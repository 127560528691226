import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Card from '../Card';
import Link from 'components/Link';
import Loading from 'components/Loading';
import SubscriptionStatus from 'components/SubscriptionStatus';
import Text from 'components/Text';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';
import useSubscriptionPrice from './useSubscriptionPrice';
import { useSelector } from 'react-redux';

const StyledButton = styled(Button)`
  cursor: pointer;
  margin-top: 18px;
  max-width: 219px;
  ${({ $noSubscription }) => $noSubscription && 'display: block;'}
`;

const StyledSubscriptionStatus = styled(SubscriptionStatus)`
  margin-top: -6px;
`;

function SubscriptionCard() {
  const user = useSelector(({ auth }) => auth?.data);
  const [loading, setLoading] = React.useState(false);
  const {
    canManageSubscription,
    loading: priceLoading,
    price,
    interval,
    noSubscription,
  } = useSubscriptionPrice();

  function handleManageSubscriptionClick() {
    setLoading(true);
    http
      .post(endpoints.billingPortal)
      .then(({ data }) => {
        window.location.href = data.url;
      })
      .catch(err => {
        setLoading(false);
        sendError('Error fetching billing portal', err);
      });
  }

  function getSubscriptionStatus() {
    // MTS - This overrides the backend status so we are able to display
    // the SubscriptionStatus component.
    if (noSubscription) {
      return 'no_subscription';
    }
    return user?.subscription_status;
  }

  function checkRenewButton() {
    // MTS - This needs to come before the noSubscription check because a free
    // user does not have a subscription according to the back end.
    if (user?.subscription_type === 'free') {
      return false;
    }
    if (noSubscription) {
      return true;
    }
    return false;
  }

  const subscriptionStatus = getSubscriptionStatus();
  const showRenewButton = checkRenewButton();

  return (
    <Card title="Subscription">
      <StyledSubscriptionStatus status={subscriptionStatus} />
      {!priceLoading && !noSubscription && (
        <Text font="serif" style={{ margin: 0 }}>
          You're paying ${price}/{interval}.
        </Text>
      )}
      {priceLoading && <Loading />}
      {showRenewButton && (
        <StyledButton small to={routes.subscription} style={{ marginTop: 8 }}>
          Renew Subscription
        </StyledButton>
      )}
      {canManageSubscription && (
        <StyledButton
          as={noSubscription ? Link : undefined}
          loading={loading}
          $noSubscription={noSubscription}
          onClick={handleManageSubscriptionClick}
          small
        >
          View Billing Details
        </StyledButton>
      )}
    </Card>
  );
}

SubscriptionCard.propTypes = {};

SubscriptionCard.defaultProps = {};

export default SubscriptionCard;
