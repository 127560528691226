import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

import AdminLayout from '../../components/AdminLayout';
import EmptyListMessage from '../../components/EmptyListMessage';
import ImageLoader from 'components/ImageLoader';
import SortButton from 'components/SortButton';
import Table from 'components/Table';
import ViewLink from '../../components/ViewLink';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';

const StyledTable = styled(Table)`
  tbody tr td:first-child {
    padding: 2px;
  }
`;

function AllImages({ location }) {
  const [loading, setLoading] = React.useState(false);
  const [allImages, setAllImages] = React.useState([]);
  // eslint-disable-next-line
  const tableData = React.useMemo(() => allImages);

  React.useEffect(() => {
    setLoading(true);
    http
      .get(endpoints.approvedImages)
      .then(({ data }) => setAllImages(data.data))
      .catch(err => sendError('Error fetching all images', err))
      .finally(() => setLoading(false));
  }, []);

  // eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      Header: 'Image',
      accessor: 'photo_url',
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <ImageLoader
          alt="All Images"
          src={value}
          style={{ height: 47, width: 70 }}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Uploaded By',
      disableSortBy: true,
      accessor: image =>
        `${image.uploader_first_name} ${image.uploader_last_name}`,
    },
    {
      Header: 'Condition',
      disableSortBy: true,
      accessor: 'conditions',
      // eslint-disable-next-line
      Cell: ({ value }) => <span>{value.join(', ')}</span>,
    },
    {
      accessor: image => new Date(dayjs(image.approved_on).format()),
      id: 'approved',
      Header: props => <SortButton {...props}>Approved</SortButton>,
      // eslint-disable-next-line
      Cell: ({ value }) => dayjs(value).format('M/D/YY'),
      sortType: 'datetime',
    },
    {
      Header: '',
      disableSortBy: true,
      accessor: 'id',
      // eslint-disable-next-line
      Cell: ({ value }) => <ViewLink to={`${routes.adminImage}/${value}`} />,
    },
  ]);

  const initialState = {
    sortBy: [{ id: 'approved', desc: false }],
  };

  const hasImages = allImages.length > 0;
  return (
    <AdminLayout loading={loading} location={location} title="All Images">
      {!hasImages && !loading && <EmptyListMessage type="images" />}
      {hasImages && (
        <StyledTable
          columns={columns}
          data={tableData}
          initialState={initialState}
        />
      )}
    </AdminLayout>
  );
}

AllImages.propTypes = {
  location: PropTypes.any.isRequired,
};

AllImages.defaultProps = {};

export default AllImages;
