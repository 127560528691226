import styled from 'styled-components/macro';
import Text from 'components/Text';

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.brown8};
  font-size: 20px;
  font-weight: 700;
`;

export default Title;
