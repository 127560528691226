import styled from 'styled-components/macro';

const Text = styled.p`
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.darkBrown};

  ${({ font, theme }) => font && `font-family: ${theme.fonts[font]};`}

  // size prop
  ${({ size, theme }) => (size ? theme.sizes[size] : theme.sizes.p)};

  // center prop
  ${({ center }) => center && `text-align: center;`}

  // error prop
  ${({ error, theme }) =>
    error &&
    `
    color: ${theme.colors.red};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  `};
`;

export default Text;
