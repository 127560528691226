import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Close from './Close';
import ImageLoader from 'components/ImageLoader';
import Modal from './Modal';

const Button = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mediumLightBrown};
  border: 0;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
`;

const Wrapper = styled.div`
  position: relative;
`;

function ImageModal({ alt, showModal, src, toggleModal }) {
  return (
    <Modal
      showModal={showModal}
      style={{ padding: 0, top: '50%', tranform: `translate(-50%, -50%)` }}
    >
      <Wrapper>
        <Button onClick={toggleModal}>
          <Close height={27} />
        </Button>
        <ImageLoader
          alt={alt}
          onContextMenu={e => e.preventDefault()}
          src={src}
          style={{
            maxHeight: '90vh',
            maxWidth: '90vw',
            objectFit: 'contain',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
          watermark
        />
      </Wrapper>
    </Modal>
  );
}

ImageModal.propTypes = {
  alt: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

ImageModal.defaultProps = {};

export default ImageModal;
