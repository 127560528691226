import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Recurring from './Recurring';

const StyledRadioWrapper = styled.div`
  background-color: white;
  border-radius: 3px;
  ${({ active }) =>
    active &&
    `
  margin: 4px 0;
  padding-bottom: 16px;

  `}
`;

function RadioWrapper({ active, children, handleRecurringChange, recurring, subscriptionRenewalInterval }) {
  return (
    <StyledRadioWrapper active={active}>
      {children}
      {active && (
        <Recurring recurring={recurring} subscriptionRenewalInterval={subscriptionRenewalInterval} onChange={handleRecurringChange} />
      )}
    </StyledRadioWrapper>
  );
}

RadioWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  handleRecurringChange: PropTypes.func.isRequired,
  recurring: PropTypes.bool.isRequired,
  subscriptionRenewalInterval: PropTypes.string.isRequired,
};

RadioWrapper.defaultProps = {};

export default RadioWrapper;
