import React from 'react';
import { navigate } from 'utils/router';
import PropTypes from 'prop-types';

import Input from 'components/Input';
import InputFile from 'components/InputFile';
import Text from 'components/Text';

import Form from '../../components/Form';
import NavButtons from '../../components/NavButtons';
import PageWrapper from '../../components/PageWrapper';

import { useForm } from 'react-hook-form';
import useScrollTop from 'hooks/useScrollTop';

function ImageUploadImage({ uploadData, setUploadData }) {
  useScrollTop();
  const defaultValues = {
    photo: uploadData.photo,
    photoCredit: uploadData.photo_credit,
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ defaultValues });

  function onSubmit(data) {
    setUploadData({
      ...uploadData,
      photo: data.photo,
      photo_credit: data.photoCredit,
    });
    navigate('/image-upload/image-details');
  }

  if (errors) {
    console.log({ errors });
  }

  const text = {
    creditDesc: `This field is optional, but highly encouraged. Information listed will help MM Atlas users reach you should they want to request an image directly from you or to learn more about what is pictured. Most submitters enter their name and email address in this box.`,
    imageDesc: `Images must depict the affected area of the breast/chest of a Black, Indigenous, Asian or Latinx human.`,
  };

  const wrapperStyle = { marginBottom: 24 };
  return (
    <PageWrapper title="Upload Image">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputFile
          accept="image/x-png,image/gif,image/jpeg"
          description={text.imageDesc}
          label="Attach Photo"
          name="img"
          required
          type="file"
          wrapperStyle={wrapperStyle}
          {...register('photo', { required: true })}
        />
        <Text font="serif" style={{ marginBottom: 20 }}>
          Please note: Photos that include any identifying features of the
          patient or that show areas beyond the breast/chest region may be
          edited or rejected.
        </Text>
        <Input
          description={text.creditDesc}
          label="Photo Credit"
          wrapperStyle={wrapperStyle}
          {...register('photoCredit')}
        />
        <Text font="serif"></Text>
        <NavButtons submitDisabled={false} submitText="Next" />
      </Form>
    </PageWrapper>
  );
}

ImageUploadImage.propTypes = {
  setUploadData: PropTypes.func.isRequired,
  uploadData: PropTypes.any.isRequired,
};

ImageUploadImage.defaultProps = {};

export default ImageUploadImage;
