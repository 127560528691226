import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ResultsCount from '../ResultsCount';
import Search from 'components/Search';

import { media } from 'styles/theme';

const StyledSearch = styled(Search)`
  grid-area: search;
  margin: 0 15px;
  width: 100%;
  ${media.ns} {
    margin: 0;
    width: initial;
  }
`;

const StyledResultsCount = styled(ResultsCount)`
  display: none;
  ${media.ns} {
    align-items: center;
    display: flex;
    transform: translateY(24px);
  }
`;

const SearchWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  width: 100%;
  ${media.ns} {
    padding: 15px 0 24px;
  }
`;

function SearchBar({ error, loading, setSearchError, termObj, totalResults }) {
  return (
    <SearchWrapper>
      <StyledSearch
        data-tour="searchbar"
        defaultTerm={termObj?.name || ''}
        defaultSelection={termObj || null}
        error={error}
        setSearchError={setSearchError}
        small
      />
      <StyledResultsCount loading={loading} totalResults={totalResults} />
    </SearchWrapper>
  );
}

SearchBar.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  setSearchError: PropTypes.func.isRequired,
  termObj: PropTypes.any.isRequired,
  totalResults: PropTypes.number.isRequired,
};

SearchBar.defaultProps = {
  termObj: null,
};

export default SearchBar;
