import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import MobileTooltip from './MobileTooltip';

import useIsMobile from 'hooks/useIsMobile';

const ChildrenWrapper = styled.span`
  :first-child {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledTooltip = styled.div`
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
  padding: 10px 0 0;
  position: absolute;

  left: ${({ $position }) => $position.left}px;
  transition: visibility 0s 250ms, opacity 250ms ease-out;
  top: ${({ $position }) => $position.top}px;

  width: 465px;
  visibility: hidden;
  z-index: 4;

  :after {
    content: '';
    position: absolute;
    top: -15px;

    z-index: 2;
    /* position arrow correctly */
    left: calc(50% + ${({ $position }) => $position.arrowOffset}px);
    transform: translateX(-50%);

    /* the arrow */
    border: 8px solid #fff;
    border-color: transparent transparent #fff transparent;

    display: none;
  }
`;

const TooltipMain = styled.span`
  :hover {
    ${StyledTooltip} {
      opacity: 1;
      visibility: visible;
    }
    ${StyledTooltip}:before, ${StyledTooltip}:after {
      display: block;
    }
  }
`;

function Tooltip({ children, tooltipContent }) {
  const isMobile = useIsMobile();
  const tooltipRef = React.useRef(null);
  const childRef = React.useRef(null);
  const [position, setPosition] = React.useState({
    arrowOffset: 0,
    left: 0,
    top: 0,
  });

  React.useEffect(() => {
    document.fonts.onloadingdone = () => {
      calculateOffset();
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (tooltipRef?.current) {
      calculateOffset();
    }
    // eslint-disable-next-line
  }, [children, childRef.current, tooltipRef, tooltipContent]);

  function calculateOffset() {
    if (isMobile) {
      return;
    }
    const parentWidth = document.querySelector('body').getBoundingClientRect()
      .width;

    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const childRect = childRef.current.getBoundingClientRect();
    const subtractLeft = (tooltipRect.width - childRect.width) / 2;
    let left = childRect.left - subtractLeft;
    const spaceAvailableRight = parentWidth - childRect.right;
    const spaceNeededRight = subtractLeft;
    let arrowOffset = 0;
    setPosition({ left: left - spaceNeededRight, top: childRect.bottom + 12 });
    if (spaceNeededRight > spaceAvailableRight) {
      const diff = spaceNeededRight - spaceAvailableRight;
      left = left - diff;
      arrowOffset = diff;
    }
    setPosition({ arrowOffset, left, top: childRect.bottom + 12 });
  }

  if (isMobile) {
    return (
      <MobileTooltip children={children} tooltipContent={tooltipContent} />
    );
  }

  return (
    <TooltipMain onMouseEnter={calculateOffset}>
      <ChildrenWrapper ref={childRef}>{children}</ChildrenWrapper>
      <StyledTooltip ref={tooltipRef} $position={position}>
        {tooltipContent}
      </StyledTooltip>
    </TooltipMain>
  );
}

Tooltip.propTypes = {
  children: PropTypes.any,
  tooltipContent: PropTypes.any,
};

Tooltip.defaultProps = {};

export default Tooltip;
