import styled from 'styled-components/macro';
import Text from 'components/Text';
import { media } from 'styles/theme';

const CaseSummary = styled(Text)`
  grid-area: des;
  ${media.sm} {
    margin: 0;
  }
`;

export default CaseSummary;
