import * as React from 'react';
// import PropTypes from 'prop-types';
// import dayjs from 'dayjs';

import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';

import { logout, refreshToken } from 'reducers/auth';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { navigate } from 'utils/router';
import { sendError } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';

function RefreshToken() {
  const [ready, setReady] = React.useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth.data);
  // const { expires_at, refresh_token } = auth;
  const { refresh_token } = auth;

  React.useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 3000);
  }, []);

  React.useEffect(() => {
    if (ready) {
      http
        .post(endpoints.refreshToken, { refresh_token })
        .then(({ data }) => {
          dispatch(refreshToken(data));
          setTimeout(() => {
            navigate(-1, { replace: true });
          }, 500);
        })
        .catch(err => {
          sendError('Error refreshing token', err);
          dispatch(logout());
          navigate('/', { replace: true });
        });
    }
    // eslint-disable-next-line
  }, [ready]);

  // React.useEffect(() => {
  //   const now = dayjs();
  //   const expires = dayjs(expires_at);
  //   if (expires.isAfter(now)) {
  //     navigate(-1, { replace: true });
  //   }
  // }, [expires_at]);

  return (
    <Layout>
      <PageLoading />
    </Layout>
  );
}

RefreshToken.propTypes = {};

RefreshToken.defaultProps = {};

export default RefreshToken;
