// MTS - This hook was created to hopefully make
// make the images more random than they currently are.
import * as React from 'react';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { useSelector } from 'react-redux';

function useNewImages() {
  const [loading, setLoading] = React.useState(true);
  const terms = useSelector(({ auth }) => auth.meta.search_options);
  const [results, setResults] = React.useState([]);

  React.useEffect(() => {
    getImages();
    // eslint-disable-next-line
  }, []);

  async function getImages() {
    const cats = getRandomCats();
    let resolved = await Promise.all(cats.map(el => getImage(el)));
    // flatten the array
    resolved = resolved.reduce((acc, el, i) => {
      return acc.concat(el);
    }, []);
    // dedup
    resolved = dedup(resolved);
    setResults(resolved.slice(0, 3));
    setLoading(false);
  }

  function dedup(arr) {
    return arr.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
  }

  async function getImage(id) {
    const result = await http.post(endpoints.search, { search: id });
    return result?.data?.data;
  }

  function getRandomCats() {
    return shuffle(terms.map(el => el.id)).slice(0, 12);
  }

  return { loading, results };
}

export default useNewImages;

function shuffle(arr) {
  const array = [...arr];
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
