import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Loading from 'components/Loading';

const StyledPageLoading = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

function PageLoading() {
  return (
    <StyledPageLoading>
      <Loading />
    </StyledPageLoading>
  );
}

PageLoading.propTypes = {};

PageLoading.defaultProps = {};

export default PageLoading;
