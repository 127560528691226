import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Icon from './Icon';

const StyledSortButton = styled.span`
  cursor: pointer;
  white-space: nowrap;
`;

function SortButton({ children, column, ...rest }) {
  function getIcon() {
    if (column?.isSorted) {
      return (
        <Icon
          height={10}
          style={{ marginLeft: 6 }}
          type={column?.isSortedDesc ? 'desc' : 'acc'}
        />
      );
    }
    return null;
  }
  return (
    <StyledSortButton role="button">
      {children}
      {getIcon()}
    </StyledSortButton>
  );
}

SortButton.propTypes = {
  children: PropTypes.any.isRequired,
  column: PropTypes.shape({
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }),
};

SortButton.defaultProps = {};

export default SortButton;
