import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Input from 'components/Input';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Text from 'components/Text';

const Form = styled.form`
  width: 250px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  margin-left: 8px;
  max-height: 40px;
  min-width: ${({ hasChanges }) => (hasChanges ? 154 : 88)}px;
  display: inline-flex;
`;

const ModalTitle = styled(Text)`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  height: 40px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
`;

function PendingActionButtons({
  hasChanges,
  handleApproveDeny,
  image,
  loading,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [reason, setReason] = React.useState('');

  function handleClick(status) {
    if (status === 'denied') {
      setShowModal(true);
    } else {
      handleApproveDeny(status);
    }
  }

  function handleCancel() {
    setShowModal(false);
  }

  function handleDeny(e) {
    e.preventDefault();
    setShowModal(false);
    setTimeout(() => {
      handleApproveDeny('denied', reason);
    }, 300);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <StyledButton
        disabled={loading}
        hasChanges={hasChanges}
        onClick={() => handleClick('approved')}
      >
        {hasChanges && 'Save & '}Approve
      </StyledButton>
      <StyledButton
        disabled={loading}
        hasChanges={hasChanges}
        secondary
        onClick={() => handleClick('denied')}
      >
        {hasChanges && 'Save & '}Deny
      </StyledButton>
      <Modal showModal={showModal}>
        <Form onSubmit={handleDeny}>
          <ModalTitle center>Reason for Denial</ModalTitle>
          <Input
            label="Reason"
            onChange={e => setReason(e.target.value)}
            value={reason}
          />
          <Button
            onClick={handleDeny}
            type="submit"
            style={{ fontSize: 12, maxHeight: 40, marginTop: 20 }}
          >
            Submit
          </Button>
          <CancelButton onClick={handleCancel} type="button">
            Cancel
          </CancelButton>
        </Form>
      </Modal>
    </>
  );
}

PendingActionButtons.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string,
  }),
  hasChanges: PropTypes.bool.isRequired,
  handleApproveDeny: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

PendingActionButtons.defaultProps = {
  image: {},
  loading: false,
};

export default PendingActionButtons;
