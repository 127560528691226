import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Arrow from './Arrow';
import PageButton from '../PageButton';

const StyledArrowButton = styled(PageButton)`
  background-color: ${({ theme }) => theme.colors.primary};
  :disabled {
    background-color: ${({ theme }) => theme.colors.lightTan};
  }
`;

function ArrowButton({ right, ...rest }) {
  return (
    <StyledArrowButton {...rest}>
      <div>
        <Arrow
          height={14}
          style={{ transform: `rotate(${right ? '180' : '0'}deg)` }}
        />
      </div>
    </StyledArrowButton>
  );
}

ArrowButton.propTypes = {
  right: PropTypes.bool,
};

ArrowButton.defaultProps = {
  right: false,
};

export default ArrowButton;
