import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';

import AdminLayout from '../../components/AdminLayout';
import EmptyListMessage from '../../components/EmptyListMessage';
import SortButton from 'components/SortButton';
import Table from 'components/Table';
import ViewLink from '../../components/ViewLink';

function PendingUsers({ location }) {
  const [loading, setLoading] = React.useState(false);
  const [waitlist, setWaitlist] = React.useState([]);
  // eslint-disable-next-line
  const tableData = React.useMemo(() => waitlist);

  React.useEffect(() => {
    fetchWaitlist();
    // eslint-disable-next-line
  }, []);

  const fetchWaitlist = React.useCallback(() => {
    setLoading(true);
    http(endpoints.pendingUsers)
      .then(({ data }) => {
        setWaitlist(data.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      Header: props => <SortButton {...props}>First Name</SortButton>,
      accessor: 'first_name',
    },
    {
      Header: props => <SortButton {...props}>Last Name</SortButton>,
      accessor: 'last_name',
    },
    {
      Header: props => <SortButton {...props}>Occupation</SortButton>,
      accessor: 'occupation',
    },
    {
      accessor: user => new Date(dayjs(user.submitted_at).format()),
      Header: props => <SortButton {...props}>Submitted</SortButton>,
      id: 'submitted',
      // eslint-disable-next-line
      Cell: ({ value }) => dayjs(value).format('M/D/YY'),
      sortType: 'datetime',
    },
    {
      Header: '',
      disableSortBy: true,
      accessor: 'id',
      // eslint-disable-next-line
      Cell: ({ value }) => <ViewLink to={`${routes.pendingUser}/${value}`} />,
    },
  ]);

  const initialState = {
    sortBy: [{ id: 'submitted', desc: false }],
  };

  return (
    <AdminLayout title="Pending Users" loading={loading} location={location}>
      {waitlist.length === 0 && <EmptyListMessage type="pending users" />}
      {waitlist.length > 0 && (
        <Table columns={columns} data={tableData} initialState={initialState} />
      )}
    </AdminLayout>
  );
}

PendingUsers.propTypes = {
  location: PropTypes.any.isRequired,
};

PendingUsers.defaultProps = {};

export default PendingUsers;
