import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';

import Button from 'components/Button';
import Input from 'components/Input';
import Layout from 'components/Layout';
import Text from 'components/Text';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { passwordValidation } from 'utils/validations';
import { sendError } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 20px;
`;

const Content = styled.div`
  max-width: 346px;
  width: 100%;
`;

function ResetPassword(props) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const urlParams = new URLSearchParams(props.location.search);
  const reset_password_token = urlParams.get('reset_password_token');

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm();

  const password = React.useRef({});
  const passwordConfirm = React.useRef({});
  password.current = watch('password', '');
  passwordConfirm.current = watch('passwordConfirm', '');

  function onSubmit(data) {
    setError(false);
    setLoading(true);
    http
      .put(endpoints.resetPassword, {
        reset_password_token,
        password: data.password,
        password_confirmation: data.passwordConfirm,
      })
      .then(res => {
        setSuccess(true);
      })
      .catch(err => {
        sendError('Error during password reset', err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }

  const passwordsMatch = password.current === passwordConfirm.current;

  if (success) {
    return (
      <Layout title="Success">
        <Text font="serif" center>
          Your password has been reset.
        </Text>
      </Layout>
    );
  }

  return (
    <Layout title="Set your new password below">
      <Container>
        <Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              error={errors?.password?.message}
              hideRequired
              label="Password"
              name="password"
              required
              type="password"
              {...register('password', passwordValidation)}
            />
            <Input
              error={errors?.passwordConfirm?.message}
              hideRequired
              label="Confirm Password"
              name="password"
              required
              type="password"
              wrapperStyle={{ marginTop: 20 }}
              {...register('passwordConfirm', passwordValidation)}
            />
            {!passwordsMatch && <Text color="red">Passwords must match</Text>}
            {error && (
              <Text error>
                An error occurred while trying to update your password.
              </Text>
            )}
            <Button
              disabled={!passwordsMatch || loading}
              loading={loading}
              type="submit"
              style={{ marginTop: 20 }}
            >
              Submit
            </Button>
          </form>
        </Content>
      </Container>
    </Layout>
  );
}

ResetPassword.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

ResetPassword.defaultProps = {};

export default ResetPassword;
