import * as React from 'react';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';
import useParams from './useParams';

function useFilters() {
  const [urlFilters, setUrlFilters] = useParams('filters');
  const [availableFilters, setAvailableFilters] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    http(endpoints.getFilters)
      .then(({ data }) => {
        setAvailableFilters(data);
      })
      .catch(err => sendError('Unable to fetch filters', err))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (availableFilters) {
      setFiltersInUrl();
    }
    // eslint-disable-next-line
  }, [selectedFilters]);

  React.useEffect(() => {
    if (availableFilters) {
      try {
        getFiltersFromUrl();
      } catch (err) {
        console.log('error getting filters from url', err);
      }
    }
    // eslint-disable-next-line
  }, [availableFilters]);

  function getFiltersFromUrl() {
    if (!availableFilters || !urlFilters) {
      return;
    }
    const filters = JSON.parse(urlFilters).map(id => {
      const filter = Object.keys(availableFilters).reduce((acc, key) => {
        const found = availableFilters[key].find(el => el.id === id);
        if (found) {
          return {
            type: key,
            id,
            name: found.name,
          };
        }
        return acc;
      }, null);
      return filter;
    });
    setSelectedFilters(filters);
  }

  function setFiltersInUrl() {
    if (selectedFilters.length > 0) {
      setUrlFilters(JSON.stringify(selectedFilters.map(el => el.id)));
    } else {
      setUrlFilters('');
    }
  }

  function toggleFilter(filter) {
    if (selectedFilters.find(el => el.id === filter.id)) {
      return setSelectedFilters(
        selectedFilters.filter(el => el.id !== filter.id)
      );
    }
    setSelectedFilters(selectedFilters.concat([filter]));
  }

  return {
    availableFilters,
    filtersLoading: loading,
    selectedFilters,
    setSelectedFilters,
    toggleFilter,
  };
}

export default useFilters;
