import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components/macro';

import AdminLayout from '../../components/AdminLayout';
import EmptyListMessage from '../../components/EmptyListMessage';
import ImageLoader from 'components/ImageLoader';
import SortButton from 'components/SortButton';
import Table from 'components/Table';
import ViewLink from '../../components/ViewLink';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';

const StyledTable = styled(Table)`
  tbody tr td:first-child {
    padding: 2px;
  }
`;

function DeniedImages({ location }) {
  const [loading, setLoading] = React.useState(false);
  const [deniedImages, setDeniedImages] = React.useState([]);
  // eslint-disable-next-line
  const tableData = React.useMemo(() => deniedImages);

  React.useEffect(() => {
    setLoading(true);
    http
      .get(endpoints.deniedImages)
      .then(({ data }) => setDeniedImages(data.data))
      .catch(err => sendError('Error fetching denied images', err))
      .finally(() => setLoading(false));
  }, []);

  // eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      Header: 'Image',
      accessor: 'photo_url',
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <ImageLoader
          alt="denied image"
          src={value}
          style={{ height: 47, width: 70 }}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Uploaded By',
      disableSortBy: true,
      accessor: image =>
        `${image.uploader_first_name} ${image.uploader_last_name}`,
    },
    {
      Header: 'Condition',
      disableSortBy: true,
      accessor: 'conditions',
      // eslint-disable-next-line
      Cell: ({ value }) => <span>{value.join(', ')}</span>,
    },
    {
      accessor: image => new Date(dayjs(image.submitted_at).format()),
      id: 'submitted',
      Header: <SortButton>Submitted</SortButton>,
      // eslint-disable-next-line
      Cell: ({ value }) => dayjs(value).format('M/D/YY'),
      sortType: 'datetime',
    },
    {
      Header: '',
      disableSortBy: true,
      accessor: 'id',
      // eslint-disable-next-line
      Cell: ({ value }) => <ViewLink to={`${routes.adminImage}/${value}`} />,
    },
  ]);

  const initialState = {
    sortBy: [{ id: 'submitted', desc: false }],
  };

  return (
    <AdminLayout location={location} loading={loading} title="Denied Images">
      {!loading && deniedImages.length === 0 && (
        <EmptyListMessage type="denied images" />
      )}
      {deniedImages.length > 0 && (
        <StyledTable
          columns={columns}
          data={tableData}
          initialState={initialState}
        />
      )}
    </AdminLayout>
  );
}

DeniedImages.propTypes = {
  location: PropTypes.any.isRequired,
};

DeniedImages.defaultProps = {};

export default DeniedImages;
