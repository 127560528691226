import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const Dot = styled.div`
  border-radius: 100%;
  background-color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.brown2};
  height: 8px;
  width: 8px;
  margin: 0 8px;
`;

const StyledLoading = styled.div`
  display: inline-flex;
  div {
    animation-name: ${blink};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  div:nth-child(2) {
    animation-delay: 0.2s;
  }
  div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

function Loading({ color, ...props }) {
  return (
    <StyledLoading {...props}>
      {Array(3)
        .fill('')
        .map((el, i) => (
          <Dot color={color} key={i} />
        ))}
    </StyledLoading>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: 'brown2',
};

export default Loading;
