import * as React from 'react';
// import PropTypes from 'prop-types';
import NotFound from 'components/NotFound';
import Router from 'components/Router';

// Pages
import InvitationsIndex from './pages/invitationsIndex';
import SubscriptionSuccess from './pages/subscription-success';

function Invitations() {
  return (
    <Router>
      <InvitationsIndex path="/:waitlist_token" />
      <SubscriptionSuccess path="/subscription-success" />
      <NotFound default />
    </Router>
  );
}

Invitations.propTypes = {};

Invitations.defaultProps = {};

export default Invitations;
