import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import CheckboxGroup from './CheckboxGroup';
import ConsentDownload from './ConsentDownload';
import DefaultLoader from 'components/ImageLoader/DefaultLoader';
import DownloadImageButton from './DownloadImageButton';
import ImageLoader from 'components/ImageLoader';
import InputFile from 'components/InputFile';
import Input from 'components/Input';
import Select from 'components/Select';

const StyledImageForm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
`;

const FormWrapper = styled.div`
  max-width: 600px;
  width: 100%;
`;

function ImageForm({
  conditions,
  consentFileName,
  consentUrl,
  imageOptions,
  locations,
  photoUrl,
  register,
  previewImage,
  setConditions,
  setHasChanges,
  setLocations,
  setSymptoms,
  symptoms,
}) {
  function handleConditions(name) {
    setHasChanges(true);
    // handle deselect
    if (conditions.find(el => el === name)) {
      setConditions(conditions.filter(el => el !== name));
    } else {
      setConditions(conditions.concat([name]));
    }
  }

  function handleSymptoms(name) {
    setHasChanges(true);
    // handle deselect
    if (symptoms.find(el => el === name)) {
      setSymptoms(symptoms.filter(el => el !== name));
    } else {
      setSymptoms(symptoms.concat([name]));
    }
  }

  function handleLocations(name) {
    setHasChanges(true);
    // handle deselect
    if (locations.find(el => el === name)) {
      setLocations(locations.filter(el => el !== name));
    } else {
      setLocations(locations.concat([name]));
    }
  }

  const inputStyle = { maxWidth: 490, marginBottom: 20 };
  const imageStyle = {
    backgroundColor: 'transparent',
    height: 340,
    marginBottom: 20,
  };

  const fileName = `${conditions[0]}-download`;
  return (
    <StyledImageForm>
      <FormWrapper>
        {previewImage ? (
          <DefaultLoader alt="New" src={previewImage} style={imageStyle} />
        ) : (
          <ImageLoader alt="Pending" src={photoUrl} style={imageStyle} />
        )}
        <DownloadImageButton photoUrl={photoUrl} fileName={fileName} />
        <InputFile
          accept="image/x-png,image/gif,image/jpeg"
          label="Upload a new photo"
          name="img"
          type="file"
          wrapperStyle={inputStyle}
          {...register('photo', { required: true })}
        />

        <Input
          disabled
          label="Email"
          type="email"
          wrapperStyle={inputStyle}
          {...register('email', { required: true })}
        />

        <Input
          disabled
          label="First Name"
          wrapperStyle={inputStyle}
          {...register('firstName', { required: true })}
        />

        <Input
          disabled
          label="Last Name"
          wrapperStyle={inputStyle}
          {...register('lastName', { required: true })}
        />

        <Input
          label="Photo Credit"
          wrapperStyle={inputStyle}
          {...register('photoCredit', { required: true })}
        />

        <CheckboxGroup
          label="Conditions"
          handleChange={handleConditions}
          options={imageOptions.conditions}
          selectedValues={conditions}
        />

        <CheckboxGroup
          label="Symptoms"
          handleChange={handleSymptoms}
          options={imageOptions.symptoms}
          selectedValues={symptoms}
        />

        <CheckboxGroup
          label="Locations"
          handleChange={handleLocations}
          options={imageOptions.locations}
          selectedValues={locations}
        />

        <Select
          label="How would you describe the pain"
          wrapperStyle={inputStyle}
          {...register('painQuality', { required: true })}
        >
          {imageOptions?.pain_quality.map(el => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))}
        </Select>

        <Select
          label="Severity of Pain"
          wrapperStyle={inputStyle}
          {...register('severity', { required: true })}
        >
          {Array(11)
            .fill('')
            .map((el, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
        </Select>

        <Select
          label="Duration of Pain/Condition"
          wrapperStyle={inputStyle}
          {...register('duration', { required: true })}
        >
          {imageOptions?.duration.map(el => (
            <option key={el.id} value={el.id}>
              {el.name}
            </option>
          ))}
        </Select>

        <Select
          label="When did the pain occur"
          wrapperStyle={inputStyle}
          {...register('timing', { required: true })}
        >
          {imageOptions?.timings.map(el => (
            <option key={el.id} value={el.name}>
              {el.name}
            </option>
          ))}
        </Select>

        <Input
          as="textarea"
          label="Modifying Factors"
          rows={5}
          wrapperStyle={inputStyle}
          {...register('modifyingFactors', { required: true })}
        />

        <Input
          as="textarea"
          label="Case Summary"
          rows={5}
          wrapperStyle={inputStyle}
          {...register('caseSummary', { required: true })}
        />
        <ConsentDownload filename={consentFileName} href={consentUrl} />
      </FormWrapper>
    </StyledImageForm>
  );
}

ImageForm.propTypes = {
  conditions: PropTypes.array,
  locations: PropTypes.array,
  consentUrl: PropTypes.string.isRequired,
  consentFileName: PropTypes.string.isRequired,
  imageOptions: PropTypes.object.isRequired,
  photoUrl: PropTypes.string,
  register: PropTypes.func.isRequired,
  previewImage: PropTypes.string,
  setConditions: PropTypes.func.isRequired,
  setHasChanges: PropTypes.func.isRequired,
  setLocations: PropTypes.func.isRequired,
  setSymptoms: PropTypes.func.isRequired,
  symptoms: PropTypes.array,
};

ImageForm.defaultProps = {
  previewImage: null,
};

export default ImageForm;
