import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Banner from '../Banner';
import Footer from 'components/Footer';
import JoinHeader from '../JoinHeader';

import { media } from 'styles/theme';

const StyledJoinLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  flex: 1;
  padding: 24px 24px 30px;
  ${media.ns} {
    padding: 56px 20px 30px;
  }
`;

function JoinLayout({ bannerText, children }) {
  return (
    <StyledJoinLayout>
      <JoinHeader />
      <Banner>{bannerText}</Banner>
      <Container>{children}</Container>
      <Footer />
    </StyledJoinLayout>
  );
}

JoinLayout.propTypes = {
  bannerText: PropTypes.string.isRequired,
  children: PropTypes.any,
};

JoinLayout.defaultProps = {
  children: null,
};

export default JoinLayout;
