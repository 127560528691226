import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import AdminLinks from './AdminLinks';
import { Link as ReachLink, useLocation } from 'utils/router';
import Logo from 'components/Logo';
import MobileNav from './MobileNav';
import UserLinks from './UserLinks';

import { media } from 'styles/theme';

export const StyledHeader = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 73px;
  justify-content: space-between;
  padding: 18px 8px 18px 18px;
  ${({ theme }) => theme.shadow1}
  ${media.ns} {
    height: 76px;
    padding: 0px 30px;
  }
`;

const Links = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  ${media.sm} {
    display: none;
  }
`;

function Header({ type }) {
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const location = useLocation();
  return (
    <StyledHeader>
      <ReachLink to="/" style={{ display: 'flex' }}>
        <Logo height={36} />
      </ReachLink>
      <Links>
        {type === 'admin' ? (
          <AdminLinks location={location} />
        ) : (
          <UserLinks location={location} />
        )}
      </Links>
      <MobileNav
        open={mobileNavOpen}
        toggleOpen={() => setMobileNavOpen(!mobileNavOpen)}
        type={type}
      />
    </StyledHeader>
  );
}

Header.propTypes = {
  type: PropTypes.oneOf(['default', 'admin']),
};

Header.defaultProps = {
  type: 'default',
};

export default Header;
