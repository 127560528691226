// This loads normal images without having to fetch them
import * as React from 'react';
import PropTypes from 'prop-types';
import { Img, Wrapper } from './';

function DefaultLoader({ alt, src, ...rest }) {
  const [imageReady, setImageReady] = React.useState(false);
  const [mounted, setMounted] = React.useState(true);

  React.useEffect(() => {
    return () => setMounted(false);
    // eslint-disable-next-line
  }, []);

  function handleLoaded() {
    if (mounted) {
      setImageReady(true);
    }
  }

  return (
    <Wrapper {...rest}>
      <Img
        alt={alt}
        imageReady={imageReady}
        loading="lazy"
        onLoad={handleLoaded}
        src={src}
        {...rest}
      />
    </Wrapper>
  );
}

DefaultLoader.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

DefaultLoader.defaultProps = {};

export default DefaultLoader;
