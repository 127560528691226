import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import DetailItem from './DetailItem';
import Loading from 'components/Loading';

import http from 'utils/http';
import endpoints from 'utils/endpoints';
import { media } from 'styles/theme';
import { sendError } from 'utils/helpers';

const StyledDetailsSection = styled.div`
  align-self: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  grid-area: detail;
  flex-direction: column;
  padding: 3px 18px 0;
  width: 291px;
  ${media.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px 16px;
    width: 100%;
  }
`;

function DetailsSection({
  conditions,
  duration,
  locations,
  modifying_factors,
  pain_quality,
  severity,
  symptoms,
  timing,
  ...rest
}) {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState(null);

  React.useEffect(() => {
    http(endpoints.getImageOptions)
      .then(({ data }) => setOptions(data))
      .catch(err => {
        sendError('Error fetching image options in image detail', err);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading || !options) {
    return <Loading />;
  }

  return (
    <StyledDetailsSection>
      <DetailItem title="Conditions" value={conditions.join(', ')} />
      <DetailItem title="Symptoms" value={symptoms.join(', ')} />
      <DetailItem title="Location" value={locations.join(', ')} />
      <DetailItem title="Pain Quality" value={pain_quality} />
      <DetailItem title="Pain Severity" value={`${severity}/10`} />
      <DetailItem
        title="Duration of Condition"
        value={options.duration.find(el => el.id === duration).name}
      />
      <DetailItem title="Timing" value={timing} />
      <DetailItem
        title="Modifying Factors"
        value={modifying_factors}
        style={{ marginBottom: 18 }}
      />
    </StyledDetailsSection>
  );
}

DetailsSection.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.string).isRequired,
  duration: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  modifying_factors: PropTypes.string,
  pain_quality: PropTypes.string.isRequired,
  severity: PropTypes.number.isRequired,
  symptoms: PropTypes.arrayOf(PropTypes.string).isRequired,
  timing: PropTypes.string,
};

DetailsSection.defaultProps = {};

export default DetailsSection;
