const endpoints = {
  // admin
  allUsers: '/admin/user/all?status=approved',
  adminInvitation: '/admin/invitation',
  adminImageDetail: '/admin/image',
  deniedUsers: '/admin/waitlist/all?status=denied',
  invitedUsers: '/admin/waitlist/all?status=invited',
  pendingUsers: '/admin/waitlist/all?status=pending',
  waitlist: '/admin/waitlist',
  userDetail: '/admin/user',
  pendingUser: '/admin/waitlist',

  // user
  account: '/account',
  billingPortal: '/subscription/manage',
  checkInviteToken: '/invitations',
  createWaitlist: '/waitlist',
  getFilters: '/search/filters',
  login: '/login',
  logout: '/logout',
  refreshToken: '/refresh',
  resetPassword: '/reset_password',
  search: '/search',
  subscription: '/subscription',
  subscriptions: '/subscriptions',
  images: '/images', // all images
  resources: '/resources',

  // Images
  allImages: '/admin/image/all',
  deniedImages: '/admin/image/all?status=denied',
  getImageOptions: '/images/options',
  pendingImages: '/admin/image/all?status=pending',
  approvedImages: '/admin/image/all?status=approved',
  // Image Upload
  image: '/image',
};
export default endpoints;
