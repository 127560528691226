import * as React from 'react';

function useDonation() {
  const [currentDonation, setCurrentDonation] = React.useState(500);
  const [customAmount, setCustomAmount] = React.useState(0);
  const [selectedDonation, setSelectedDonation] = React.useState('five');
  const [recurring, setRecurring] = React.useState('one-time');

  function handleNoneSelect() {
    setRecurring(false);
    setSelectedDonation('none');
    setCurrentDonation(0);
  }

  function handleFiveSelect() {
    setSelectedDonation('five');
    setCurrentDonation(500);
  }

  function handleTenSelect() {
    setSelectedDonation('ten');
    setCurrentDonation(1000);
  }

  function handleCustomSelect() {
    setCurrentDonation(customAmount * 100);
    setSelectedDonation('custom');
  }

  function handleCustomInput(e) {
    setCustomAmount(e.target.value);
    if (selectedDonation === 'custom') {
      setCurrentDonation(e.target.value * 100);
    }
  }

  return {
    customAmount,
    currentDonation,
    handleNoneSelect,
    handleFiveSelect,
    handleTenSelect,
    handleCustomSelect,
    handleCustomInput,
    recurring,
    setRecurring,
    selectedDonation,
    setSelectedDonation,
  };
}

export default useDonation;
