import * as React from 'react';
import PropTypes from 'prop-types';

import {
  Column,
  Title,
  TooltipContent as Content,
  TooltipWrapper,
} from './TooltipStyles';
import Item from './Item';

function TooltipContent({ items, title }) {
  function splitColumns() {
    const splitIndex = Math.ceil(items.length / 2);
    const first = items.slice(0, splitIndex);
    const second = items.slice(splitIndex, items.length);
    return [first, second];
  }

  const [firstColumn, secondColumn] = splitColumns();

  return (
    <TooltipWrapper>
      <Title>{title}</Title>
      <Content>
        <Column>
          {firstColumn.map(el => (
            <Item key={el.id} {...el} />
          ))}
        </Column>
        <Column>
          {secondColumn.map(el => (
            <Item key={el.id} {...el} />
          ))}
        </Column>
      </Content>
    </TooltipWrapper>
  );
}

TooltipContent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string.isRequired,
};

TooltipContent.defaultProps = {};

export default TooltipContent;
