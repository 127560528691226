import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import FiltersBar from './components/FiltersBar';
import { Helmet } from 'react-helmet-async';
import Layout from 'components/Layout';
import Pagination from './components/Pagination';
import Results from './components/Results';
import ResultsCount from './components/ResultsCount';
import SearchBar from './components/SearchBar';
import SelectedFilters from './components/SelectedFilters';
import HelpUI from 'components/HelpUI';

import { media } from 'styles/theme';
import useFilters from './useFilters';
import useResults from './useResults';
import { useSelector } from 'react-redux';
import useHelp from 'hooks/useHelp';
import useHelpItems from './useHelpItems';

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'search'
    'filters'
    'sfilters'
    'count'
    'results';
  ${media.ns} {
    grid-gap: 0px 48px;
    grid-template-areas:
      'search search search'
      'filters sfilters sfilters'
      'filters results results';
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 200px 1fr;
  }
`;

const StyledResultsCount = styled(ResultsCount)`
  ${media.ns} {
    display: none;
  }
`;

const StyledLayout = styled(Layout)`
  > div {
    padding-top: 0px;
  }
  ${media.sm} {
    > div {
      padding: 22px 0 0;
    }
  }
`;

function SearchResults({ location }) {
  const [searchError, setSearchError] = React.useState(false);
  const params = new URLSearchParams(location.search);
  const terms = useSelector(({ auth }) => auth.meta.search_options);
  const term = params.get('term');
  const termObj = terms.find(t => t.name === term);
  const {
    availableFilters,
    filtersLoading,
    selectedFilters,
    setSelectedFilters,
    toggleFilter,
  } = useFilters();
  const { loading, limit, page, results, setPage, total } = useResults(
    termObj,
    selectedFilters,
    setSearchError
  );
  const items = useHelpItems(selectedFilters);
  const { helpItems, setIsOpen } = useHelp(items);

  React.useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line
  }, [availableFilters, selectedFilters]);

  React.useEffect(() => {
    return () => setIsOpen(false);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledLayout>
      <Helmet title="MMAtlas | Search Results" />
      <Grid>
        <SearchBar
          error={searchError}
          loading={loading}
          setSearchError={setSearchError}
          totalResults={total}
          termObj={termObj}
        />
        <FiltersBar
          availableFilters={availableFilters}
          loading={filtersLoading}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          toggleFilter={toggleFilter}
        />
        <StyledResultsCount loading={loading} totalResults={total} />
        <SelectedFilters
          selectedFilters={selectedFilters}
          toggleFilter={toggleFilter}
        />
        <Results results={results} loading={loading} termObj={termObj}>
          <Pagination
            limit={limit}
            page={page}
            setPage={setPage}
            total={total}
          />
        </Results>
      </Grid>
      <HelpUI items={helpItems} />
    </StyledLayout>
  );
}

SearchResults.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

SearchResults.defaultProps = {
  location: {
    search: '',
  },
};

export default SearchResults;
