import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from 'components/Text';

const StyledDialogItem = styled.li`
  cursor: pointer;
  padding: 12px 22px;
  margin: 0;
  p {
    line-height: 1.5;
    margin: 2px;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  margin-bottom: 6px;
`;

function DialogItem({ closeHelp, hide, onClick, title, text, ...rest }) {
  function handleClick() {
    closeHelp();
    onClick();
  }

  if (hide) {
    return null;
  }

  return (
    <StyledDialogItem aria-label={title} role="button" onClick={handleClick}>
      <Title color="primary" size="smallestP">
        {title}
      </Title>
      <Text color="darkBrown" size="smallestP">
        {text}
      </Text>
    </StyledDialogItem>
  );
}

DialogItem.propTypes = {
  closeHelp: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  text: PropTypes.any.isRequired,
};

DialogItem.defaultProps = {
  hide: false,
};

export default DialogItem;
