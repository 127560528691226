import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'utils/router';

import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import MultiCheckbox from 'components/MultiCheckbox';
import Select from 'components/Select';
import Text from 'components/Text';

import Form from '../../components/Form';
import NavButtons from '../../components/NavButtons';
import PageWrapper from '../../components/PageWrapper';

import { useForm } from 'react-hook-form';
import useScrollTop from 'hooks/useScrollTop';

function ImageUploadPatientSummary({
  painQuality,
  setUploadData,
  symptoms,
  uploadData,
}) {
  useScrollTop();
  const [multiError, setMultiError] = React.useState(null);

  // Set previously selected answers
  React.useEffect(() => {
    const { symptom_ids } = uploadData;
    if (symptom_ids.length) {
      setSelectedSymptoms(
        symptoms.filter(el => symptom_ids.find(symptom => symptom === el.id))
      );
    }
    // eslint-disable-next-line
  }, []);

  const defaultValues = {
    caseSummary: uploadData.case_summary,
    painQuality: uploadData.pain_quality || painQuality[0].id,
    severity: uploadData.severity,
  };

  const {
    handleSubmit,
    // formState: { errors },
    register,
  } = useForm({ defaultValues });

  const [selectedSymptoms, setSelectedSymptoms] = React.useState([]);

  function onSubmit(d) {
    setMultiError(null);

    // Validate selectedSymptoms
    if (selectedSymptoms.length === 0) {
      setMultiError('Please select at least one symptom.');
      return;
    }

    const data = {
      case_summary: d.caseSummary,
      pain_quality: d.painQuality,
      severity: parseInt(d.severity, 10),
      symptom_ids: selectedSymptoms.map(el => el.id),
    };
    setUploadData({ ...uploadData, ...data });
    navigate('/image-upload/consent-media');
  }

  function handleSymptom(symptom) {
    const alreadyExists = !!selectedSymptoms.find(el => el.id === symptom.id);
    if (alreadyExists) {
      return setSelectedSymptoms(
        selectedSymptoms.filter(el => el.id !== symptom.id)
      );
    }
    setSelectedSymptoms(selectedSymptoms.concat([symptom]));
  }

  return (
    <PageWrapper title="Patient Case Summary">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <MultiCheckbox
          description="Please select all of the symptoms the patient experienced."
          required
          label="Symptom(s)"
        >
          {symptoms.map(symptom => (
            <Checkbox
              checked={!!selectedSymptoms.find(el => el.id === symptom.id)}
              key={symptom.id}
              label={symptom.name}
              onChange={() => handleSymptom(symptom)}
            />
          ))}
        </MultiCheckbox>

        <div style={{ padding: '20px 0' }}>
          <Select
            description="How did the patient describe the pain?"
            label="Pain Quality"
            {...register('painQuality')}
          >
            {painQuality.map(el => (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            ))}
          </Select>
        </div>

        <div style={{ padding: '20px 0' }}>
          <Select
            description="What was the severity of the symptoms on a scale of 0-10?"
            label="Severity"
            {...register('severity')}
          >
            {new Array(11).fill('').map((el, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
        </div>

        <Input
          as="textarea"
          rows={4}
          description="This space is available for you to include a brief summary to provide additional context and insight regarding what is pictured. If you selected “none of these” for any attributes, please use this place to explain. We encourage you to include any additional information about the image, any care provided or actions taken, and how it may have been resolved in the end."
          label="Case Brief"
          required
          {...register('caseSummary')}
        />

        {multiError && (
          <Text error style={{ marginTop: 40 }}>
            {multiError}
          </Text>
        )}
        <NavButtons submitDisabled={false} submitText="Next" />
      </Form>
    </PageWrapper>
  );
}

ImageUploadPatientSummary.propTypes = {
  painQuality: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  setUploadData: PropTypes.func.isRequired,
  symptoms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  uploadData: PropTypes.shape({
    case_summary: PropTypes.string,
    pain_quality: PropTypes.string,
    severity: PropTypes.string,
    symptom_ids: PropTypes.array,
  }),
};

ImageUploadPatientSummary.defaultProps = {};

export default ImageUploadPatientSummary;
