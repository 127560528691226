import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { media } from 'styles/theme';

import Link from 'components/Link';
import Text from 'components/Text';

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-size: 14px;
  font-weight: 700;
`;

const Body = styled(Text)`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 16px;
  margin-bottom: 28px;
`;

const StyledSubscriptionText = styled.div`
  grid-area: text;
  margin-top: 40px;
  ${media.ns} {
    margin-top: 0;
  }
`;

function SubscriptionText() {
  return (
    <StyledSubscriptionText>
      <Title>We are committed to accessibility.</Title>

      <Body>
        If you are unable to afford the base subscription rate (including if you
        are in a{' '}
        <Link
          href="https://datatopics.worldbank.org/world-development-indicators/the-world-by-income-and-region.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          lower-income country
        </Link>
        ) please <Link href="mailto:contact@mmatlas.com">contact us</Link> to
        discuss.
      </Body>

      <Body>
        Conversely, if you are able to commit to a higher monthly rate, we
        invite you to take advantage of our Pay What You Want model that allows
        you to invest in the work of maternal health equity by subscribing at
        the highest level that is comfortable to you. Your higher commitment
        makes the MM Atlas available to others who cannot afford to access it
        and goes to support those who so generously share images with us through
        our community partner organizations.
      </Body>
    </StyledSubscriptionText>
  );
}

SubscriptionText.propTypes = {};

SubscriptionText.defaultProps = {};

export default SubscriptionText;
