import * as React from 'react';
import PropTypes from 'prop-types';

import HeaderLink from './HeaderLink';
import LogoutLink from './LogoutLink';
import routes from 'utils/routes';

function AdminLinks({ location }) {
  return (
    <>
      <HeaderLink
        active={!location.pathname.includes('invite')}
        to={routes.admin}
      >
        Dashboard
      </HeaderLink>
      <HeaderLink
        active={location.pathname.includes('invite')}
        to={routes.invite}
      >
        Invite
      </HeaderLink>
      <LogoutLink />
    </>
  );
}

AdminLinks.propTypes = {
  location: PropTypes.any.isRequired,
};

AdminLinks.defaultProps = {};

export default AdminLinks;
