function useHelpItems(selectedFilters) {
  const helpItems = [
    {
      active: window?.scrollTo(0, 0),
      content:
        'Select filters from this sidebar to narrow down the search results. You can apply multiple filters from any of the filter categories.',
      maxWidth: 202,
      selector: '[data-tour="filters-sidebar"]',
      bypassElem: true,
      position: 'right',
      title: 'How do I apply filters?',
      text: 'Best practices for using filters',
    },
    {
      content:
        'You can type new conditions or symptoms into this search bar to generate new search results.',
      maxWidth: 202,
      selector: '[data-tour="searchbar"]',
      position: 'right',
      title: 'How do I start a new search?',
      text: 'Start a brand new search',
    },
    {
      content:
        "All of your applied filters appear in this bar. If you're not seeing a lot of results, you may have too many filters applied. Remove some of your filters to broaden your search by clicking the “x” next to the search term.",
      hide: selectedFilters?.length === 0,
      maxWidth: 305,
      selector: '[data-tour="selected-filters"]',
      position: 'right',
      title: 'Why am I not seeing a lot of results?',
      text: 'Manage your filters',
    },
    {
      content:
        'Click on any image to view it larger, read more specific details, and see the contact information of the image owner.',
      maxWidth: 363,
      selector: '[data-tour="search-result-item"]:first-of-type',
      position: 'top',
      title: 'How do I view more information about an image?',
      text: 'Read more about an image',
    },
  ];
  return helpItems;
}

export default useHelpItems;
