import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loading from 'components/Loading';
import Input from 'components/Input';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';

const Suggestion = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: 18px 20px;
  text-decoration: none;
  text-align: left;
  width: 100%;
  :hover {
    border-left: 2px solid ${({ theme }) => theme.colors.brown2};
  }
  span:first-of-type {
    color: ${({ theme }) => theme.colors.darkBrown};
    ${({ theme }) => theme.fonts.p}
    font-weight: 500;
  }
  span:last-of-type {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.darkBrown};
    font-size: 14px;
    margin-left: 13px;
    margin-top: 2px;
  }
`;

const ResultList = styled.div`
  position: absolute;
  top: 83px;
  left: 0;
  right: 0;
`;

function Address({ address, error, onChange, onSelect }) {
  function handleSelect(address) {
    geocodeByAddress(address)
      .then(results => {
        const obj = {};
        results[0].address_components.forEach(el => {
          if (el.types[0] === 'administrative_area_level_1') {
            obj.stateLong = el.long_name;
          }
          obj[el.types[0]] = el.short_name;
        });
        onSelect(obj);
      })
      .catch(error => console.error('Error', error));
  }

  const searchOptions = { types: ['address'] };

  return (
    <PlacesAutocomplete
      onChange={onChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      value={address}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative' }}>
          <Input
            error={error}
            label="Address"
            {...getInputProps({
              color: 'white',
              autocomplete: 'off',
              required: true,
            })}
          />
          <ResultList>
            {loading && (
              <Suggestion>
                <Loading />
              </Suggestion>
            )}
            {suggestions.map(suggestion => {
              const {
                mainText,
                secondaryText,
              } = suggestion.formattedSuggestion;
              return (
                <Suggestion
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {})}
                >
                  <span>{mainText}</span>
                  <span>{secondaryText}</span>
                </Suggestion>
              );
            })}
          </ResultList>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

Address.propTypes = {
  address: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Address.defaultProps = {};

export default Address;
