import * as React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox';
import DatePicker from 'components/DatePicker';
import Input from 'components/Input';
import Select from 'components/Select';

import countries from './countries';

function ProviderInputs({
  control,
  errors,
  register,
  required,
  noNpi,
  setNoNpi,
  unregister,
}) {
  React.useEffect(() => {
    return () => {
      unregister('country');
      unregister('credentials');
      unregister('certificationDate');
      unregister('licenseNumber');
      unregister('licenseType');
      unregister('npiNumber');
      unregister('region');
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!noNpi) {
      unregister('licenseNumber');
      unregister('licenseType');
    }
  }, [noNpi, unregister]);

  function npiValid(v) {
    if (!noNpi && !v) {
      return 'NPI number required';
    }
    if (!noNpi && v.length !== 10) {
      return 'NPI number must be 10 characters';
    }
    return true;
  }

  return (
    <>
      <Input
        error={errors?.credentials?.message}
        label="Credentials"
        required
        {...register('credentials', { required })}
      />
      <DatePicker
        control={control}
        error={errors?.certificationDate?.message}
        label={'Certification Date'}
        name="certificationDate"
        required
      />
      <Select
        error={errors?.country?.message}
        label="Country of Operation"
        required
        {...register('country', { required })}
      >
        <option value="">Select your country</option>
        {countries.map(el => (
          <option key={el.code} value={el.code}>
            {el.name}
          </option>
        ))}
      </Select>
      <Input
        error={errors?.region?.message}
        label="State/Region"
        required
        {...register('region', { required })}
      />
      <Input
        error={errors?.npiNumber?.message}
        label="National Provider Identifier (NPI)"
        {...register('npiNumber', {
          validate: npiValid,
        })}
      />
      <Checkbox
        label="I do not have an NPI/I am not in the US"
        checked={noNpi}
        onChange={() => setNoNpi(!noNpi)}
        maxlength={10}
      />
      {noNpi && (
        <>
          <Input
            error={errors?.licenseType?.message}
            label="License/Certification"
            required
            {...register('licenseType', {
              required: noNpi ? required : false,
            })}
          />
          <Input
            error={errors?.licenseNumber?.message}
            label="License/Certification Number"
            required
            {...register('licenseNumber', {
              required: noNpi ? required : false,
            })}
          />
        </>
      )}
    </>
  );
}

ProviderInputs.propTypes = {
  control: PropTypes.any.isRequired,
  errors: PropTypes.shape({
    credentials: PropTypes.shape({
      message: PropTypes.string,
    }),
    certificationDate: PropTypes.shape({
      message: PropTypes.string,
    }),
    country: PropTypes.shape({
      message: PropTypes.string,
    }),
    licenseNumber: PropTypes.shape({
      message: PropTypes.string,
    }),
    licenseType: PropTypes.shape({
      message: PropTypes.string,
    }),
    npiNumber: PropTypes.shape({
      message: PropTypes.string,
    }),
    region: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  register: PropTypes.func.isRequired,
  required: PropTypes.string,
  noNpi: PropTypes.bool,
  setNoNpi: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

ProviderInputs.defaultProps = {};

export default ProviderInputs;
