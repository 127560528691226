import * as React from 'react';

function useImagePreview(file) {
  const [url, setUrl] = React.useState(null);
  const [reader] = React.useState(new FileReader());

  React.useEffect(() => {
    reader.addEventListener('load', readerLoad);
    return () => reader.removeEventListener('load', readerLoad);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (file && file.length) {
      reader.readAsDataURL(file[0]);
    }
    // eslint-disable-next-line
  }, [file]);

  function readerLoad() {
    setUrl(reader.result);
  }

  return url;
}

export default useImagePreview;
