import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { navigate } from 'utils/router';
import routes from 'utils/routes';

import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';

function AdminRoute({ as: Component, ...rest }) {
  const adminAccess = !!useSelector(
    state => state.auth?.policies?.user?.access_admin
  );

  React.useEffect(() => {
    if (!adminAccess) {
      navigate(routes.login);
    }
  }, [adminAccess]);

  if (!adminAccess) {
    return (
      <Layout>
        <PageLoading />
      </Layout>
    );
  }

  return <Component {...rest} />;
}

AdminRoute.propTypes = {
  as: PropTypes.any.isRequired,
};

AdminRoute.defaultProps = {};

export default AdminRoute;
