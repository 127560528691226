import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

import Button from 'components/Button';
import Input from 'components/Input';
import ProviderInputs from './ProviderInputs';
import Select from 'components/Select';
import StudentInputs from './StudentInputs';
import Title from '../../../components/Title';

import { addToast } from 'reducers/toasts';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { navigate } from 'utils/router';
import { sendError } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

const StyledJoinForm = styled.form`
  margin-top: -14px;
`;

function JoinForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [noNpi, setNoNpi] = React.useState(false);
  const defaultValues = { occupation: 'provider' };
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
    unregister,
  } = useForm({ defaultValues });
  const occupation = watch('occupation');
  const isStudent = occupation === 'student';
  const isProvider = occupation === 'provider';

  function onSubmit(data) {
    setLoading(true);
    const defaultPayload = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      occupation: data.occupation,
      referred: data.referred,
    };

    const provider = {
      certification_date: dayjs(data.certificationDate).format('YYYY-MM-DD'),
      country_of_operation: data.country,
      credentials: data.credentials,
      id_number: data.licenseNumber || '',
      identifier_type: data.licenseType || '',
      npi_number: data.npiNumber || '',
      region: data.region || '',
    };

    const student = {
      school: data?.school,
      graduation_date: data?.gradDate,
      program: data?.program,
    };

    const payload = isProvider
      ? { ...defaultPayload, ...provider }
      : { ...defaultPayload, ...student };

    http
      .post(endpoints.createWaitlist, payload)
      .then(res => {
        navigate('/join/success');
      })
      .catch(err => {
        sendError('Error adding user to waitlist', err, payload);
        dispatch(
          addToast({
            type: 'error',
            message: 'We could not add you to the waitlist at this time.',
          })
        );
      })
      .finally(() => setLoading(false));
  }

  const required = 'This field is required';

  return (
    <StyledJoinForm onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors?.firstName?.message}
        label="First Name"
        required
        {...register('firstName', { required })}
      />
      <Input
        error={errors?.lastName?.message}
        label="Last Name"
        required
        {...register('lastName', { required })}
      />
      <Input
        error={errors?.email?.message}
        label="Email"
        required
        type="email"
        {...register('email', { required })}
      />
      <Select
        error={errors?.occupation?.message}
        label="Occupation"
        required
        {...register('occupation', { required })}
      >
        <option value="provider">Healthcare Professional</option>
        <option value="student">Student</option>
      </Select>

      <Title style={{ marginTop: 24, marginBottom: 0 }}>
        Help us verify you
      </Title>
      {isProvider && (
        <ProviderInputs
          control={control}
          errors={errors}
          register={register}
          required={required}
          isProvider={isProvider}
          noNpi={noNpi}
          setNoNpi={setNoNpi}
          unregister={unregister}
        />
      )}
      {isStudent && (
        <StudentInputs
          control={control}
          errors={errors}
          register={register}
          required={required}
          unregister={unregister}
        />
      )}
      <Input
        error={errors?.referred?.message}
        label="Organization Code (optional)"
        {...register('referred')}
      />
      <Button
        disabled={loading}
        loading={loading}
        type="submit"
        style={{ marginTop: 28 }}
      >
        Submit
      </Button>
    </StyledJoinForm>
  );
}

JoinForm.propTypes = {};

JoinForm.defaultProps = {};

export default JoinForm;
