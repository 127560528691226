import * as React from 'react';
// import PropTypes from 'prop-types';
import { colors } from 'styles/theme';

function Check(props) {
  return (
    <svg
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(45deg)' }}
      {...props}
    >
      <line x1={0} x2={10} y1={5} y2={5} stroke={colors.red} strokeWidth={2} />
      <line x1={5} x2={5} y1={0} y2={10} stroke={colors.red} strokeWidth={2} />
    </svg>
  );
}

Check.propTypes = {};

Check.defaultProps = {};

export default Check;
