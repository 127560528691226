import * as React from 'react';
import { useTour } from '@reactour/tour';

import Content from 'components/HelpUI/Tour/Content';

const itemStyle = {
  border: `1px solid #D8CEC4`,
  boxShadow: `0px 0px 18px rgba(0, 0, 0, 0.1)`,
};

function useHelp(items) {
  const { isOpen, setIsOpen, setCurrentStep, setSteps } = useTour();

  const steps = items.map(el => ({
    content: props => (
      <Content
        {...props}
        {...el}
        maxWidth={el.maxWidth}
        text={el.content}
        title={el.title}
      />
    ),
    position: el.position || null,
    selector: el.selector,
    style: itemStyle,
  }));

  const newItems = items.map((el, i) => ({
    ...el,
    onClick: () => {
      setCurrentStep(i);
      setIsOpen(true);
    },
  }));

  React.useEffect(() => {
    setSteps(steps);
    // eslint-disable-next-line
  }, []);

  return { helpItems: newItems, isOpen, setIsOpen };
}

export default useHelp;
