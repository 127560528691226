import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import JoinLayout from '../../components/JoinLayout';
import Text from 'components/Text';
import Title from '../../components/Title';

import routes from 'utils/routes';

const Body = styled(Text)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.serif};
`;

const TextWrapper = styled.div`
  margin: 0 auto;
  max-width: 540px;
  text-align: center;
`;

function JoinSuccess() {
  const msg = 'Thank you for your interest in The Melanated Mammary Atlas';
  return (
    <JoinLayout bannerText={msg}>
      <TextWrapper>
        <Title as="h2">
          Your request to join the movement to normalize brown breasts is
          currently being reviewed.
        </Title>
        <Body>
          We take security very seriously in order to protect the folks who so
          generously share their personal images with the MMAtlas. We appreciate
          your patience. Once your credentials are verified, we will be in touch
          with next steps. If we run into any issues, you’ll be the first to
          know! Verification generally takes less than 72 hours.
        </Body>
        <Body>
          Meanwhile, if you have any images of conditions on brown breasts, we’d
          love it if you’d share them with us here.
        </Body>
        <Button to={routes.imageUpload} style={{ marginTop: 60 }}>
          View the upload tool
        </Button>
      </TextWrapper>
    </JoinLayout>
  );
}

JoinSuccess.propTypes = {};

JoinSuccess.defaultProps = {};

export default JoinSuccess;
