import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Error } from '../styles';
import { Link as ReachLink } from 'utils/router';
import List from '../List';
import { StyledInput } from 'components/Input';
import SearchIcon from './SearchIcon';

const SearchButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 3px;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;

  position: absolute;
  right: 5px;
  top: 5px;
  ${({ disabled }) => disabled && `click-events: none;`}
`;

const StyledMobileSearch = styled(StyledInput)`
  height: 54px;
  ${({ hasMatches, theme }) =>
    hasMatches &&
    `
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

const SearchWrapper = styled.div`
  position: relative;
`;

function MobileSearch({
  buttonDisabled,
  checkError,
  error,
  errorText,
  matches,
  onChange,
  onSelect,
  searchLink,
  searchTerm,
  ...rest
}) {
  return (
    <SearchWrapper {...rest}>
      <StyledMobileSearch
        hasMatches={matches.length > 0}
        onChange={onChange}
        value={searchTerm}
      />
      <SearchButton
        as={ReachLink}
        disabled={buttonDisabled}
        onClick={checkError}
        to={searchLink}
      >
        <SearchIcon height={22} />
      </SearchButton>
      <List matches={matches} onSelect={onSelect} searchTerm={searchTerm} />
      {error && <Error>{errorText}</Error>}
    </SearchWrapper>
  );
}

MobileSearch.propTypes = {
  buttonDisabled: PropTypes.bool,
  checkError: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorText: PropTypes.string.isRequired,
  matches: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  searchLink: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
};

MobileSearch.defaultProps = {
  buttonDisabled: false,
  searchTerm: '',
};

export default MobileSearch;
