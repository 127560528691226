import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { animated, useSpring } from 'react-spring';

const StyledToggle = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
`;

function Toggle({ open, ...rest }) {
  const [rotation, setRotation] = React.useState(0);

  React.useEffect(() => {
    setRotation(rotation + 90);
    // eslint-disable-next-line
  }, [open]);

  const animate = useSpring({
    transform: `rotate(${rotation}deg)`,
    transformOrigin: 'center',
  });
  return (
    <StyledToggle {...rest}>
      <svg
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.11111 6.67999L0 6.67993V8.45771H7.11111H8.88889H16V6.67993H8.88889L7.11111 6.67999Z"
          fill="#84603F"
        />
        <animated.path
          d="M7.00006 8.88889L7 16H8.77778L8.77778 8.88889V7.11111L8.77778 0H7L7 7.11111L7.00006 8.88889Z"
          fill="#84603F"
          style={animate}
        />
      </svg>
    </StyledToggle>
  );
}

Toggle.propTypes = {
  open: PropTypes.bool.isRequired,
};

Toggle.defaultProps = {};

export default Toggle;
