import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { StyledInput } from 'components/Input';

const StyledCustomAmount = styled(StyledInput)`
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightTan};
  box-sizing: border-box;
  padding: 0 8px 10px 15px;
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

const Dollar = styled.span`
  font-weight: 500;
  position: absolute;
  top: 0px;
  left: 4px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0 32px 0 44px;
  padding-bottom: 16px;
`;

function CustomAmount(props) {
  return (
    <Wrapper>
      <Dollar>$</Dollar>
      <StyledCustomAmount {...props} />
    </Wrapper>
  );
}

CustomAmount.propTypes = {};

CustomAmount.defaultProps = {};

export default CustomAmount;
