import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Layout from 'components/Layout';
import PasswordCard from './components/PasswordCard';
import SubscriptionCard from './components/SubscriptionCard';
import YourSettings from './components/YourSettings';

import { media } from 'styles/theme';

const Grid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  justify-content: center;
  margin: 50px auto 140px;
  ${media.ns} {
    grid-template-columns: 424px 291px;
    margin: 50px auto 140px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

function Settings() {
  return (
    <Layout title="Settings">
      <Grid>
        <Column>
          <YourSettings />
        </Column>
        <Column>
          <SubscriptionCard />
          <PasswordCard />
        </Column>
      </Grid>
    </Layout>
  );
}

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
