import styled from 'styled-components/macro';
import { media } from 'styles/theme';
import { Link as ReachLink } from 'utils/router';

export const TooltipWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 22px 32px 32px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  :first-of-type {
    margin-right: 10px;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  ${Column} {
    width: 100%;
  }
  ${media.ns} {
    flex-direction: row;
    ${Column} {
      width: 50%;
    }
  }
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 0;
  text-align: left;
`;

export const Item = styled(ReachLink)`
  ${({ theme }) => theme.sizes.p}
  color: ${({ theme }) => theme.colors.darkBrown};
  font-family: ${({ theme }) => theme.fonts.serif};
  line-height: 27px;
  text-align: left;
  text-transform: capitalize;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
