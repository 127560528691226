import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { useSortBy, useTable } from 'react-table';

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  thead tr th {
    color: ${({ theme }) => theme.colors.darkBrown};
    font-size: 12px;
    font-weight: bold;
    opacity: 0.5;
    padding: 0px 16px 10px;
    text-align: left;
    text-transform: uppercase;
  }
  thead th:first-of-type {
    padding-left: 0;
  }
  tbody tr {
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.nearWhite};
  }
  tbody tr td {
    color: ${({ theme }) => theme.colors.darkBorwn};
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    max-width: 400px;
    padding: 16px;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }
`;

function Table({ columns, data, initialState, ...rest }) {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (isNaN(rowOneColumn)) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
              ? 1
              : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        },
      },
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  if (data.length === 0) {
    return null;
  }

  return (
    <StyledTable {...getTableProps()} {...rest}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
}

Table.propTypes = {
  columns: PropTypes.any.isRequired,
  data: PropTypes.array.isRequired,
  initialState: PropTypes.any,
};

Table.defaultProps = {
  initialState: null,
};

export default Table;
