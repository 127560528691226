import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Desc, Label, Red, StyledInput } from 'components/Input';
import DownArrow from './DownArrow';

const SelectWrapper = styled(StyledInput)`
  background-color: white;
  cursor: pointer;
  padding: 0;
  position: relative;
  :focus-within {
    border-color: ${({ theme }) => theme.colors.brown2};
  }
  select {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: inherit;
    margin: 0;
    padding: 15px 16px;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
    outline: none;
    line-height: inherit;
    height: 100%;
  }
  select::-ms-expand {
    display: none;
  }
`;

const StyledDownArrow = styled(DownArrow)`
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
`;

const Select = React.forwardRef(
  ({ children, description, label, required, wrapperStyle, ...rest }, ref) => {
    const hasDescription = !!description;
    return (
      <div style={{ padding: '10px 0', ...wrapperStyle }}>
        {label && (
          <div style={{ marginBottom: hasDescription ? 6 : 4 }}>
            <Label hasDescription={hasDescription}>
              {label}
              {required && <Red>*</Red>}
            </Label>
          </div>
        )}
        {hasDescription && (
          <Desc font="sansSerif" size="smallP">
            {description}
          </Desc>
        )}
        <SelectWrapper as="div">
          <select required={required} ref={ref} {...rest}>
            {children}
          </select>
          <StyledDownArrow height={10} />
        </SelectWrapper>
      </div>
    );
  }
);

Select.propTypes = {
  children: PropTypes.array,
  description: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  wrapperStyle: PropTypes.object,
};

Select.defaultProps = {
  children: null,
  description: null,
  label: null,
  required: false,
  wrapperStyle: {},
};

export default Select;
