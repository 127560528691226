import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DialogItem from './DialogItem';
import Top from './Top';

import { animated, useSpring } from '@react-spring/web';

const StyledDialog = styled(animated.aside)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.lightTan};
  border-radius: 5px;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 241px;

  position: fixed;
  bottom: 86px;
  right: 18px;
  li:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightTan};
  }
`;

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

function Dialog({ closeHelp, items, open }) {
  const styles = useSpring({
    transform: `translateY(${!open ? 15 : 0}px)`,
    opacity: open ? 1 : 0,
    pointerEvents: open ? 'auto' : 'none',
  });
  return (
    <StyledDialog style={styles}>
      <Top />
      <Ul>
        {items.map((el, i) => (
          <DialogItem closeHelp={closeHelp} key={i} {...el} />
        ))}
      </Ul>
    </StyledDialog>
  );
}

Dialog.propTypes = {
  closeHelp: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      selector: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  open: PropTypes.bool.isRequired,
};

Dialog.defaultProps = {};

export default Dialog;
