import styled from 'styled-components/macro';

const PageButton = styled.button`
  align-items: center;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.darkBrown};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 700;
  margin: 4px;
  height: 38px;
  justify-content: center;
  width: 38px;
  :disabled {
    background-color: ${({ theme }) => theme.colors.lightTan};
  }
`;

export default PageButton;
