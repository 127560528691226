import sendError from './sendError';

// MTS - Send error use to be part of helpers but it because to big.
// Adding this so we don't have to update each of the instances.
export { sendError };

export const formatSubscriptions = subscriptions => {
  return [
    {
      plan: 'free',
      price_id: 'free',
      price_name: 'Free',
      unit_amount_in_cents: 0,
    },
  ]
    .concat(subscriptions)
    .sort((a, b) => a.unit_amount_in_cents - b.unit_amount_in_cents);
};
