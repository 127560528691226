import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import RadioInput from 'components/RadioInput';
import SubscriptionStatus from 'components/SubscriptionStatus';
import Text from 'components/Text';

import { useSelector } from 'react-redux';
import { formatSubscriptions } from 'utils/helpers';

const StyledSubscriptionBox = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
`;

function SubscriptionBox({
  subscriptionStatic,
  subscriptionState,
  subscriptionStatus,
}) {
  const subscriptions = useSelector(({ auth }) => auth.meta.prices);
  let options = formatSubscriptions(subscriptions);

  if (subscriptionStatic) {
    options = options.filter(
      el => el.plan === subscriptionState?.subscriptionType
    );
  }

  function getText(option) {
    const price = Math.floor(option.unit_amount_in_cents / 100) || null;
    if (price) {
      return `$${price} | ${option?.price_name?.split(' ')[0]}`;
    }
    return 'Free';
  }

  const { subscriptionType, setSubscriptionType } = subscriptionState;
  return (
    <StyledSubscriptionBox>
      <SubscriptionStatus status={subscriptionStatus} />
      {options.map(option => {
        if (option.price_name === 'Yearly Rate') return null
        return (
          <RadioInput
            checked={option.plan === subscriptionType}
            key={option.plan}
            name="subscription-type"
            onChange={() => setSubscriptionType(option.plan)}
          >
            {getText(option)}
          </RadioInput>
        )
      })}
      {options.length === 0 && <Text>User does not have a subscription</Text>}
    </StyledSubscriptionBox>
  );
}

SubscriptionBox.propTypes = {
  subscriptionStatic: PropTypes.bool,
  subscriptionState: PropTypes.shape({
    subscriptionType: PropTypes.string.isRequired,
    setSubscriptionType: PropTypes.func.isRequired,
  }).isRequired,
  subscriptionStatus: PropTypes.string,
};

SubscriptionBox.defaultProps = {
  subscriptionStatic: false,
  subscriptionStatus: null,
};

export default SubscriptionBox;
