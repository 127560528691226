import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Buffer } from 'buffer';
import Overlay from './Overlay';

import http from 'utils/http';
import { sendError } from 'utils/helpers';

export const Wrapper = styled.div`
  background-color: rgba(146, 134, 127, 0.3);
  background-position: center;
  background-size: cover;
  overflow: hidden;
  ${({ dim, imageReady, url }) =>
    imageReady &&
    `
    background-image: url("${url}");
    height: ${dim.height}px;
    width: ${dim.width}px;
    `}
`;

export const Img = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: opacity 350ms ease-out;
`;

function ImageLoader({ alt, onLoaded, src, watermark, ...rest }) {
  const imageRef = React.useRef();
  const [dim, setDim] = React.useState({ height: 0, width: 0 });
  const [loading, setLoading] = React.useState(true);
  const [imageSrc, setImageSrc] = React.useState(null);
  const [imageReady, setImageReady] = React.useState(false);
  const [mounted, setMounted] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);

    http
      .get(src, {
        baseURL: '',
        responseType: 'arraybuffer',
        headers: {
          'mmatlas-API-Version': 1,
        },
      })
      .then(res => {
        let url = Buffer.from(res.data, 'binary').toString('base64');
        url = `data:${res.headers['content-type']};base64,${url}`;
        setImageSrc(url);
      })
      .catch(err => sendError('Error fetching image', err))
      .finally(setLoading(false));
    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line
  }, []);

  function handleLoaded() {
    if (mounted) {
      const { height, width } = imageRef.current.getBoundingClientRect();
      setDim({ height, width });
      onLoaded({ height, width });
      setImageReady(true);
    }
  }

  const overlayStyle = {
    height: dim?.height || 0,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
  };
  return (
    <Wrapper dim={dim} url={imageSrc} imageReady={imageReady} {...rest}>
      {watermark && <Overlay style={overlayStyle} />}
      {!loading && imageSrc && !imageReady && (
        <Img
          alt={alt}
          loading="lazy"
          onLoad={handleLoaded}
          ref={imageRef}
          src={imageSrc}
          {...rest}
        />
      )}
    </Wrapper>
  );
}

ImageLoader.propTypes = {
  alt: PropTypes.string.isRequired,
  onLoaded: PropTypes.func,
  src: PropTypes.string.isRequired,
  watermark: PropTypes.bool,
};

ImageLoader.defaultProps = {
  onLoaded: () => null,
  watermark: false,
};

export default ImageLoader;
