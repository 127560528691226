import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Text from 'components/Text';

import { media } from 'styles/theme';

const StyledDetailItem = styled.div`
  ${media.ns} {
    margin-bottom: 8px;
  }
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.bold};
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 3px;
`;

const Value = styled(Text)`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 16px;
  line-height: 20px;
  margin-top: 0;
  text-transform: capitalize;
`;

function DetailItem({ title, value, ...rest }) {
  if (!value) {
    return null;
  }

  return (
    <StyledDetailItem {...rest}>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </StyledDetailItem>
  );
}

DetailItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DetailItem.defaultProps = {
  value: null,
};

export default DetailItem;
