import * as React from 'react';
import PropTypes from 'prop-types';

import MobileLink from './MobileLink';

import routes from 'utils/routes';

function UserLinks({ loggedIn }) {
  return (
    <>
      {loggedIn && <MobileLink to={routes.home}>Search</MobileLink>}
      {loggedIn && <MobileLink to={routes.settings}>Evidence</MobileLink>}
      {loggedIn && <MobileLink to={routes.settings}>Settings</MobileLink>}
      <MobileLink to={routes.faq}>FAQ</MobileLink>
    </>
  );
}

UserLinks.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};

UserLinks.defaultProps = {};

export default UserLinks;
