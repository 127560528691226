import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Link from 'components/Link';
import Modal from 'components/Modal';
import Text from 'components/Text';

import routes from 'utils/routes';
import { media } from 'styles/theme';
import useLocalStorage from 'hooks/useLocalStorage';

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.darkNavy};
  max-width: 90vw;
  width: 100%;
  ${media.ns} {
    max-width: 537px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const COOKIE_KEY = 'mmatlas-cookie-consent-shown';

function CookieConsent() {
  const [showModal, setShowModal] = React.useState(false);
  const [bannerShown, setBannerShown] = useLocalStorage(COOKIE_KEY, false);

  React.useEffect(() => {
    if (!bannerShown) {
      setShowModal(true);
    }
  }, [bannerShown]);

  function handleClick() {
    setBannerShown(true);
    setShowModal(false);
  }

  return (
    <StyledModal overlayColor="rgba(255, 255, 255, 0.7)" showModal={showModal}>
      <Text
        center
        color="white"
        font="serif"
        style={{ maxWidth: 398, margin: '0 auto' }}
      >
        We use cookies and data collection tools to improve the user experience,
        recognize user preferences, and provide a safe experience. We do not
        sell your data or personal information to advertisers or other third
        parties. By requesting to join the movement or uploading an image, you
        consent to the terms of our policies which can be viewed by clicking on{' '}
        <StyledLink color="white" external href={routes.terms}>
          Terms
        </StyledLink>{' '}
        and{' '}
        <StyledLink color="white" external href={routes.privacy}>
          Privacy Policy.
        </StyledLink>
      </Text>
      <Button onClick={handleClick} style={{ marginTop: 25 }}>
        I Understand
      </Button>
    </StyledModal>
  );
}

CookieConsent.propTypes = {};

CookieConsent.defaultProps = {};

export default CookieConsent;
