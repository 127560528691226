import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Text from 'components/Text';
import Title from '../../components/Title';

const StyledMessageBox = styled.div`
  background-color: #f8efe8;
  padding: 22px 24px;
  align-self: flex-start;
`;

const Body = styled(Text)`
  font-size: 16px;
  line-heigth: 24px;
  font-family: ${({ theme }) => theme.fonts.serif};
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
  margin-top: 0;
`;

function MessageBox() {
  return (
    <StyledMessageBox>
      <StyledTitle>What to expect</StyledTitle>
      <Body>
        Due to the sensitive nature of the images within the directory, access
        is limited to healthcare professionals and students whose work can be
        directly informed by having exposure to examples of how conditions
        present on brown skin. We appreciate your patience and assistance as we
        verify your credentials to ensure the security of this tool.
      </Body>
      <Body as="div">
        Once you complete this form, you can expect to be:
        <ol>
          <li>Added to our Waitlist</li>
          <li>Verified in the order in which we received your request</li>
          <li>Invited to complete account set up & subscribe</li>
          <li>Given access to search and browse images</li>
        </ol>
      </Body>
      <Body>
        We'll email you every step of the way to keep you informed of where you
        are in the process!
      </Body>
    </StyledMessageBox>
  );
}

MessageBox.propTypes = {};

MessageBox.defaultProps = {};

export default MessageBox;
