import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import theme from 'styles/theme';
import './normalize.css';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Redux
import { persistor, store } from 'reducers';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';

const GlobalStyle = createGlobalStyle`
  body {
    background-color:  ${({ theme }) => theme.colors.nearWhite};
    color: ${({ theme }) => theme.colors.darkBrown};
    h1, h2, h3, h4, h5, h6 {
      color: ${({ theme }) => theme.colors.darkBrown};
      font-weight: 400;
    }
  }
`;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://6270b17c122c4a768e697b6c3b87c19f@o614076.ingest.sentry.io/5806593',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
