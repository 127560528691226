import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledDescription = styled.div`
  color: ${({ theme }) => theme.colors.mediumBrown};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 18px 15px 6px;
`;

function Description({ categoryOpen }) {
  function getText() {
    switch (categoryOpen) {
      case 'locations':
        return 'Where symptom was most prominent.';
      case 'painQuality':
        return 'Type of pain experienced.';
      case 'duration':
        return 'How long symptoms had been experienced at the time of the photo.';
      case 'timings':
        return 'When the pain/discomfort was most experienced.';
      default:
        return null;
    }
  }

  const text = getText();

  if (!text) {
    return null;
  }

  return <StyledDescription>{text}</StyledDescription>;
}

Description.propTypes = {
  categoryOpen: PropTypes.string,
};

Description.defaultProps = {
  categoryOpen: null,
};

export default Description;
