import * as React from 'react';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchOptions } from 'reducers/auth';

function useSearchOptions() {
  const dispatch = useDispatch();
  const currentOptions = useSelector(
    state => state?.auth?.meta?.search_options || []
  );

  React.useEffect(() => {
    if (!currentOptions || currentOptions.length === 0) {
      getSearchOptions();
    }
    // eslint-disable-next-line
  }, []);

  function getSearchOptions() {
    http(endpoints.getFilters)
      .then(({ data }) => {
        const searchOptions = data.searchable;
        dispatch(updateSearchOptions(searchOptions));
      })
      .catch(err =>
        sendError('Unable to get options in useSearchOptions', err)
      );
  }
  return currentOptions;
}

export default useSearchOptions;
