import * as React from 'react';
// import PropTypes from 'prop-types';

function SearchIcon(props) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.7895 19.4924L16.8957 15.5978C18.348 13.9701 19.2495 11.814 19.2495 9.46022C19.2495 4.37778 15.1133 0.242661 10.0319 0.242661C4.94948 0.242661 0.813477 4.378 0.813477 9.46022C0.813477 14.5424 4.9497 18.6778 10.031 18.6778C11.8349 18.6778 13.5073 18.1501 14.9148 17.2701L18.9624 21.3177C19.2047 21.56 19.5347 21.6915 19.8647 21.6915C20.1947 21.6915 20.5247 21.5592 20.7671 21.3177C21.2956 20.8124 21.2956 19.9977 20.7894 19.4924L20.7895 19.4924ZM3.38704 9.46C3.38704 5.80756 6.35704 2.81622 10.0308 2.81622C13.7055 2.81622 16.6533 5.80778 16.6533 9.46C16.6533 13.1122 13.6833 16.1038 10.0317 16.1038C6.37926 16.1038 3.38704 13.1338 3.38704 9.46Z"
        fill="white"
      />
    </svg>
  );
}

SearchIcon.propTypes = {};

SearchIcon.defaultProps = {};

export default SearchIcon;
