import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link as ReachLink } from 'utils/router';

const StyledLink = styled.a`
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.primary};
  font-weight: 500;
  outline: 0;
  opacity: 1;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    opacity: 0.5;
  }
  transition: all 150ms ease-out;
  ${({ size, theme }) => size && theme.sizes[size]}
`;

function Link({ external, ...rest }) {
  const propsObj = {
    ...rest,
    rel: external ? 'noopener noreferrer' : undefined,
    target: external ? '_noopener noreferrer' : undefined,
  };
  return <StyledLink as={propsObj.to ? ReachLink : 'a'} {...propsObj} />;
}

Link.propTypes = {
  external: PropTypes.bool,
};

Link.defaultProps = {
  external: false,
};

export default Link;
