import * as React from 'react';

function CloseIcon() {
  return (
    <svg width="7" height="7" viewBox="0 0 7 7" fill="none">
      <path
        d="M7 0.909859L6.08913 0L3.49966 2.5888L0.910531 0L0 0.910195L2.58913 3.49933L0 6.08846L0.910531 6.99866L3.49966 4.40986L6.08913 6.99866L7 6.08846L4.41053 3.49933L7 0.909859Z"
        fill="#AC8058"
      />
    </svg>
  );
}

CloseIcon.propTypes = {};

CloseIcon.defaultProps = {};

export default CloseIcon;
