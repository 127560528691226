import React from 'react'; // import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Banner from './Banner';
import FirstLoginModal from './FirstLoginModal';
import { Helmet } from 'react-helmet-async';
import HelpUI from 'components/HelpUI';
import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';
import Search from 'components/Search';
import WhatOthersAreViewing from './WhatOthersAreViewing';

import useHelp from 'hooks/useHelp';
import useLocalStorage from 'hooks/useLocalStorage';
import useSearchOptions from './useSearchOptions';
import { useSelector } from 'react-redux';

const StyledSearch = styled(Search)`
  margin-top: 24px;
  input {
    border-color: ${({ theme }) => theme.colors.white};
  }
  a {
    background-color: #b87f54 !important;
  }
`;

const StyledLayout = styled(Layout)``;

const FIRST_TIME_KEY = 'mmatlas-user-first-login';

const items = [
  {
    content:
      'Hover over conditions or symptoms to see a list of searchable terms.',
    selector: '[data-tour="tour-home-step-1"]',
    position: 'top',
    title: 'What search terms can I use?',
    text: 'View our searchable terms',
  },
  {
    content:
      'Start typing a condition or symptom and select a search term from the pre-populated dropdown.',
    maxWidth: 202,
    position: 'right',
    selector: '[data-tour="tour-home-step-2"]',
    title: 'How do I start a search?',
    text: 'Learn how to create a search',
  },
];

function Home() {
  const { helpItems, setIsOpen } = useHelp(items);
  const firstName = useSelector(state => state.auth.data.first_name);
  const searchOptions = useSearchOptions();
  const [firstLogin, setFirstLogin] = useLocalStorage(FIRST_TIME_KEY, true);
  const [searchError, setSearchError] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (firstLogin) {
      setShowModal(true);
      setFirstLogin(false);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return () => setIsOpen(false);
    // eslint-disable-next-line
  }, []);

  if (!searchOptions) {
    return (
      <StyledLayout containerStyle={{ padding: 0 }}>
        <PageLoading />
      </StyledLayout>
    );
  }

  return (
    <StyledLayout containerStyle={{ padding: 0 }}>
      <FirstLoginModal
        firstName={firstName}
        onClose={() => setShowModal(false)}
        showModal={showModal}
      />
      <Helmet title="MMAtlas | Search" />
      <Banner firstName={firstName} searchOptions={searchOptions}>
        <StyledSearch
          data-tour="tour-home-step-2"
          error={searchError}
          setSearchError={setSearchError}
        />
      </Banner>
      <WhatOthersAreViewing />
      <HelpUI items={helpItems} />
    </StyledLayout>
  );
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
