import * as React from 'react';
import PropTypes from 'prop-types';

import HeaderLink from './HeaderLink';
import LogoutLink from './LogoutLink';

import routes from 'utils/routes';
import { useSelector } from 'react-redux';

function UserLinks({ location }) {
  const loggedIn = useSelector(({ auth }) => !!auth.data.access_token);
  return (
    <>
      {loggedIn && (
        <HeaderLink
          active={
            location.pathname === '/' ||
            location.pathname.includes('/search-results')
          }
          to={routes.home}
        >
          Search
        </HeaderLink>
      )}
      {loggedIn && (
        <HeaderLink
          active={location.pathname.includes('resources')}
          to={routes.resources}
        >
          Evidence
        </HeaderLink>
      )}
      {loggedIn && (
        <HeaderLink
          active={location.pathname.includes('settings')}
          to={routes.settings}
        >
          Settings
        </HeaderLink>
      )}
      <LogoutLink />
      <HeaderLink active={location.pathname.includes('faq')} to={routes.faq}>
        FAQ
      </HeaderLink>
    </>
  );
}

UserLinks.propTypes = {
  location: PropTypes.any.isRequired,
};

UserLinks.defaultProps = {};

export default UserLinks;
