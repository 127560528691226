import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTransition, animated } from 'react-spring';

import Button from 'components/Button';
import Text from 'components/Text';

const Row = styled.div`
  align-items: center;
  justify-self: flex-end;
  display: flex;
  margin-top: 26px;
`;

const LinkText = styled(Text)`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledFilterActions = styled(animated.div)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -4px 4px rgba(73, 47, 26, 0.05);
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 180px;
  padding: 21px 20px 27px;
  width: 100%;
`;

function FilterActions({ clearFilters, close, open, selectedFilters }) {
  const transitions = useTransition(open, {
    from: {
      opacity: 0,
      transform: 'translateY(30px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(30px)',
    },
  });

  return transitions(
    (props, item) =>
      item && (
        <StyledFilterActions style={props}>
          <Text style={{ fontSize: 12, fontStyle: 'italic', margin: 0 }}>
            {selectedFilters.length} filter
            {selectedFilters.length === 1 ? '' : 's'} selected
          </Text>
          <Button
            onClick={close}
            style={{ height: 53, minHeight: 53, width: 222 }}
          >
            Apply Filters
          </Button>
          <Row>
            <LinkText style={{ marginRight: 16 }} onClick={clearFilters}>
              Clear All
            </LinkText>
            <LinkText style={{ marginRight: 16 }}>|</LinkText>
            <LinkText onClick={close}>Cancel</LinkText>
          </Row>
        </StyledFilterActions>
      )
  );
}

FilterActions.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
};

FilterActions.defaultProps = {
  selectedFilters: [],
};

export default FilterActions;
