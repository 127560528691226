import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import JoinForm from './JoinForm';
import JoinLayout from '../../components/JoinLayout';
import MessageBox from './MessageBox';

import { media } from 'styles/theme';

const Grid = styled.div`
  display: grid;
  grid-gap: 52px;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-bottom: 60px;
  ${media.ns} {
    grid-template-columns: 350px 350px;
    margin: 0 20px 60px;
  }
`;

function JoinIndex() {
  return (
    <JoinLayout bannerText="I'd like to join the movement">
      <Grid>
        <MessageBox />
        <JoinForm />
      </Grid>
    </JoinLayout>
  );
}

JoinIndex.propTypes = {};

JoinIndex.defaultProps = {};

export default JoinIndex;
