import * as React from 'react';
// import PropTypes from 'prop-types';

import NotFound from 'components/NotFound';
import Router from 'components/Router';

import JoinIndex from './pages/join-index';
import JoinSuccess from './pages/success';

function Join() {
  return (
    <Router>
      <JoinIndex path="/" component={JoinIndex} />
      <JoinSuccess path="/success" component={JoinSuccess} />
      <NotFound default />
    </Router>
  );
}

Join.propTypes = {};

Join.defaultProps = {};

export default Join;
