import * as React from 'react';
// import PropTypes from 'prop-types';

import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Router from 'components/Router';
import SearchResultsIndex from './pages/searchResultsIndex';
import SearchResultsDetail from './pages/searchResultsDetail';

function SearchResults() {
  return (
    <Router>
      <AuthenticatedRoute as={SearchResultsIndex} path="/" needsSubscription />
      <AuthenticatedRoute
        as={SearchResultsDetail}
        path="/:id"
        needsSubscription
      />
    </Router>
  );
}

SearchResults.propTypes = {};

SearchResults.defaultProps = {};

export default SearchResults;
