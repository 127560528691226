import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

// Reducers
import auth from './auth';
import toasts from './toasts';

const appReducer = combineReducers({
  auth,
  toasts,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    const initialState = appReducer(undefined, action);
    return initialState;
  }

  return appReducer(state, action);
};

const suffix = process.env.NODE_ENV;

const persistConfig = {
  key: `MMAtlas - ${suffix}`,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: `MMAtlas - ${suffix}`,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  ],
});

// Hot reloading
// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   // module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
//   module.hot.accept('./', () => store.replaceReducer(rootReducer));
// }
export let persistor = persistStore(store);
