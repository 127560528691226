import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Link as ReachLink } from 'utils/router';

import Loading from '../Loading';

export const buttonStyles = css`
  align-items: center;
  background-color: ${({ secondary, theme }) =>
    secondary ? theme.colors.secondary : theme.colors.primary};
  border: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: ${({ $small, theme }) => ($small ? 13 : 16)}px;
  font-weight: bold;
  height: ${({ $small, theme }) => ($small ? 38 : 60)}px;
  justify-content: center;
  margin-top: 8px;
  padding: ${({ $small, theme }) => ($small ? '0 24px' : '0 21px')};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: ${({ $small, theme }) => ($small ? 'initial' : '100%')};
  :disabled {
    opacity: 0.7;
  }
`;

const StyledButton = styled.button`
  ${buttonStyles}
`;

function Button({ children, loading, small, ...rest }) {
  function getAs() {
    if (rest.to) {
      return ReachLink;
    }
    if (rest.href) {
      return 'a';
    }
    return undefined;
  }
  return (
    <StyledButton as={getAs()} $small={small} {...rest}>
      {loading ? <Loading color="white" /> : children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  small: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  small: false,
};

export default Button;
