const routes = {
  // admin routes
  admin: '/admin', // pending users screen is default
  adminImage: '/admin/image',
  allImages: '/admin/all-images',
  allUsers: '/admin/all-users',
  deniedImages: '/admin/denied-images',
  deniedUsers: '/admin/denied-users',
  imageDetail: '/admin/image',
  invite: '/admin/invite',
  invitedUsers: '/admin/invited-users',
  pendingImages: '/admin/pending-images',
  pendingUser: '/admin/pending-user',
  pendingUsers: '/admin',
  user: '/admin/user',
  userDetail: '/admin/user',

  // user routes
  home: '/',
  help: '/faq', // MTS 3.15.23 - This is now changed to /faq
  faq: '/faq',
  imageUpload: '/image-upload',
  searchResults: '/search-results',
  settings: '/settings',
  resources: '/resources',
  subscription: '/subscription',
  subscriptionRedirect: '/subscription?expired=true',
  subscriptionSuccess: '/invitations/subscription-success',

  // unauthenticated routes
  login: '/login',
  logout: '/logout',

  // external
  privacy: 'https://www.mmatlas.com/privacy-policy',
  terms: 'https://www.mmatlas.com/terms',
};

export default routes;
