import * as React from 'react';
// import PropTypes from 'prop-types';

function RadioChecked() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="10"
        fill="white"
        stroke="#84603F"
        strokeWidth="2"
      />
      />
      <circle cx="11" cy="11" r="6" fill="#84603F" />
    </svg>
  );
}

RadioChecked.propTypes = {};

RadioChecked.defaultProps = {};

export default RadioChecked;
