import * as React from 'react';
import endpoints from 'utils/endpoints';
import { sendError } from 'utils/helpers';
import http from 'utils/http';

function useImageForm(props) {
  // multi checkboxes
  const [conditions, setConditions] = React.useState(props.conditions);
  const [locations, setLocations] = React.useState(props.locations);
  const [symptoms, setSymptoms] = React.useState(props.symptoms);

  const [imageOptions, setImageOptions] = React.useState(null);
  const [imageOptionsLoading, setImageOptionsLoading] = React.useState(true);

  const defaultValues = {
    firstName: props.uploader_first_name,
    lastName: props.uploader_last_name,
    email: props.uploader.email,
    photo: null,
    photoCredit: props.photo_credit,
    duration: props.duration,
    timing: props.timing,
    modifyingFactors: props.modifying_factors,
    painQuality: props.pain_quality,
    severity: props.severity,
    caseSummary: props.case_summary,
  };

  React.useEffect(() => {
    setImageOptionsLoading(true);
    http(endpoints.getImageOptions)
      .then(({ data }) => setImageOptions(data))
      .catch(err => sendError('Error getting image options in admin', err))
      .finally(() => setImageOptionsLoading(false));
  }, []);

  return {
    conditions,
    defaultValues,
    imageOptionsLoading,
    imageOptions,
    locations,
    setConditions,
    setLocations,
    setSymptoms,
    symptoms,
  };
}

export default useImageForm;
