import * as React from 'react';
// import PropTypes from 'prop-types';

function Hamburger(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.04 9.84001V8.16001H0.959961V9.84001H17.04ZM17.04 17.04V15.36H0.959961V17.04H17.04ZM17.04 2.64001V0.960007H0.959961V2.64001H17.04Z"
        fill="#AC8058"
      />
    </svg>
  );
}

Hamburger.propTypes = {};

Hamburger.defaultProps = {};

export default Hamburger;
