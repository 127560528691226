import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Input from 'components/Input';
import { StyledBreadcrumbHeader } from '../../components/BreadcrumbHeader';
import SubscriptionBox from '../../components/UserForm/SubscriptionBox';

import { emailValidation } from 'utils/validations';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';
import { useForm } from 'react-hook-form';
import useToast from 'hooks/useToast';

const ColumnTitle = styled.h2`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 383px 291px;
  grid-gap: 75px;
  justify-content: center;
  margin: 32px 30px;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
`;

const Col = styled.div``;

const StyledInvite = styled.form``;

function Invite({ location }) {
  const { createToast } = useToast();
  const [subscriptionType, setSubscriptionType] = React.useState('standard');
  const [loading, setLoading] = React.useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm();

  function onSubmit(data) {
    const payload = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      subscription_type: subscriptionType,
    };
    setLoading(true);
    http
      .post(endpoints.adminInvitation, payload)
      .then(({ data }) => {
        createToast({ message: 'User invitation sent successfully' });
        reset();
      })
      .catch(err => {
        sendError('Error sending invitation', err);
        createToast({
          type: 'error',
          message: 'Unable to send invitation at this time',
        });
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const subscriptionState = { subscriptionType, setSubscriptionType };
  return (
    <StyledInvite onSubmit={handleSubmit(onSubmit)}>
      <StyledBreadcrumbHeader>
        <Title>Invite User</Title>
        <Button loading={loading} type="submit" small style={{ maxWidth: 175 }}>
          Send Invitation
        </Button>
      </StyledBreadcrumbHeader>
      <Grid>
        <Col>
          <ColumnTitle>User Details</ColumnTitle>
          <Input
            name="email"
            error={errors?.email?.message}
            label="Email"
            required
            type="email"
            {...register('email', { required: true, ...emailValidation })}
          />
          <Input
            name="firstName"
            error={errors?.firstName?.message}
            label="First Name"
            required
            {...register('firstName', { required: 'First name is required' })}
          />
          <Input
            name="lastName"
            error={errors?.lastName?.message}
            label="Last Name"
            required
            {...register('lastName', { required: 'Last name is required' })}
          />
        </Col>
        <Col>
          <ColumnTitle style={{ paddingBottom: 6 }}>
            Subscription Rate
          </ColumnTitle>
          <SubscriptionBox
            subscriptionStatic={false}
            subscriptionState={subscriptionState}
          />
        </Col>
      </Grid>
    </StyledInvite>
  );
}

Invite.propTypes = {
  location: PropTypes.any.isRequired,
};

Invite.defaultProps = {};

export default Invite;
