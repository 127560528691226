import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import CloseButton from './CloseButton';

import useClickOutside from './useClickOutside';

const MobileTooltipMain = styled.span``;

const ChildrenWrapper = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  :first-child {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }
`;

const StyledMobileTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  transition: visibility 0s 250ms, opacity 250ms ease-out;
  visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};

  position: absolute;
  top: ${({ $top }) => $top}px;
  left: 20px;
  right: 20px;
  z-index: 2;

  :after {
    content: '';
    position: absolute;
    top: -14px;
    z-index: 2;
    /* position tooltip correctly */
    left: ${({ $arrowLeft }) => $arrowLeft - 20}px;
    /* the arrow */
    border: 8px solid #fff;
    border-color: transparent transparent #fff transparent;
    opacity: ${({ $open }) => ($open ? '1' : '0')};
    transition: visibility 0s 250ms, opacity 250ms ease-out;
    visibility: ${({ $open }) => ($open ? 'visible' : 'hidden')};
  }
`;

function MobileTooltip({ children, tooltipContent }) {
  const mainRef = React.useRef(null);
  const wrapperRef = React.useRef(null);
  const [top, setTop] = React.useState(0);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [arrowLeft, setArrowLeft] = React.useState(0);
  useClickOutside(mainRef, () => setTooltipOpen(false));

  React.useEffect(() => {
    if (wrapperRef?.current) {
      calculateOffset();
    }
    // eslint-disable-next-line
  }, [wrapperRef]);

  function calculateOffset() {
    const rect = wrapperRef.current.getBoundingClientRect();
    setTop(rect.bottom + 8);
    setArrowLeft(rect.left + rect.width / 2);
  }

  return (
    <MobileTooltipMain ref={mainRef}>
      <ChildrenWrapper
        onClick={() => setTooltipOpen(!tooltipOpen)}
        ref={wrapperRef}
      >
        {children}
      </ChildrenWrapper>
      <StyledMobileTooltip
        $arrowLeft={arrowLeft}
        $open={tooltipOpen}
        $top={top}
      >
        <CloseButton onClick={() => setTooltipOpen(false)} />
        {tooltipContent}
      </StyledMobileTooltip>
    </MobileTooltipMain>
  );
}

MobileTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  tooltipContent: PropTypes.any.isRequired,
};

MobileTooltip.defaultProps = {};

export default MobileTooltip;
