function paginationText({ limit, page, total }) {
  function lastNumber() {
    const num = (page - 1) * limit + limit;
    if (num > total) {
      return total;
    }
    return num;
  }
  return `(Showing ${(page - 1) * limit + 1}-${lastNumber()} of ${total})`;
}

export default paginationText;
