// This page is not being used but I'm going to leave it here in case any
// of these fields need to be reused.
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'utils/router';

import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import MultiCheckbox from 'components/MultiCheckbox';
import Text from 'components/Text';

import Form from '../../components/Form';
import PageWrapper from '../../components/PageWrapper';
import NavButtons from '../../components/NavButtons';

import { useForm } from 'react-hook-form';
import useScrollTop from 'hooks/useScrollTop';

function ConsentCredentials({ uploadData, setUploadData }) {
  useScrollTop();
  const titles = ['IBCLC', 'CLC/CLEC/CBS', 'MD/DO', 'RD/RN', 'Doula', 'Other'];
  const [selectedTitles, setSelectedTitles] = React.useState([]);
  const [titleError, setTitleError] = React.useState(null);

  React.useEffect(() => {
    const { credential_titles } = uploadData;
    const theseTitles = credential_titles.split(', ');
    if (!!checkOther()) {
      setSelectedTitles(theseTitles.slice(0, -1));
    }
    setSelectedTitles(theseTitles);
    // eslint-disable-next-line
  }, []);

  function checkOther() {
    const { credential_titles } = uploadData;
    const theseTitles = credential_titles.split(', ');
    const last = theseTitles[theseTitles.length - 1];
    // if you can't find the last value then other has been selected
    if (titles.find(el => el !== last)) {
      return last;
    }
    return '';
  }

  const defaultValues = {
    otherTitle: checkOther(),
    npiNumber: uploadData.npi_number,
  };

  const {
    handleSubmit,
    // formState: { errors },
    register,
  } = useForm({ defaultValues });

  function onSubmit(data) {
    setTitleError(null);
    let titlesStr = selectedTitles.join(', ');

    if (!selectedTitles.length) {
      return setTitleError('Please select at least one title.');
    }

    if (hasOtherTitle()) {
      titlesStr = `${titlesStr}, ${data.otherTitle}`;
    }

    setUploadData({
      ...uploadData,
      credential_titles: titlesStr,
      npi_number: data.npiNumber,
    });

    navigate('/image-upload/consent-media');
  }

  function hasOtherTitle() {
    return selectedTitles.find(el => el === 'Other');
  }

  function handleTitle(title) {
    const alreadyExists = !!selectedTitles.find(el => el === title);
    if (alreadyExists) {
      return setSelectedTitles(selectedTitles.filter(el => el !== title));
    }
    setSelectedTitles(selectedTitles.concat([title]));
  }

  const hasOther = hasOtherTitle();
  return (
    <PageWrapper title="Consent Part 1: Your Credentials">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <MultiCheckbox label="Relevent Titles" required>
          {titles.map((title, i) => (
            <Checkbox
              checked={!!selectedTitles.find(el => el === title)}
              key={i}
              label={title}
              onChange={() => handleTitle(title)}
            />
          ))}
        </MultiCheckbox>
        {hasOther && (
          <Input
            label="Other Title"
            {...register('otherTitle')}
            wrapperStyle={{ marginTop: 0, marginBottom: 20 }}
          />
        )}
        <Input
          label="NPI Number"
          description="Please provide your NPI number if you have one."
          {...register('npiNumber')}
        />
        {titleError && <Text error>{titleError}</Text>}
        <NavButtons submitDisabled={false} submitText="Next" />
      </Form>
    </PageWrapper>
  );
}

ConsentCredentials.propTypes = {
  setUploadData: PropTypes.func.isRequired,
  uploadData: PropTypes.any.isRequired,
};

ConsentCredentials.defaultProps = {};

export default ConsentCredentials;
