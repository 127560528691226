import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Loading from 'components/Loading';
import SearchResultItem from 'components/SearchResultItem';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { media } from 'styles/theme';
import { sendError } from 'utils/helpers';
import { useSelector } from 'react-redux';

export const StyledSearchResultItem = styled(SearchResultItem)`
  width: 100%;
  ${media.ns} {
    margin-right: 16px;
    width: initial;
  }
`;

const StyledRelatedImages = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  ${media.ns} {
    flex-direction: row;
  }
`;

const Title = styled.h2`
  font-size: 34px;
  ${media.sm} {
    margin-bottom: 10px;
  }
`;

function RelatedImages({ currentImage, location }) {
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);

  const params = new URLSearchParams(location.search);
  const terms = useSelector(({ auth }) => auth.meta.search_options);
  const term = params.get('term');
  const termObj = terms.find(t => t.name === term);

  React.useEffect(() => {
    getResults();
    // eslint-disable-next-line
  }, [currentImage]);

  function getResults() {
    setLoading(true);
    http
      .post(endpoints.search, { search: termObj?.id || '' })
      .then(({ data }) => {
        const filtered = data.data
          .filter(el => el.id !== currentImage)
          .slice(0, 3);
        setResults(filtered);
      })
      .catch(err => {
        sendError('Error fetching related images', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (!loading && results.length === 0) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <StyledRelatedImages data-tour="related-images">
      <Title>Related Images</Title>
      <Results>
        {results.map((el, i) => (
          <StyledSearchResultItem
            conditions={el.conditions}
            data-tour={i === 0 ? 'related-images-mobile' : undefined}
            height={244}
            id={el.id}
            key={el.id}
            symptoms={el.symptoms}
            photo_url={el.photo_url}
          />
        ))}
      </Results>
    </StyledRelatedImages>
  );
}

RelatedImages.propTypes = {
  currentImage: PropTypes.string.isRequired,
  location: PropTypes.any.isRequired,
};

RelatedImages.defaultProps = {};

export default RelatedImages;
