import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import http from 'utils/http';
import { sendError } from 'utils/helpers';

import Link from 'components/Link';
import Text from 'components/Text';

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.lightTan};
  margin-top: 70px;
  max-width: 490px;
  padding-top: 35px;
`;

function ConsentDownload({ filename, href }) {
  const [mounted, setMounted] = React.useState(true);
  const [url, setURL] = React.useState('');

  React.useEffect(() => {
    if (href) {
      http
        .get(href, {
          baseURL: '',
        })
        .then(({ data }) => {
          if (mounted) {
            const dataUrl = window.URL.createObjectURL(new Blob([data]));
            setURL(dataUrl);
          }
        })
        .catch(err => sendError('Error fetching consent form', err));
    }
    return () => setMounted(false);
    // eslint-disable-next-line
  }, [href]);

  if (!url || !href) {
    return null;
  }

  return (
    <Wrapper>
      <StyledLink href={url} download={filename}>
        <StyledText>Signed Consent Form</StyledText>
        <svg
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: 12 }}
        >
          <path
            d="M10.4165 5.3336L6.83366 8.9164V0.5H5.16648V8.9164L1.58368 5.3336L0.416504 6.5L6.0001 12.0836L11.5837 6.5L10.4165 5.3336Z"
            fill="#84603F"
          />
          <path
            d="M1.4165 13.8335H10.5829V15.4999H1.4165V13.8335Z"
            fill="#84603F"
          />
        </svg>
      </StyledLink>
    </Wrapper>
  );
}

ConsentDownload.propTypes = {
  filename: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

ConsentDownload.defaultProps = {};

export default ConsentDownload;
