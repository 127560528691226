import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Disclaimer from 'components/Disclaimer';
import Text from 'components/Text';
import Tooltip from './Tooltip';
import TooltipContent from './Tooltip/TooltipContent';

import { media } from 'styles/theme';

const StyledBanner = styled.div`
  align-items: center;
  background: linear-gradient(136.27deg, #84603f 25.51%, #614226 84.65%);
  display: flex;
  flex-direction: column;
  min-height: 318px;
  padding: 30px 20px 20px;
  ${Text} {
    color: ${({ theme }) => theme.colors.white};
  }
  ${media.ns} {
    padding: 50px 20px 40px;
  }
`;

const Greeting = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
`;

const SearchContainer = styled.div`
  max-width: 545px;
`;

const Title = styled(Text)`
  margin-top: 12px;
  margin-bottom: 0;
`;

function Banner({ children, firstName, searchOptions }) {
  const conditions = searchOptions.filter(el => el.type === 'condition');
  const symptoms = searchOptions.filter(el => el.type === 'symptom');
  const conditionsTooltip = (
    <TooltipContent items={alphabetize(conditions)} title="Conditions" />
  );
  const symptomsTooltip = (
    <TooltipContent items={alphabetize(symptoms)} title="Symptoms" />
  );

  function alphabetize(arr) {
    return arr.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <StyledBanner>
      <Greeting center>Hi, {firstName} ☀️</Greeting>
      <Title as="h1" center size="f1">
        Search by{' '}
        <span data-tour="tour-home-step-1">
          <Tooltip tooltipContent={conditionsTooltip}>conditions</Tooltip> or{' '}
          <Tooltip tooltipContent={symptomsTooltip}>symptoms</Tooltip>
        </span>
      </Title>
      <SearchContainer>
        {children}
        <Disclaimer color="white" />
      </SearchContainer>
    </StyledBanner>
  );
}

Banner.propTypes = {
  children: PropTypes.any,
  firstName: PropTypes.string.isRequired,
  searchOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
};

Banner.defaultProps = {
  children: null,
};

export default Banner;
