import * as React from 'react';
// import PropTypes from 'prop-types';
import { colors } from 'styles/theme';

function Check(props) {
  return (
    <svg
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5316 0.796387C12.8355 3.49903 9.71819 6.60639 6.93023 9.39771L2.31023 5.47771L0.490234 7.61271L6.09023 12.3727L7.07897 13.204L7.98897 12.294C10.9738 9.30357 14.5197 5.76969 17.509 2.77397L15.5316 0.796387Z"
        fill={colors.green}
      />
    </svg>
  );
}

Check.propTypes = {};

Check.defaultProps = {};

export default Check;
