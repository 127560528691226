import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';

import AdminLayout from '../../components/AdminLayout';
import EmptyListMessage from '../../components/EmptyListMessage';
import ImageLoader from 'components/ImageLoader';
import SortButton from 'components/SortButton';
import Table from 'components/Table';
import ViewLink from '../../components/ViewLink';

const StyledTable = styled(Table)`
  tbody tr td:first-child {
    padding: 2px;
  }
`;

function PendingImages({ location }) {
  const [loading, setLoading] = React.useState(false);
  const [pendingImages, setPendingImages] = React.useState([]);
  // eslint-disable-next-line
  const tableData = React.useMemo(() => pendingImages);

  React.useEffect(() => {
    setLoading(true);
    http
      .get(endpoints.pendingImages)
      .then(({ data }) => setPendingImages(data.data))
      .catch(err => sendError('Error fetching pending images', err))
      .finally(() => setLoading(false));
  }, []);

  // eslint-disable-next-line
  const columns = React.useMemo(() => [
    {
      Header: 'Image',
      accessor: 'photo_url',
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <ImageLoader
          alt="pending image"
          src={value}
          style={{ height: 47, width: 70 }}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Uploaded By',
      disableSortBy: true,
      accessor: image =>
        `${image.uploader_first_name} ${image.uploader_last_name}`,
    },
    {
      Header: 'Condition',
      disableSortBy: true,
      accessor: 'conditions',
      // eslint-disable-next-line
      Cell: ({ value }) => <span>{value.join(', ')}</span>,
    },
    {
      accessor: image => new Date(dayjs(image.submitted_at).format()),
      id: 'submitted',
      Header: props => <SortButton {...props}>Submitted</SortButton>,
      Cell: ({ value }) => dayjs(value).format('M/D/YY'),
      sortType: 'datetime',
    },
    {
      Header: '',
      disableSortBy: true,
      accessor: 'id',
      // eslint-disable-next-line
      Cell: ({ value }) => <ViewLink to={`${routes.adminImage}/${value}`} />,
    },
  ]);

  const initialState = {
    sortBy: [{ id: 'submitted', desc: false }],
  };

  return (
    <AdminLayout location={location} loading={loading} title="Pending Images">
      {!loading && pendingImages.length === 0 && (
        <EmptyListMessage type="pending images" />
      )}
      {pendingImages.length > 0 && (
        <StyledTable
          columns={columns}
          data={tableData}
          initialState={initialState}
        />
      )}
    </AdminLayout>
  );
}

PendingImages.propTypes = {
  location: PropTypes.any.isRequired,
};

PendingImages.defaultProps = {};

export default PendingImages;
