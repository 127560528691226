import * as React from 'react';

function RadioUnchecked() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="10"
        fill="white"
        stroke="#D8CEC4"
        strokeWidth="2"
      />
    </svg>
  );
}

RadioUnchecked.propTypes = {};

RadioUnchecked.defaultProps = {};

export default RadioUnchecked;
