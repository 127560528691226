import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { animated, useTransition } from 'react-spring';
import useLockScroll from 'hooks/useLockScroll';

const Overlay = styled(animated.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(19, 29, 41, 0.8);
  top: 0;
  left: 0;
  z-index: 5;
`;

const StyledModal = styled(animated.div)`
  background-color: white;
  padding: 32px 50px;
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 3;
`;

function Modal({ children, overlayColor, showModal, style, ...rest }) {
  const [isOpen, setIsOpen] = React.useState(showModal);
  useLockScroll(showModal);

  function getStartColor(color) {
    const splitColor = color.split(',');
    const withTransparency = splitColor.slice(0, -1).concat([' 0)']).join(',');
    return withTransparency;
  }

  const startColor = getStartColor(overlayColor);

  const transitions = useTransition(isOpen, {
    from: {
      backgroundColor: startColor,
      opacity: 0,
      transform: 'translate(-50%, 30px)',
    },
    enter: {
      backgroundColor: overlayColor,
      opacity: 1,
      transform: 'translate(-50%, 0px)',
    },
    leave: {
      backgroundColor: startColor,
      opacity: 0,
      transform: 'translate(-50%, 30px)',
    },
  });

  React.useEffect(() => {
    setIsOpen(showModal);
  }, [showModal]);

  return transitions(
    (styles, item) =>
      item && (
        <Overlay
          style={{
            backgroundColor: styles.backgroundColor,
          }}
        >
          <StyledModal
            style={{
              transform: styles.transform,
              opacity: styles.opacity,
              ...style,
            }}
            {...rest}
          >
            {children}
          </StyledModal>
        </Overlay>
      )
  );
}

Modal.propTypes = {
  overlayColor: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

Modal.defaultProps = {
  overlayColor: 'rgba(19, 29, 41, .8)',
  style: {},
};

export default Modal;
