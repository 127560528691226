import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';

const StyledModal = styled(Modal)`
  max-width: 616px;
  text-align: center;
  width: 100%;
  p {
    max-width: 492px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Title = styled(Text)`
  margin-bottom: 20px;
  margin-top: 8px;
`;

function FirstLoginModal({ firstName, onClose, showModal }) {
  return (
    <StyledModal showModal={showModal}>
      <Title as="h1" size="f1">
        Hi, {firstName}!
      </Title>
      <Text font="serif">
        Welcome to The Melanated Mammary Atlas®: your portal to a world of
        images displaying mammary related conditions on people of Asian,
        Indigenous, and African descent. Here, you will be able to explore how
        various conditions present on skin of color for education or assessment
        purposes.
      </Text>

      <Text font="serif">
        We believe the more you see, the better you can support. Bookmark this
        page for easy access to the tool. Happy searching!
      </Text>
      <Button
        onClick={onClose}
        style={{ maxWidth: 276, margin: '32px auto 18px' }}
      >
        Start Searching
      </Button>
    </StyledModal>
  );
}

FirstLoginModal.propTypes = {
  firstName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

FirstLoginModal.defaultProps = {};

export default FirstLoginModal;
