import * as React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonText,
  Error,
  SearchInput,
  SearchWrapper,
  StyledButton,
  StyledSearch,
} from './styles';
import List from './List';
import MobileSearch from './MobileSearch';

import routes from 'utils/routes';
import { navigate } from 'utils/router';
import { sendError } from 'utils/helpers';
import useIsMobile from 'hooks/useIsMobile';
import { useSelector } from 'react-redux';

function Search({
  defaultTerm,
  defaultSelection,
  error,
  setSearchError,
  small,
  ...rest
}) {
  const isMobile = useIsMobile();
  const terms = useSelector(({ auth }) => auth.meta.search_options);
  const [searchTerm, setSearchTerm] = React.useState(defaultTerm);
  const [selection, setSelection] = React.useState(defaultSelection);

  function checkError() {
    if (!selection) {
      setSearchError(true);
    } else {
      setSearchError(false);
    }
  }

  function getMatches() {
    if (!searchTerm.length || selection) {
      return [];
    }
    if (!terms || terms.length === 0) {
      sendError(
        "Sending to logout because we can't find terms",
        { error: 'custom error' },
        {
          terms,
        }
      );
      navigate(routes.logout);
      return [];
    }
    return terms.filter(term =>
      term.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  function handleSelection(value) {
    setSearchError(false);
    setSearchTerm(value.name);
    setSelection(value);
  }

  function buildSearchLink() {
    let params;
    if (selection) {
      params = new URLSearchParams({
        term: selection.name,
      });
    }
    return `${routes.searchResults}?${params ? params.toString() : ''}`;
  }

  function handleInput(e) {
    if (selection) {
      setSelection(null);
    }
    setSearchTerm(e.target.value);
  }

  const matches = getMatches();
  let searchLink = !selection ? '#' : buildSearchLink();
  const errorText =
    'Please try again using a term from the list of available conditions/symptoms that appears when you type into the search box';

  if (isMobile) {
    return (
      <MobileSearch
        buttonDisabled={false}
        checkError={checkError}
        error={error}
        errorText={errorText}
        matches={matches}
        onChange={handleInput}
        onSelect={handleSelection}
        searchLink={searchLink}
        searchTerm={searchTerm}
        {...rest}
      />
    );
  }

  return (
    <SearchWrapper small={small} {...rest}>
      <StyledSearch>
        <SearchInput
          hasMatches={matches.length > 0}
          onChange={handleInput}
          small={small}
          value={searchTerm}
        />
        <List
          matches={matches}
          onSelect={handleSelection}
          searchTerm={searchTerm}
          small={small}
        />
        {error && <Error>{errorText}</Error>}
      </StyledSearch>
      <StyledButton $resultsScreen={small} onClick={checkError} to={searchLink}>
        <ButtonText small={small}>Search</ButtonText>
      </StyledButton>
    </SearchWrapper>
  );
}

Search.propTypes = {
  defaultTerm: PropTypes.string.isRequired,
  defaultSelection: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  error: PropTypes.bool,
  small: PropTypes.bool,
  setSearchError: PropTypes.func.isRequired,
};

Search.defaultProps = {
  defaultSelection: null,
  defaultTerm: '',
  error: false,
  small: false,
};

export default Search;
