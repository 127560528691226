import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import FilterButton from '../FilterButton';

import { media } from 'styles/theme';

const StyledSelectedFilters = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-area: sfilters;
  margin-bottom: 20px;
  ${({ $show }) => !$show && 'display: none;'};
  ${media.sm} {
    margin: 12px 15px 0;
  }
`;

const StyledText = styled.span`
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
  ${media.sm} {
    display: none;
  }
`;

function SelectedFilters({ selectedFilters, toggleFilter }) {
  return (
    <StyledSelectedFilters
      data-tour="selected-filters"
      $show={selectedFilters.length}
    >
      <StyledText>Filters:</StyledText>
      {selectedFilters.map(el => (
        <FilterButton
          key={el.id}
          isSelected={true}
          onClick={() => toggleFilter(el)}
          withCloseButton
        >
          {el.name}
        </FilterButton>
      ))}
    </StyledSelectedFilters>
  );
}

SelectedFilters.propTypes = {
  selectedFilters: PropTypes.array.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};

SelectedFilters.defaultProps = {};

export default SelectedFilters;
