import axios from 'axios';
import { navigate } from 'utils/router';
import routes from 'utils/routes';
import { store } from 'reducers';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

// //// Response interceptor
axios.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // MTS - This is the interceptor for expired tokens
  error => {
    const authMsg = 'You need to sign in or sign up before continuing.';
    const errors = error?.response?.data?.errors;
    const errorMsg =
      errors && errors.length && errors.length > 0 ? errors[0] : '';

    // check to see if the subscription has been invalidated
    const validSubscription = checkValidSubscription(error);
    if (!validSubscription) {
      navigate(routes.subscriptionRedirect);
    }

    // Other 401 errors will result in a token refresh
    if (
      error.response.status === 401 &&
      !error.config.url !== '/login' &&
      errorMsg === authMsg
    ) {
      const refreshToken = store.getState()?.auth?.data?.refresh_token;
      if (refreshToken && window.location.pathname !== '/refresh-token') {
        navigate('/refresh-token');
      }
    }
    return Promise.reject(error);
  }
);

function checkValidSubscription(error) {
  const subscriptionExpired = error?.response?.data?.subscription_expired;
  if (subscriptionExpired) {
    return false;
  }
  return true;
}

export default axios;
