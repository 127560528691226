import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledConsent = styled.article`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 20px 0;
  max-height: 60vh;
  padding: 20px;
  overflow: auto;

  h1 {
    ${({ theme }) => theme.sizes.f3}
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    font-weight: 600;
    text-align: center;
  }
  h2 {
    ${({ theme }) => theme.sizes.p}
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    font-weight: 600;
    text-align: center;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.serif};
    ${({ theme }) => theme.sizes.p}
  }
`;

function Consent({ address, firstName, lastName, userState }) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dateStr = `${months[month]} ${day}, ${year}`;

  return (
    <StyledConsent>
      <header>
        <h1>MEDIA RELEASE FOR CLINICAL & EDUCATIONAL PURPOSES</h1>
      </header>

      <section>
        <p>
          This Media Release Agreement, (the "Agreement") is entered into and
          made effective as of <strong>{dateStr}</strong>, (the "Execution
          Date"), by and between Lioness Lactation, LLC, a Limited Liability
          Company, incorporated under the laws of the State of Florida, having
          an address of 3425 Bayside Lakes Blvd Ste 103-1032. Palm Bay 32909
          (“Recipient”) and{' '}
          <strong>
            {firstName} {lastName}
          </strong>
          , an Individual of the State of <strong>{userState}</strong>, having
          an address of <strong>{address}</strong>, (“Owner”) agree to the
          following Limited Release Agreement (“Agreement”).
        </p>
      </section>

      <section>
        <h2>Grant.</h2>
        <p>
          In exchange for the consideration consisting of increased exposure and
          publicity, Owner hereby grants to Recipient and Recipient’s assigns,
          licensees, and successors the non-exclusive, non-transferable,
          royalty-free perpetual right and license to use and display Owner’s
          electronic media with accompanying data, the images owned by Owner
          named and described herein, including but not limited to images of
          trademarks, copyrights and brand names owned by Owner (“Image” or
          “Images”) and provided by Owner to Recipient. Recipient may use and
          display Owner’s electronic media and accompanying data for any and all
          purposes, including marketing, promotion or advertising, in any and
          all media channels, cell phone applications, websites and social
          platforms (now known or hereafter existing), without compensation,
          payment or other consideration of any kind. The Image(s) may be used
          in conjunction with other photographic images and Recipient may edit,
          alter, reproduce, create derivative works from, publish or distribute
          the images for any lawful purpose in its sole discretion, with no
          obligation to Owner or right of inspection or approval.
        </p>
      </section>

      <section>
        <h2>Term.</h2>
        <p>
          The term of this Agreement is for twelve (12) years after the date it
          is signed by the Owner. Notwithstanding the preceding sentence, Owner
          may terminate this Agreement upon written notice provided to Recipient
          not less than ninety (90) days prior to termination. Such termination
          will not require Recipient to withdraw from circulation materials
          produced or finalized prior to the effective date of said termination.
        </p>
      </section>
      <section>
        <h2>Release.</h2>
        <p>
          Owner hereby releases Recipient and Recipient’s assigns, licensees,
          and successors from any claims that may arise regarding the use of any
          Image(s) including but not limited to any claims of defamation,
          invasion of privacy, or infringement of moral rights, rights of
          publicity, or copyright. Any captions and accompanying data will
          describe the contents of the Image(s), including listing the names of
          the Owner as the provider of the Image(s). Recipient is permitted, but
          not obligated, to include Owner’s name in connection with the image,
          unless Owner objects and wishes to remain anonymous, which Owner may
          do so by submitting a written request.
        </p>
      </section>
      <section>
        <p>
          Owner hereby releases, discharges and hold harmless and indemnify
          Recipient, its officers, directors and employees from any and all
          direct and indirect claims, demands, liability, loss, damages,
          expenses or causes of action (including attorneys’ fees and other
          costs for investigation and litigation) relating to, arising from or
          caused by the Image(s), the patient’s Likeness and/or the rights
          granted herein and/or any claims, demands, liability, loss, damages,
          expenses, or causes of action which Owner, Owner’s heirs,
          representatives, executors, administrators, or any other persons
          acting on their behalf or on behalf of Owner’s estate have or may have
          by reason of this Agreement.
        </p>
      </section>
      <section>
        <h2>Representations & Warranties.</h2>
        <p>
          Owner represents that: (i) the Image(s) is original and that he/she
          owns all rights, including copyright rights, in and to the Image(s) or
          the necessary permissions were obtained to grant the rights given
          herein; (ii) the patient depicted in the Image(s) is over the age of
          majority in his/her state of legal residence; and (iii) Recipient’s
          use of the Image(s) and/or the Likeness as depicted in the Image(s)
          granted herein will not violate the rights of any third party or any
          law. Owner understands that the Image(s) authorized for disclosure may
          be seen by members of the general public. However, the Image(s) shall
          be used specifically for medical and educational purposes only.
          Nevertheless, the Images may be used in any print or broadcast media
          including, but not necessarily limited to newspapers, pamphlets,
          educational films, internet, cell phone applications, and television.
          Therefore, the Image(s) shall be seen by scientists, medical
          researchers, and medical students and teachers, as well as by members
          of the general public.
        </p>
      </section>
      <section>
        <h2>Miscellaneous.</h2>
        <p>
          This Agreement may not be amended except in a written document signed
          by both parties. If a court finds any provision of this Agreement
          invalid or unenforceable, the remainder of this Agreement shall be
          interpreted so as best to affect the intent of the parties. This
          Agreement expresses the complete understanding of the parties with
          respect to the subject matter and supersedes all prior representations
          and understandings. Owner agree that: (i) this Agreement shall be
          governed by, and construed in accordance with, the laws of the State
          of Florida, without giving effect to any choice of law or conflict of
          law rules (whether of the State of Florida, or any other
          jurisdiction); (ii) any dispute relating to this Agreement shall be
          brought in the appropriate state or federal court having jurisdiction
          over the subject matter located in Brevard County, Florida and Owner
          hereby irrevocably consents to the personal jurisdiction of said
          courts and waive any claim of forum non-conveniens or lack of personal
          jurisdiction that Owner may have; and (iii) IN THE EVENT OF A DISPUTE
          AS BETWEEN OWNER AND RECIPIENT RELATING TO OR ARISING FROM THIS
          AGREEMENT AND THE RIGHTS GRANTED HEREIN, IN NO EVENT WILL RECIPIENT BE
          LIABLE TO OWNER FOR LOST PROFITS OR ANY PUNITIVE, SPECIAL, INDIRECT,
          INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND OR HAVE ANY LIABILITY
          EXCEEDING THE AGGREGATE AMOUNT OF TEN DOLLARS ($10.00).
        </p>
      </section>
    </StyledConsent>
  );
}

Consent.propTypes = {
  address: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userState: PropTypes.string.isRequired,
};

Consent.defaultProps = {};

export default Consent;
