import * as React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import dayjs from 'dayjs';

function StudentInputs({ user }) {
  return (
    <>
      <Input disabled label="School" value={user.student.school || ''} />
      <Input disabled label="Program" value={user.student.program || ''} />
      <Input
        disabled
        label="Graduation Date"
        value={dayjs(user.student.graduation_date).format('MM/DD/YYYY') || ''}
      />
    </>
  );
}

StudentInputs.propTypes = {
  user: PropTypes.shape({
    student: PropTypes.shape({
      graduation_date: PropTypes.string,
      program: PropTypes.string,
      school: PropTypes.string,
    }),
  }),
};

StudentInputs.defaultProps = {};

export default StudentInputs;
