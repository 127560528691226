import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'utils/router';
import styled from 'styled-components/macro';

import Consent from './Consent';
import Text from 'components/Text';
import NavButtons from '../../components/NavButtons';

import useScrollTop from 'hooks/useScrollTop';

const Form = styled.form`
  margin-top: 20px;
`;

function ConsentMedia({ uploadData }) {
  useScrollTop();

  function onSubmit(e) {
    e.preventDefault();
    navigate('/image-upload/consent-submit');
  }

  return (
    <div className="ImageUploadPatientSummary">
      <Text as="h1" center size="h1">
        Consent Part 1: Review Media Release
      </Text>
      <Consent
        address={uploadData.address}
        lastName={uploadData.last_name}
        firstName={uploadData.first_name}
        userState={uploadData.userState}
      />
      <Text font="serif"></Text>
      <Form onSubmit={onSubmit}>
        <NavButtons submitDisabled={false} submitText="Next" />
      </Form>
    </div>
  );
}

ConsentMedia.propTypes = {
  uploadData: PropTypes.shape({
    address: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    userState: PropTypes.string.isRequired,
  }).isRequired,
};

ConsentMedia.defaultProps = {};

export default ConsentMedia;
