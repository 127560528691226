import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Desc, InputWrapper, Label, Red } from 'components/Input';
import Text from 'components/Text';

const StyledInput = styled.input`
  border: none;
  ::before {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.white};
    content: 'Browse';
    cursor: pointer;
    font-weight: bold;
    display: inline-flex;
    flex: 0;
    align-items: center;
    justify-content: center;
    max-height: 38px;
    padding: 10px 26px;
    margin-top: 4px;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-user-select: none;
    ${({ theme }) => theme.sizes.smallestP}
  }
  ::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

const InputFile = React.forwardRef(
  ({ description, error, label, required, wrapperStyle, ...rest }, ref) => {
    const hasError = !!error;
    return (
      <InputWrapper style={wrapperStyle}>
        {label && (
          <Label hasDescription={!!description}>
            {label}
            {required && <Red>*</Red>}
          </Label>
        )}
        {description && (
          <Desc font="serif" size="smallP">
            {description}
          </Desc>
        )}
        <StyledInput ref={ref} required={required} {...rest} />
        {hasError && <Text error>{error}</Text>}
      </InputWrapper>
    );
  }
);

InputFile.propTypes = {
  description: PropTypes.any,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  wrapperStyle: PropTypes.object,
};

InputFile.defaultProps = {
  description: null,
  error: '',
  required: false,
  wrapperStyle: {},
};

export default InputFile;
