import endpoints from 'utils/endpoints';

function buildPayload({ condition_ids, location_ids, symptom_ids, ...values }) {
  console.log({ values });
  let newValues = {
    id: values.id,
    // first_name: values.firstName,
    // last_name: values.lastName,
    // email: values.email,
    photo: values.photo || null,
    photo_credit: values.photoCredit || '',
    condition_ids,
    symptom_ids,
    location_ids,
    duration: values.duration || '', //string
    timing_id: values.timing_id || '', //string
    modifying_factors: values.modifyingFactors || '', //string
    pain_quality: values.painQuality || '', //string
    severity: parseInt(values.severity, 10), //int
    case_summary: values.caseSummary || '', //string
  };

  // This is for a pending image
  if (values.status) {
    newValues = {
      ...newValues,
      status: values.status,
      denied_reason: values.denied_reason,
    };
  }

  let formData = new FormData();
  // MTS - Multipart form code can be a little complicated when sending
  // arrays.

  if (!newValues.photo) {
    delete newValues.photo;
  }

  for (const key in newValues) {
    if (key === 'photo') {
      formData.set('photo', newValues[key][0]);
    } else {
      if (newValues[key] instanceof Array) {
        const arr = newValues[key];
        for (const val of arr) {
          formData.append(`${key}[]`, val);
        }
      } else {
        if (key !== 'photo') {
          formData.set(key, newValues[key]);
        }
      }
    }
  }

  const config = {
    data: formData,
    // data: values,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'put',
    // url: endpoints.adminImageDetail,
    url: endpoints.adminImageDetail,
  };
  return config;
}

export default buildPayload;
