import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import PageLoading from 'components/PageLoading';

import Sidebar from './Sidebar';
import Text from 'components/Text';

const StyledAdminLayout = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const Content = styled.div`
  max-width: 850px;
  width: 100%;
`;

const PageTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-size: 22px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 168px 1fr;
  padding: 0px 32px;
`;

function AdminLayout({ children, loading, location, title }) {
  return (
    <Grid>
      <Sidebar pathname={location.pathname} />
      <StyledAdminLayout>
        <Content>
          {title && <PageTitle>{title}</PageTitle>}
          {loading ? <PageLoading /> : children}
        </Content>
      </StyledAdminLayout>
    </Grid>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.any,
};

AdminLayout.defaultProps = {
  children: null,
  loading: false,
  title: null,
};

export default AdminLayout;
