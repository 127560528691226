import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Link from 'components/Link';

import routes from 'utils/routes';

const StyledDisclaimer = styled.p`
  color: ${({ $color, theme }) => theme.colors[$color]};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 12px;
  line-height: 16px;
  ${({ $color, theme }) =>
    $color &&
    `
    a {
      color: ${theme.colors[$color]};
    }
  `};
`;

function Disclaimer({ color }) {
  return (
    <StyledDisclaimer $color={color}>
      Copying, downloading or sharing images from the MM Atlas is a direct
      violation of our{' '}
      <Link
        external
        href={routes.terms}
        style={{ textDecoration: 'underline' }}
      >
        Terms of Use
      </Link>{' '}
      and is considered theft; any such actions will be subject to prosecution.
    </StyledDisclaimer>
  );
}

Disclaimer.propTypes = {
  color: PropTypes.string,
};

Disclaimer.defaultProps = {
  color: 'mediumBrown',
};

export default Disclaimer;
