import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Layout from 'components/Layout';
import Text from 'components/Text';

import { media } from 'styles/theme';
import http from "../../utils/http";
import endpoints from "../../utils/endpoints";
import {sendError} from "../../utils/helpers";
import {useEffect, useState} from "react";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 140px;
  ${media.ns} {
    margin: 50px auto 140px;
  }
`;

const Column = styled.div`
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledResourceItem = styled.div`
  max-width: 1200px;
  padding: 1em;

  ${media.ns} {
    margin: 0 10px 20px 0;
  }
`;

const Authors = styled(Text)`
  font-weight: bold;
  font-size: 12px;
  margin: 0;
`;

const Description = styled(Text)`
  font-size: 14px;
  margin: 0;
`;

const Title = styled.a`
  color: ${({ theme }) => theme.colors.brown};
  font-size: 20px;
  text-decoration: none;
  margin: 0;
`;

function Resources() {
  const [resources, setResources] = useState([])

  useEffect(() => {
    const getResources = () => {
      http
        .get(endpoints.resources)
        .then(({data}) => {
          setResources(data);
        })
        .catch(err => {
          sendError('Error fetching resources', err);
        });
    }
    getResources()
  }, []);

  const groupedResources = resources.reduce((acc, resource) => {
    const year = resource.year_posted || '';
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(resource);
    return acc;
  }, {});

  return (
    <Layout title="Evidence">
      <Flex>
        <Column>
          {Object.keys(groupedResources).sort((a, b) => b - a).map(year => (
            <React.Fragment key={year}>
              <h2>{year}</h2>
              {groupedResources[year].map(resource => (
                <StyledResourceItem key={resource.id}>
                  <Title target="_blank" href={resource.url}>{resource.title}</Title>
                  <Authors>
                    {resource.authors.join(', ')}
                  </Authors>
                  <Description>{resource.description}</Description>
                </StyledResourceItem>
              ))}
            </React.Fragment>
          ))}
        </Column>
      </Flex>
    </Layout>
  );
}

Resources.propTypes = {};

Resources.defaultProps = {};

export default Resources;
