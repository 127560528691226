import { Router as ReachRouter } from 'utils/router';
import styled from 'styled-components/macro';

const Router = styled(ReachRouter)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default Router;
