import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Text from 'components/Text';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  padding: 16px;
`;

function EmptyListMessage({ text, type, ...rest }) {
  return (
    <Container {...rest}>
      <Text center style={{ fontWeight: 500 }}>
        {text || `We are not able to find any ${type} right now.`}
      </Text>
    </Container>
  );
}

EmptyListMessage.propTypes = {
  text: PropTypes.any,
  type: PropTypes.string,
};

EmptyListMessage.defaultProps = {
  text: null,
  type: '',
};

export default EmptyListMessage;
