import * as React from 'react';
import PropTypes from 'prop-types';

import PageLoading from 'components/PageLoading';
import BreadcrumbHeader from '../../components/BreadcrumbHeader';
import UserForm from '../../components/UserForm';

import { addToast } from 'reducers/toasts';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';
import { useDispatch } from 'react-redux';

function User({ userId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(null);
  let subscriptionState = {};

  React.useEffect(() => {
    setLoading(true);
    http(`${endpoints.userDetail}?id=${userId}`)
      .then(({ data }) => {
        setUser(data.data);
      })
      .catch(err => {
        sendError('CMS: Error fetching user', err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  function setSubscriptionType() {
    dispatch(
      addToast({
        type: 'error',
        message: 'We cannot change subscription rates at this time',
      })
    );
  }

  if (loading || !user) {
    return <PageLoading />;
  }

  if (user.subscription_plan) {
    subscriptionState.subscriptionType = user.subscription_plan;
    subscriptionState.setSubscriptionType = setSubscriptionType;
  }

  return (
    <>
      <BreadcrumbHeader
        actionButtons={null}
        backTo={routes.allUsers}
        loading={loading}
        title="User Details"
      />
      {!loading && user && (
        <UserForm
          user={user}
          subscriptionStatic
          subscriptionState={subscriptionState}
        />
      )}
    </>
  );
}

User.propTypes = {
  userId: PropTypes.string.isRequired,
};

User.defaultProps = {};

export default User;
