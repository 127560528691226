import * as React from 'react';
// import PropTypes from 'prop-types';

import AdminRoute from 'components/AdminRoute';
import Layout from 'components/Layout';
import Router from 'components/Router';
import NotFound from 'components/NotFound';

// pages
import AllImages from './pages/all-images';
import AllUsers from './pages/all-users';
import DeniedImages from './pages/denied-images';
import DeniedUsers from './pages/denied-users';
import ImageDetail from './pages/image';
import Invite from './pages/invite';
import InvitedUsers from './pages/invited-users';
import PendingImages from './pages/pending-images';
import PendingUsers from './pages/pending-users';
import PendingUser from './pages/pending-user';
import User from './pages/user';

function Admin(props) {
  return (
    <Layout header="admin">
      <Router>
        <AdminRoute as={PendingUsers} path="/" />
        <AdminRoute as={PendingImages} path="/pending-images" />
        <AdminRoute as={AllUsers} path="/all-users" />
        <AdminRoute as={AllImages} path="/all-images" />
        <AdminRoute as={ImageDetail} path="/image/:imageId" />
        <AdminRoute as={DeniedUsers} path="/denied-users" />
        <AdminRoute as={PendingUser} path="/pending-user/:userId" />
        <AdminRoute as={DeniedImages} path="/denied-images" />
        <AdminRoute as={InvitedUsers} path="/invited-users" />
        <AdminRoute as={Invite} path="/invite" />
        <AdminRoute as={User} path="/user/:userId" />
        <NotFound default noHeader={true} />
      </Router>
    </Layout>
  );
}

Admin.propTypes = {};

Admin.defaultProps = {};

export default Admin;
