import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Layout from 'components/Layout';
import Text from 'components/Text';

const Container = styled.div`
  flex: 1;
`;

function NotFound({ noHeader }) {
  const body = (
    <Container>
      <Text center>Whoops, you've landed on a page that doesn't exist 🤦‍♂️</Text>
    </Container>
  );

  if (noHeader) {
    return body;
  }

  return <Layout>{body}</Layout>;
}

NotFound.propTypes = {
  noHeader: PropTypes.bool,
};

NotFound.defaultProps = {
  noHeader: false,
};

export default NotFound;
