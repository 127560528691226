import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import BackLink from 'components/BackLink';
import CaseSummary from './components/CaseSummary';
import DetailsSection from './components/DetailsSection';
import ExpandImage from './components/ExpandImage';
import Footer from 'components/Footer';
import Header from 'components/Header';
import HelpUI from 'components/HelpUI';
import ImageModal from './components/ImageModal';
import PhotoCredit from './components/PhotoCredit';
import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';
import RelatedImages from './components/RelatedImages';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { media } from 'styles/theme';
import { sendError } from 'utils/helpers';
import useHelp from 'hooks/useHelp';
import useHelpItems from './useHelpItems';

const StyledBackLink = styled(BackLink)`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-size: 12px;
`;

const StyledLayout = styled.div`
  flex: 1;
`;

const Container = styled.div`
  flex: 1;
  padding: 22px 15px;
  max-width: 1036px;
  margin: 0 auto;
  ${media.ns} {
    padding: 30px 80px 50px;
  }
`;

const Grid = styled.div`
  flex: 1;
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'img'
    'detail'
    'des'
    'phc';
  margin-top: 22px;
  ${media.ns} {
    margin-top: 30px;
    grid-gap: 0 28px;
    grid-template-areas:
      'img img img img img detail detail detail'
      'des des des des des detail detail detail'
      'phc phc phc phc phc detail detail detail';
  }
`;

function SearchResultsDetail({ id, location }) {
  const [data, setData] = React.useState(null);
  const items = useHelpItems({ photoCredit: data?.photo_credit });
  const { helpItems, setIsOpen } = useHelp(items);
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    http(`${endpoints.image}?id=${id}`)
      .then(res => {
        setData(res.data.data);
      })
      .catch(err => {
        sendError('Error fetching image detail', err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    return () => setIsOpen(false);
    // eslint-disable-next-line
  }, []);

  if (loading || !data) {
    return (
      <Layout>
        <PageLoading />
      </Layout>
    );
  }

  return (
    <>
      <StyledLayout>
        <Header />
        <Container>
          <ImageModal
            alt={data.conditions.join(', ')}
            showModal={showModal}
            src={data.photo_url}
            toggleModal={() => setShowModal(!showModal)}
          />
          <StyledBackLink to={`/search-results${location.search}`}>
            Back to Results
          </StyledBackLink>
          <Grid>
            <ExpandImage
              alt={data.conditions.join(', ')}
              src={data.photo_url}
              toggleModal={() => setShowModal(!showModal)}
            />
            <DetailsSection {...data} />
            <CaseSummary>{data.case_summary}</CaseSummary>
            <PhotoCredit credit={data.photo_credit} />
          </Grid>
          <RelatedImages currentImage={id} location={location} />
        </Container>
        <HelpUI items={helpItems} />
      </StyledLayout>
      <Footer />
    </>
  );
}

SearchResultsDetail.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

SearchResultsDetail.defaultProps = {};

export default SearchResultsDetail;
