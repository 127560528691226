import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loading from 'components/Loading';
import SearchResultItem from 'components/SearchResultItem';
import Text from 'components/Text';

import { media } from 'styles/theme';
import useNewImages from './useNewImages';

const StyledSearchResultItem = styled(SearchResultItem)`
  width: 100%;
  ${media.ns} {
    margin-right: 16px;
    width: initial;
  }
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  ${media.ns} {
    flex-direction: row;
  }
`;

const Title = styled(Text)`
  font-size: 24px;
  margin: 0;
  margin-bottom: 36px;
`;

const StyledWhatOthersAreViewing = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 46px;
`;

function WhatOthersAreViewing() {
  const { results, loading } = useNewImages();

  return (
    <StyledWhatOthersAreViewing>
      <Title as="h2" center>
        What Others Are Viewing
      </Title>
      <Results>
        {loading && <Loading />}
        {results.map(el => (
          <StyledSearchResultItem
            conditions={el.conditions}
            height={244}
            id={el.id}
            key={el.id}
            symptoms={el.symptoms}
            photo_url={el.photo_url}
          />
        ))}
      </Results>
    </StyledWhatOthersAreViewing>
  );
}

WhatOthersAreViewing.propTypes = {};

WhatOthersAreViewing.defaultProps = {};

export default WhatOthersAreViewing;
