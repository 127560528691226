import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { removeToast } from 'reducers/toasts';
import { animated } from 'react-spring';

import Check from './Check';
import Error from './Error';
import Text from 'components/Text';

import { media } from 'styles/theme';

const StyledToastItem = styled(animated.div)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.darkNavy};
  border-radius: 25px;
  display: flex;
  min-height: 51px;
  justify-content: space-between;
  left: 50%;
  max-width: 597px;
  margin: 5px auto;
  padding: 6px 12px;
  position: fixed;
  transform: translate(-50%, 0);
  top: 50px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  width: 95vw;
  z-index: 2;
  ${media.ns} {
    padding: 0 18px;
    width: 100%;
  }
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
`;

const MessageText = styled(Text)`
  margin-bottom: 0;
  margin-top: 0;
  ${media.sm} {
    text-align: center;
  }
`;

function ToastItem({ duration, id, message, springProps, type, ...rest }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => {
      remove();
    }, duration);
    // eslint-disable-next-line
  }, []);

  function remove() {
    dispatch(removeToast(id));
  }

  return (
    <StyledToastItem
      style={{
        opacity: springProps.opacity,
        transform: springProps.transform,
      }}
      {...rest}
    >
      {type === 'default' && <Check height={17} style={{ marginRight: 14 }} />}
      {type === 'error' && (
        <Error
          height={12}
          style={{ marginRight: 14, transform: 'rotate(45deg)' }}
        />
      )}
      <MessageText
        color="white"
        style={{ flex: 1, fontWeight: 500, fontSize: 14 }}
      >
        {message}
      </MessageText>
      <CloseButton onClick={remove}>Close</CloseButton>
    </StyledToastItem>
  );
}

ToastItem.propTypes = {
  duration: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  message: PropTypes.any,
  springProps: PropTypes.any,
  type: PropTypes.oneOf(['default', 'error']),
};

ToastItem.defaultProps = {
  message: null,
  type: 'default',
};

export default ToastItem;
