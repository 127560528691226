import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Controller } from 'react-hook-form';
import { InputWrapper, Label, Red } from 'components/Input';
import ReactDatePicker from 'react-datepicker';
import Text from 'components/Text';

import 'react-datepicker/dist/react-datepicker.css';

const StyledDatePicker = styled(ReactDatePicker)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid
    ${({ error, theme }) => (error ? theme.colors.red : theme.colors.lightTan)};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.darkBrown};
  display: flex;
  font-weight: 500;
  outline: 0;
  padding: 15px 16px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  :focus {
    border-color: ${({ error, theme }) =>
      error ? theme.colors.red : theme.colors.brown2};
  }
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`;

function DatePicker({ control, error, label, name, required }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <InputWrapper>
          <Label>
            {label}
            {required && <Red>*</Red>}
          </Label>
          <StyledDatePicker
            onChange={date => field.onChange(date)}
            required={required}
            selected={field.value}
          />
          {error && (
            <Text error size="smallP" style={{ marginTop: 4 }}>
              {error}
            </Text>
          )}
        </InputWrapper>
      )}
    />
  );
}

DatePicker.propTypes = {
  control: PropTypes.any.isRequired,
  error: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

DatePicker.defaultProps = {
  error: null,
  label: null,
  required: false,
};

export default DatePicker;
