import * as React from 'react';
import PropTypes from 'prop-types';
import { Buffer } from 'buffer';

import Button from 'components/Button';

import http from 'utils/http';
import { sendError } from 'utils/helpers';

function DownloadImageButton({ fileName, photoUrl }) {
  const [loading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState('');

  React.useEffect(() => {
    http
      .get(photoUrl, {
        baseURL: '',
        responseType: 'arraybuffer',
        headers: {
          'mmatlas-API-Version': 1,
        },
      })
      .then(res => {
        let str = Buffer.from(res.data, 'binary').toString('base64');
        str = `data:${res.headers['content-type']};base64,${str}`;
        setUrl(str);
      })
      .catch(err => sendError('Error fetching download image', err))
      .finally(setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Button
      as="a"
      download={fileName}
      loading={loading}
      href={url}
      small
      style={{ display: 'inline-flex', margin: '10px 0' }}
    >
      Download Image
    </Button>
  );
}

DownloadImageButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
};

DownloadImageButton.defaultProps = {};

export default DownloadImageButton;
