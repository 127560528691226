import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { media } from 'styles/theme';

const Text = styled.p`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 12px;
  line-height: 16px;
  ${media.sm} {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  grid-area: phc;
`;

const InnerWrapper = styled.div`
  display: inline-block;
`;

function PhotoCredit({ credit }) {
  if (!credit) {
    return null;
  }
  return (
    <Wrapper>
      <InnerWrapper data-tour="photo-credit">
        <Text>
          Photo Credit
          <br />
          {credit}
        </Text>
      </InnerWrapper>
    </Wrapper>
  );
}

PhotoCredit.propTypes = {
  credit: PropTypes.string,
};

PhotoCredit.defaultProps = {
  credit: null,
};

export default PhotoCredit;
