import * as React from 'react';
// import PropTypes from 'prop-types';

function Close(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.091 17.5582L18.279 16.3703L6.90869 5L5.72075 6.18794L17.091 17.5582Z"
        fill="white"
      />
      <path
        d="M5.72059 16.3703L6.90853 17.5582L18.2788 6.18794L17.0909 5L5.72059 16.3703Z"
        fill="white"
      />
    </svg>
  );
}

Close.propTypes = {};

Close.defaultProps = {};

export default Close;
