import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dialog from './Dialog';
import Inner from './Inner';
import Text from 'components/Text';

import { animated, useSpring } from '@react-spring/web';

const SIZE = 50;

const StyledHelpUI = styled(animated.button)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mediumBrown};
  border: none;
  border-radius: ${SIZE / 2}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  height: ${SIZE}px;
  padding: 13px;

  position: fixed;
  bottom: 20px;
  right: 18px;
  transform: scale(1);
  transition: transform 100ms ease-out;
  :active {
    transform: scale(0.92);
  }
`;

const NeedHelpText = styled(Text)`
  display: ${({ $expanded }) => ($expanded ? 'flex' : 'none')};
  flex: 1;
  font-weight: 600;
  left: 0;
`;

function HelpUI({ items }) {
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [styleProps, setStyleProps] = React.useState({
    justifyContent: 'right',
  });

  const width = useSpring({
    width: expanded ? 136 : SIZE,
    onRest: () => setStyleProps({ justifyContent: 'center' }),
  });

  function handleClick() {
    setExpanded(false);
    setOpen(!open);
  }

  return (
    <>
      <Dialog closeHelp={() => setOpen(false)} items={items} open={open} />
      <StyledHelpUI
        $expanded={expanded}
        onClick={handleClick}
        style={{ width: width.width, ...styleProps }}
      >
        <NeedHelpText color="white" size="smallestP" $expanded={expanded}>
          Need Help?
        </NeedHelpText>
        <Inner open={open} />
      </StyledHelpUI>
    </>
  );
}

HelpUI.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any.isRequired,
      selector: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ),
};

HelpUI.defaultProps = {};

export default HelpUI;
