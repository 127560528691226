import * as React from 'react';
import PropTypes from 'prop-types';

import { Item as StyledItem } from './TooltipStyles';

function Item({ name }) {
  return <StyledItem to={`search-results?term=${name}`}>{name}</StyledItem>;
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
};

Item.defaultProps = {};

export default Item;
