import React from 'react';

function DownArrow(props) {
  return (
    <svg
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.20718L1.41421 0.792969L7.20711 6.58586L13 0.792969L14.4142 2.20718L7.20711 9.41429L0 2.20718Z"
        fill="#301C0E"
      />
    </svg>
  );
}

DownArrow.propTypes = {};

DownArrow.defaultProps = {};

export default DownArrow;
