import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Text from 'components/Text';

import { media } from 'styles/theme';

const StyledBanner = styled.div`
  align-items: center;
  background: linear-gradient(92.52deg, #c0895f 25.54%, #834f2a 81.17%);
  display: flex;
  justify-content: center;
  padding: 10px 35px;
  width: 100%;
  ${media.ns} {
    padding: 26px 20px;
  }
`;

const Title = styled(Text)`
  max-width: 560px;
  text-align: center;
  ${media.sm} {
    font-size: 26px;
    line-height: 28px;
  }
`;

function Banner({ children }) {
  return (
    <StyledBanner>
      <Title as="h1" color="white" size="f1">
        {children}
      </Title>
    </StyledBanner>
  );
}

Banner.propTypes = {
  children: PropTypes.any.isRequired,
};

Banner.defaultProps = {};

export default Banner;
