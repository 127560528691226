// import * as React from 'react';
// import PropTypes from 'prop-types';

// import MobileLink from './MobileLink';

// import routes from 'utils/routes';

function AdminLinks() {
  return null;
}

AdminLinks.propTypes = {};

AdminLinks.defaultProps = {};

export default AdminLinks;
