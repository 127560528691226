import * as React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'components/DatePicker';
import Input from 'components/Input';

function StudentInputs({ control, errors, register, required, unregister }) {
  React.useEffect(() => {
    return () => {
      unregister('school');
      unregister('program');
      unregister('gradDate');
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Input
        error={errors?.school?.message}
        label="School where you are currently enrolled"
        required
        {...register('school', { required })}
      />
      <Input
        error={errors?.program?.message}
        label="Program/Specialty"
        required
        {...register('program', { required })}
      />
      <DatePicker
        control={control}
        error={errors?.gradDate?.message}
        label={'Intended Graduation/Licensure Date'}
        name="gradDate"
        required
      />
    </>
  );
}

StudentInputs.propTypes = {
  control: PropTypes.any.isRequired,
  errors: PropTypes.object,
  register: PropTypes.any.isRequired,
  required: PropTypes.any,
  unregister: PropTypes.func.isRequired,
};

StudentInputs.defaultProps = {};

export default StudentInputs;
