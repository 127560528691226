import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Text from 'components/Text';

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  margin: 4px 0;
  padding: 23px 21px;
  width: 100%;
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 0;
`;

function Card({ children, title, ...rest }) {
  return (
    <StyledCard {...rest}>
      {title && <Title>{title}</Title>}
      {children}
    </StyledCard>
  );
}

Card.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
};

Card.defaultProps = {
  children: null,
  title: null,
};

export default Card;
