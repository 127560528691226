import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Text from 'components/Text';

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid
    ${({ error, theme }) => (error ? theme.colors.red : theme.colors.lightTan)};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.darkBrown};
  display: flex;
  font-weight: 500;
  outline: 0;
  padding: 15px 16px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  :focus {
    border-color: ${({ error, theme }) =>
      error ? theme.colors.red : theme.colors.brown2};
  }
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  ${({ theme }) => theme.sizes.p}
`;

export const Desc = styled(Text)`
  margin-top: 0;
  margin-bottom: 7px;
`;

export const Label = styled.label`
  font-weight: 600;
  margin-bottom: ${({ hasDescription }) => (hasDescription ? 2 : 0)}px;
  ${({ theme }) => theme.sizes.smallP}
`;

export const Red = styled.span`
  color: ${({ theme }) => theme.colors.red};
`;

const Input = React.forwardRef(
  (
    {
      description,
      error,
      hideRequired,
      label,
      required,
      wrapperStyle,
      ...rest
    },
    ref
  ) => {
    const hasError = !!error;
    return (
      <InputWrapper style={wrapperStyle}>
        {label && (
          <Label hasDescription={!!description}>
            {label}
            {required && !hideRequired && <Red>*</Red>}
          </Label>
        )}
        {description && (
          <Desc font="sansSerif" size="smallP">
            {description}
          </Desc>
        )}
        <StyledInput error={hasError} ref={ref} required={required} {...rest} />
        {hasError && (
          <Text error size="smallP" style={{ marginTop: 4 }}>
            {error}
          </Text>
        )}
      </InputWrapper>
    );
  }
);

Input.propTypes = {
  description: PropTypes.string,
  error: PropTypes.string,
  hideRequired: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  wrapperStyle: PropTypes.object,
};

Input.defaultProps = {
  description: null,
  error: '',
  hideRequired: false,
  label: '',
  required: false,
  wrapperStyle: {},
};

export default Input;
