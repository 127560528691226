import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ImageLoader from 'components/ImageLoader';
import { Link as ReachLink } from 'utils/router';
import Text from 'components/Text';

import { media } from 'styles/theme';

const StyledSearchResultItem = styled(ReachLink)`
  cursor: pointer;
  margin: 10px 0;
  text-decoration: none;

  ${media.ns} {
    margin: 0 10px 20px 0;
  }
`;

const Condition = styled(Text)`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-size: 14px;
  font-weight: 700;
  margin-top: 6px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px;`}
`;

const Symptoms = styled(Text)`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-size: 12px;
  font-weight: 500;
  margin-top: -4px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledImageLoader = styled(ImageLoader)`
  object-fit: cover;
  ${media.sm} {
    max-height: 280px;
    min-height: 280px;
    width: 100%;
  }
  ${media.ns} {
    min-width: 200px;
    ${({ $height }) => `min-height: ${$height}px;`}
    ${({ $height }) => `max-height: ${$height}px;`}
  }
`;

function SearchResultItem({
  conditions,
  height,
  id,
  photo_url,
  symptoms,
  ...rest
}) {
  const [maxWidth, setMaxWidth] = React.useState(null);
  const condition = conditions[0];

  function onLoaded({ width }) {
    setMaxWidth(width);
  }

  function noRightClick(e) {
    e.preventDefault();
  }

  function getLink() {
    const unknownSearch = conditions.concat(symptoms).slice(0, 1);
    const base = `/search-results/${id}`;
    const search = window.location.search || `?term=${unknownSearch}`;
    return `${base}/${search}`;
  }

  return (
    <StyledSearchResultItem
      onContextMenu={noRightClick}
      to={getLink()}
      {...rest}
    >
      <StyledImageLoader
        alt={condition}
        onLoaded={onLoaded}
        src={photo_url}
        $height={height}
        watermark
      />
      <Condition $maxWidth={maxWidth}>{conditions.join(', ')}</Condition>
      <Symptoms>{symptoms.join(', ')}</Symptoms>
    </StyledSearchResultItem>
  );
}

SearchResultItem.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.number,
  id: PropTypes.string.isRequired,
  photo_url: PropTypes.string.isRequired,
  symptoms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SearchResultItem.defaultProps = {
  height: 274,
};

export default SearchResultItem;
