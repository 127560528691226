import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Link from 'components/Link';

const StyledViewLink = styled(Link)`
  font-size: 12px;
  font-weight: 800 !important;
`;

function ViewLink({ to }) {
  return <StyledViewLink to={to}>VIEW</StyledViewLink>;
}

ViewLink.propTypes = {
  to: PropTypes.string.isRequired,
};

ViewLink.defaultProps = {};

export default ViewLink;
