import * as React from 'react';
// import PropTypes from 'prop-types';
import routes from 'utils/routes';
import { navigate } from 'utils/router';

import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { logout } from 'reducers/auth';
import { sendError } from 'utils/helpers';
import { useDispatch } from 'react-redux';

function Logout() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    http
      .delete(endpoints.logout)
      .then(res => {
        // don't do anything
      })
      .catch(err => {
        sendError('Unable to log out user', err);
      })
      .finally(() => {
        dispatch(logout());
        navigate(routes.login);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <PageLoading />
    </Layout>
  );
}

Logout.propTypes = {};

Logout.defaultProps = {};

export default Logout;
