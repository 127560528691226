import styled from 'styled-components/macro';

import Button from 'components/Button';
import Text from 'components/Text';
import { StyledInput } from 'components/Input';

export const ButtonText = styled.span`
  font-size: ${({ small }) => (small ? 14 : 16)}px;
`;

export const Error = styled(Text)`
  color: ${({ theme }) => theme.colors.red} !important;
  font-size: 14px;
  font-weight: 600;
`;

export const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 412px ${({ small }) => (small ? 108 : 133)}px;
`;

export const StyledSearch = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  ${({ $resultsScreen }) => $resultsScreen && 'height: 46px;'}
`;

export const SearchInput = styled(StyledInput)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: none;
  height: ${({ small }) => (small ? 46 : 60)}px;
  margin-top: 8px;
  ${({ hasMatches, theme }) =>
    hasMatches &&
    `
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-right: 2px solid ${theme.colors.lightTan};
  `}
  :focus {
    border-color: ${({ theme }) => theme.colors.lightTan};
  }
`;
