import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Input from 'components/Input';
import ProviderInputs from './ProviderInputs';
import StudentInputs from './StudentInputs';
import SubscriptionBox from './SubscriptionBox';
import Text from 'components/Text';

const StyledUserForm = styled.div`
  display: grid;
  grid-template-columns: 383px 291px;
  grid-gap: 75px;
  padding: 12px 0px;
  max-width: 800px;
  margin: 0 auto;
`;

const FormWrapper = styled.div`
  max-width: 383px;
  width: 100%;
  padding-bottom: 40px;
`;

const Spacing = styled.div`
  height: 30px;
  width: 1px;
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

function UserForm({ subscriptionStatic, subscriptionState, user }) {
  const { email, first_name, last_name, occupation, referred } = user;
  const subscriptionStatus = user?.subscription_status;

  // MTS - This is required becuase we handle different formats of the the user
  // object between pending users and approved users
  function formatUser() {
    let newUser = { ...user };
    if (occupation === 'provider' && !user.provider) {
      newUser.provider = {
        certification_date: user?.certification_date,
        country_of_operation: user?.country_of_operation,
        credentials: user?.credentials,
        id_number: user?.id_number,
        identifier_type: user?.identifier_type,
        npi_number: user?.npi_number,
        region: user?.region,
      };
    }

    if (occupation === 'student' && !user.student) {
      newUser.student = {
        graduation_date: user?.graduation_date,
        school: user?.school,
        program: user?.program,
      };
    }
    return newUser;
  }

  const formattedUser = formatUser();
  return (
    <StyledUserForm>
      <FormWrapper>
        <Title>User Details</Title>
        <Input disabled label="Email" value={email} />
        <Input disabled label="First Name" value={first_name} />
        <Input disabled label="Last Name" value={last_name} />
        <Input
          disabled
          label="Occupation"
          value={occupation || ''}
          style={{ textTransform: 'capitalize' }}
        />
        <Spacing />

        {occupation === 'student' && <StudentInputs user={formattedUser} />}
        {occupation === 'provider' && <ProviderInputs user={formattedUser} />}
        <Input disabled label="Organization Code" value={referred || ''} />
      </FormWrapper>
      <div>
        <Title>Subscription Rate</Title>
        {subscriptionState && (
          <SubscriptionBox
            subscriptionStatic={subscriptionStatic}
            subscriptionState={subscriptionState}
            subscriptionStatus={subscriptionStatus}
          />
        )}
      </div>
    </StyledUserForm>
  );
}

UserForm.propTypes = {
  subscriptionStatic: PropTypes.bool,
  subscriptionState: PropTypes.shape({
    subscriptionType: PropTypes.string,
    setSubscriptionType: PropTypes.func,
  }),
  user: PropTypes.shape({
    certification_date: PropTypes.string,
    country_of_operation: PropTypes.string,
    credentials: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string.isRequired,
    graduation_date: PropTypes.string,
    id_number: PropTypes.string,
    identifier_type: PropTypes.string,
    last_name: PropTypes.string.isRequired,
    npi_number: PropTypes.string,
    occupation: PropTypes.string.isRequired,
    referred: PropTypes.string,
    region: PropTypes.string,
    subscription_status: PropTypes.string,
    program: PropTypes.string,
    provider: PropTypes.object,
    student: PropTypes.object,
    school: PropTypes.string,
  }).isRequired,
};

UserForm.defaultProps = {
  subscriptionStatic: false,
  subscriptionState: null,
  user: {
    email: '',
  },
};

export default UserForm;
