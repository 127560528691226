import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Loading from 'components/Loading';

import { media } from 'styles/theme';

const StyledResultsCount = styled.span`
  grid-area: count;
  font-size: 12px;
  font-style: italic;
  margin: 14px 15px 9px;
  white-space: nowrap;
  ${media.ns} {
    margin: 0 0 0 20px;
  }
`;

function ResultsCount({ loading, totalResults, ...rest }) {
  return (
    <StyledResultsCount {...rest}>
      {loading && <Loading />}
      {!loading &&
        `${totalResults || '0'} result${totalResults !== 1 ? 's' : ''}`}
    </StyledResultsCount>
  );
}

ResultsCount.propTypes = {
  loading: PropTypes.bool.isRequired,
  results: PropTypes.array,
  totalResults: PropTypes.number.isRequired,
};

ResultsCount.defaultProps = {
  results: [],
};

export default ResultsCount;
