import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Arrow from '../../../Arrow';

const StyledArrow = styled(Arrow)`
  margin-left: 6px;
  transform: translateY(1px) ${({ $open }) => !$open && `rotate(180deg)`};
`;

const Count = styled.span`
  font-style: italic;
  font-weight: 400;
  margin-left: 3px;
`;

const StyledFilterCategoryList = styled.div`
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightTan};
  display: flex;
  flex-wrap: nowrap;
  height: 46px;
  padding-left: 15px;
  padding-right: 3px;
  position: relative;
  overflow-x: auto;
`;

const Title = styled.p`
  align-items: center;
  color: ${({ theme }) => theme.colors.darkBrown};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  flex: 1;
  height: 100%;
  margin: 0 20px 0 0;
  padding: 22px 0;
  text-transform: capitalize;
  white-space: nowrap;
`;

const options = [
  {
    title: 'Conditions',
    key: 'conditions',
  },
  {
    title: 'Associated Symptoms',
    key: 'symptoms',
  },
  {
    title: 'Location',
    key: 'locations',
  },
  {
    title: 'Pain Quality',
    key: 'painQuality',
  },
  {
    title: 'Duration of Condition',
    key: 'duration',
  },
  {
    title: 'Timing',
    key: 'timings',
  },
];

function FilterCategoryList({
  categoryOpen,
  handleClick,
  filters,
  selectedFilters,
  ...rest
}) {
  const [maxWidth, setMaxWidth] = React.useState(0);

  React.useEffect(() => {
    setMaxWidth(window?.screen?.width);
  }, []);

  function getCount(key) {
    const count = selectedFilters.reduce((acc, el) => {
      if (filters[key].find(filter => filter.id === el.id)) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return count;
  }

  return (
    <StyledFilterCategoryList style={{ maxWidth }}>
      {options.map(el => (
        <Title key={el.key} onClick={() => handleClick(el.key)}>
          {el.title}
          <Count>{getCount(el.key) > 0 && ` (${getCount(el.key)})`}</Count>
          <StyledArrow height={6} $open={categoryOpen === el.key} />
        </Title>
      ))}
    </StyledFilterCategoryList>
  );
}

FilterCategoryList.propTypes = {
  categoryOpen: PropTypes.string,
  filters: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
};

FilterCategoryList.defaultProps = {
  categoryOpen: '',
  selectedFilters: [],
};

export default FilterCategoryList;
