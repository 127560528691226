import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'utils/router';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { authSuccess } from 'reducers/auth';

import Button from 'components/Button';
import Input from 'components/Input';
import Layout from 'components/Layout';
import Link from 'components/Link';
import PageLoading from 'components/PageLoading';
import Text from 'components/Text';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { passwordValidation } from 'utils/validations';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';

const Form = styled.form`
  align-self: center;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 366px;
  width: 100%;
`;

const Title = styled(Text)`
  margin: 20px 0 0;
`;

function InvitationsIndex({ waitlist_token }) {
  const [tokenValid, setTokenValid] = React.useState(null);
  const dispatch = useDispatch();
  const [name, setName] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm();

  const password = React.useRef({});
  const passwordConfirm = React.useRef({});
  password.current = watch('password', '');
  passwordConfirm.current = watch('passwordConfirm', '');

  React.useEffect(() => {
    if (!waitlist_token) {
      navigate('/');
    } else {
      checkToken();
    }
    // eslint-disable-next-line
  }, []);

  const checkToken = React.useCallback(() => {
    http(`${endpoints.checkInviteToken}/${waitlist_token}`)
      .then(res => {
        setTokenValid(true);
        setName(res.data.first_name);
      })
      .catch(err => {
        setTokenValid(false);
        sendError('Expired invitation', err, { waitlist_token, name });
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [waitlist_token]);

  function onSubmit(data) {
    setLoading(true);
    setError(false);
    http
      .post(endpoints.account, {
        token: waitlist_token,
        password: data.password,
        password_confirmation: data.passwordConfirm,
      })
      .then(({ data }) => {
        dispatch(authSuccess(data));
        if (data?.data?.subscription_type === 'free') {
          return navigate(routes.home);
        }
        navigate(routes.subscription);
      })
      .catch(err => {
        sendError('Error setting user password onboarding', err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }

  const passwordsMatch = password.current === passwordConfirm.current;

  // loading
  if (tokenValid === null) {
    return (
      <Layout>
        <PageLoading />
      </Layout>
    );
  }

  if (tokenValid === false) {
    return (
      <Layout>
        <Text center size="f3" style={{ fontWeight: 500 }}>
          This invitation has already been used or is expired.
        </Text>
        <Text center size="f3" style={{ fontWeight: 500 }}>
          Log in <Link to={routes.login}>HERE</Link> using the password you
          previously chose.
        </Text>
      </Layout>
    );
  }

  return (
    <Layout>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title as="h1" center color="darkBrown" size="h1">
          Welcome{name && `, ${name.split(' ')[0]}.`}
        </Title>
        <Text
          as="p"
          center
          color="darkBrown"
          font="serif"
          size="p"
          style={{ marginTop: 10, marginBottom: 32 }}
        >
          Let's finish your account setup.
        </Text>
        <Input
          error={errors?.password?.message}
          hideRequired
          label="Create Password"
          name="password"
          required
          type="password"
          {...register('password', passwordValidation)}
          style={{ marginBottom: 10 }}
        />
        <Input
          error={errors?.passwordConfirm?.message}
          hideRequired
          label="Confirm Password"
          name="password"
          required
          type="password"
          {...register('passwordConfirm', passwordValidation)}
        />
        {!passwordsMatch && <Text error>Passwords must match</Text>}
        {error && (
          <Text center error>
            An error occurred while trying to create your account
          </Text>
        )}
        <Button
          disabled={!passwordsMatch || loading}
          loading={loading}
          style={{ marginTop: 20 }}
          type="submit"
        >
          Next
        </Button>
      </Form>
    </Layout>
  );
}

InvitationsIndex.propTypes = {
  waitlist_token: PropTypes.string,
};

InvitationsIndex.defaultProps = {};

export default InvitationsIndex;
