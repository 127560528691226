import * as React from 'react';
// import PropTypes from 'prop-types';

function Close(props) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.51416 2.51416L6 5.02832L3.48584 2.51416L2.8573 3.1427L5.37146 5.65686L2.8573 8.17101L3.48584 8.79955L6 6.2854L8.51416 8.79955L9.1427 8.17101L6.62854 5.65686L9.1427 3.1427L8.51416 2.51416Z"
        fill="#D8CEC4"
      />
    </svg>
  );
}

Close.propTypes = {};

Close.defaultProps = {};

export default Close;
