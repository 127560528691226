import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Loading from 'components/Loading';

const StyledButton = styled(Button)`
  font-size: 12px;
  margin-left: 8px;
  max-height: 40px;
  min-width: 88px;
  display: inline-flex;
`;

function ActionButtons({ hasChanges, loading, onSaveClick, onCancelClick }) {
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <StyledButton disabled={loading} onClick={onSaveClick}>
        Save
      </StyledButton>
      <StyledButton disabled={loading} secondary onClick={onCancelClick}>
        Cancel
      </StyledButton>
    </>
  );
}

ActionButtons.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
};

ActionButtons.defaultProps = {};

export default ActionButtons;
