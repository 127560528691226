import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Router } from 'utils/router';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import Text from 'components/Text';

// Imported in the order of the image upload flow, not alphabetically
import ImageUploadIndex from './pages/ImageUploadIndex';
import ImageUploadContact from './pages/ImageUploadContact';
import ImageUploadImage from './pages/ImageUploadImage';
import ImageUploadImageDetails from './pages/ImageUploadImageDetails';
import ImageUploadPatientSummary from './pages/ImageUploadPatientSummary';
import ConsentCredentials from './pages/ConsentCredentials';
import ConsentMedia from './pages/ConsentMedia';
import ConsentSubmit from './pages/ConsentSubmit';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';
import useUpload from './useUpload';

const StyledRouter = styled(Router)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0px auto 80px;
  max-width: 600px;
  width: 100%;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

function ImageUpload() {
  const [error, setError] = React.useState(null);
  const [options, setOptions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const {
    error: submitError,
    loading: submitLoading,
    uploadData,
    setSuccess,
    setUploadData,
    submitForm,
    success,
  } = useUpload();

  React.useEffect(() => {
    setLoading(true);
    http(endpoints.getImageOptions)
      .then(({ data }) => setOptions(data))
      .catch(err => {
        const msg =
          'An error has occured and we are not able to accept images at this time.';
        setError(msg);
        sendError(msg, err);
      })
      .finally(() => setLoading(false));
  }, []);

  // Don't load any of the routes until the options are fetched
  if (loading || !options) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  if (error) {
    return (
      <LoadingWrapper>
        <Text error>{error}</Text>
      </LoadingWrapper>
    );
  }

  const pageProps = { uploadData, setUploadData };
  return (
    <Layout>
      <StyledRouter>
        <ImageUploadIndex path="/" />
        <ImageUploadContact path="/contact" {...pageProps} />
        <ImageUploadImage path="/image" {...pageProps} />
        <ImageUploadImageDetails
          path="/image-details"
          conditions={options.conditions}
          duration={options.duration}
          locations={options.locations}
          timings={options.timings}
          {...pageProps}
        />
        <ImageUploadPatientSummary
          painQuality={options.pain_quality}
          path="/patient-summary"
          symptoms={options.symptoms}
          {...pageProps}
        />
        <ConsentCredentials path="/consent-credentials" {...pageProps} />
        <ConsentMedia path="/consent-media" {...pageProps} />
        <ConsentSubmit
          error={submitError}
          loading={submitLoading}
          path="/consent-submit"
          setSuccess={setSuccess}
          submitForm={submitForm}
          success={success}
          {...pageProps}
        />
        <NotFound default noHeader={true} />
      </StyledRouter>
    </Layout>
  );
}

ImageUpload.propTypes = {};

ImageUpload.defaultProps = {};

export default ImageUpload;
