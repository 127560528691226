import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Donations from './Donations';
import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';
import RadioInput from 'components/RadioInput';
import SubscriptionText from './SubscriptionText';
import Text from 'components/Text';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';
import { media } from 'styles/theme';
import { sendError } from 'utils/helpers';
import { updatePolicies } from 'reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import useDonation from './useDonation';
import useSubscriptions from './useSubscriptions';
import useToast from 'hooks/useToast';

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    'title'
    'form'
    'text';

  ${media.ns} {
    grid-gap: 0 73px;
    grid-template-areas:
      'title title title title title title title'
      'text  text  text  text  form  form  form';
    margin: 0 auto 60px;
    max-width: 768px;
  }
`;

const PageTitle = styled(Text)`
  margin-top: 12px;
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.mediumBrown};
  grid-area: title;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
`;

const Total = styled(Text)`
  margin-bottom: 22px;
  margin-top: 0;
`;

const RightSide = styled.form`
  grid-area: form;
`;

function Subscriptions(props) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { subscriptions, subscriptionsLoading } = useSubscriptions();
  const [subscriptionType, setSubscriptionType] = React.useState('');
  const [subscriptionRenewalInterval, setSubscriptionRenewalInterval] = React.useState('');
  const donationState = useDonation();
  const auth = useSelector(state => state.auth);
  const { createToast } = useToast();

  // MTS - check to see if this is a redirect from expired subscription
  const urlParams = new URLSearchParams(window?.location?.search);
  const isExpired = Boolean(urlParams.get('expired'));

  React.useEffect(() => {
    // Once subscriptions are pulled down from the backend perform
    // required checks before allowing to proceed.
    if (subscriptionsLoading === false) {
      checkUser();
    }
    // eslint-disable-next-line
  }, [subscriptionsLoading]);

  // On mount check to see if we need to reset
  // the access_search policy to false
  React.useEffect(() => {
    if (auth?.policies?.user?.access_search && isExpired) {
      const newUserPolicy = {
        ...auth.policies.user,
        access_search: false,
      };
      createToast({
        duration: 5000,
        message:
          'Your subscription has expired. Please choose a plan to continue.',
        type: 'error',
      });
      const newPolicies = { ...auth.policies, user: newUserPolicy };
      dispatch(updatePolicies(newPolicies));
    }
    // eslint-disable-next-line
  }, []);

  function checkUser() {
    if (subscriptions.length === 0) {
      setError(true);
      sendError('Error setting prices', new Error({ subscriptions }));
      createToast({
        message: 'We are unable to modify your subscription at this time',
        type: 'error',
      });
    } else {
      const firstSubscription = subscriptions[0];
      setSubscriptionType(firstSubscription.price_id);
      setSubscriptionRenewalInterval(firstSubscription.interval);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const payload = {
      cancel_url: `${window.location.origin}${routes.subscription}`,
      donation: donationState.currentDonation, // dollar amount in cents ($10)
      donation_recurring: donationState.recurring, // set to false if it's a one-time donation
      price_id: subscriptionType,
      success_url: `${window.location.origin}${routes.subscriptionSuccess}`,
    };
    http
      .post(endpoints.subscription, payload)
      .then(({ data }) => {
        setLoading(false);
        window.location.href = data.url;
      })
      .catch(err => {
        setError(true);
        createToast({
          message: 'Something went wrong, refresh and try again or reach out to contact@mmatlas.com.',
          type: 'error',
        });
        setLoading(false);

        sendError('Error setting subscription', err);
      });
  }

  function getPriceText(obj) {
    const price = obj.unit_amount_in_cents;
    if (price > 0) {
      return `$${price / 100} / ${obj.interval}`;
    }
    return 'Free';
  }

  function getTotal() {
    const donation = donationState.currentDonation;
    const current = subscriptions.find(el => el.price_id === subscriptionType)
      ?.unit_amount_in_cents;
    return (donation + current) / 100;
  }

  if (loading || subscriptionsLoading) {
    return (
      <Layout>
        <PageLoading />
      </Layout>
    );
  }

  return (
    <Layout>
      <PageTitle as="h1" color="darkBrown" center size="f1">
        Choose your contribution.
      </PageTitle>
      <Grid>
        <SubscriptionText />
        <RightSide onSubmit={handleSubmit}>
          <Title>Subscription</Title>
          {subscriptions.map(el => (
            <RadioInput
              key={el.price_id}
              checked={subscriptionType === el.price_id}
              name="subscriptionType"
              value={el.price_id}
              onChange={() => {
                setSubscriptionType(el.price_id)
                setSubscriptionRenewalInterval(el.interval)
              }}
            >
              {getPriceText(el)}
            </RadioInput>
          ))}
          <Title style={{ marginTop: 20 }}>Additional Commitment</Title>
          <Donations donationState={donationState} subscriptionRenewalInterval={subscriptionRenewalInterval} />
          <Title style={{ marginBottom: 0, marginTop: 22 }}>
            Your contribution today
          </Title>
          <Total size="f1">${getTotal()}</Total>
          <Button disabled={loading || error} loading={loading} type="submit">
            Next
          </Button>
        </RightSide>
      </Grid>
    </Layout>
  );
}

Subscriptions.propTypes = {};

Subscriptions.defaultProps = {};

export default Subscriptions;
