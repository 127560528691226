import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import routes from 'utils/routes';

import { Link } from 'utils/router';
import Text from 'components/Text';

const StyledSidebar = styled.div``;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 6px;
  text-transform: uppercase;
  opacity: 0.5;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
`;

const SidebarItem = styled(({ active, ...rest }) => <Link {...rest} />)`
  align-self: flex-start;
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : 'transparent'};
  border-radius: 14px;
  color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.darkBrown};
  display: inline-flex;
  font-size: 12px;
  font-weight: bold;
  height: 28px;
  padding: 0 12px;
  margin-bottom: 6px;
  text-decoration: none;
`;

function Sidebar({ pathname }) {
  return (
    <StyledSidebar>
      <Group>
        <Title>Pending</Title>
        <SidebarItem active={pathname === '/admin'} to="/admin">
          Users
        </SidebarItem>
        <SidebarItem
          active={pathname.includes(routes.pendingImages)}
          to={routes.pendingImages}
        >
          Images
        </SidebarItem>
        <SidebarItem
          active={pathname.includes(routes.invitedUsers)}
          to={routes.invitedUsers}
        >
          Invited Users
        </SidebarItem>
      </Group>
      <Group>
        <Title>Database</Title>
        <SidebarItem
          active={pathname.includes(routes.allUsers)}
          to={routes.allUsers}
        >
          All Users
        </SidebarItem>
        <SidebarItem
          active={pathname.includes(routes.allImages)}
          to={routes.allImages}
        >
          All Images
        </SidebarItem>
        <SidebarItem
          active={pathname.includes(routes.deniedImages)}
          to={routes.deniedImages}
        >
          Denied Images
        </SidebarItem>
        <SidebarItem
          active={pathname.includes(routes.deniedUsers)}
          to={routes.deniedUsers}
        >
          Denied Users
        </SidebarItem>
      </Group>
    </StyledSidebar>
  );
}

Sidebar.propTypes = {
  pathname: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {};

export default Sidebar;
