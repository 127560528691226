import styled from 'styled-components/macro';
import Link from 'components/Link';

const isDev = process.env.NODE_ENV === 'development';

const HeaderLink = styled(({ active, onlyDev, ...rest }) => <Link {...rest} />)`
  align-items: center;
  border-bottom: 2px solid
    ${({ active, theme }) =>
      active ? theme.colors.primaryColor : 'transparent'};
  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.darkBrown};
  display: flex;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  margin-left: 32px;
  padding-top: 2px;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  ${({ onlyDev }) => onlyDev && !isDev && `display: none;`};
`;

export default HeaderLink;
