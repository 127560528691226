import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import FilterGroup from './FilterGroup';
import MobileFilters from './MobileFilters';

import filtersReducer, { initialState } from './filtersReducer';
import useIsMobile from 'hooks/useIsMobile';

const StyledFilters = styled.div`
  grid-area: filters;
  width: 200px;
  position: relative;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ $height }) => $height || 0}px;
  right: 0;
  z-index: 0;
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 8px;
`;

function FiltersBar({
  availableFilters,
  loading,
  selectedFilters,
  setSelectedFilters,
  toggleFilter,
}) {
  const maskRef = React.useRef(null);
  const isMobile = useIsMobile();
  const [filtersOpen, dispatch] = React.useReducer(
    filtersReducer,
    initialState
  );

  if (loading) {
    return null;
  }

  function getHeight() {
    const height = window.innerHeight;
    const rect = maskRef?.current?.getBoundingClientRect();
    const thisHeight = height - rect?.top;
    return thisHeight;
  }

  function toggleOpen(group) {
    if (isMobile) {
      return dispatch({ type: 'mobileToggle', payload: group });
    }
    dispatch({ type: 'toggle', payload: group });
  }

  function addType(arr, type) {
    return arr.map(el => ({ ...el, type }));
  }

  if (!availableFilters) {
    return null;
  }

  const filterTypes = {
    conditions: addType(availableFilters.conditions, 'conditions'),
    duration: addType(availableFilters.duration, 'duration'),
    locations: addType(availableFilters.locations, 'locations'),
    painQuality: addType(availableFilters.pain_quality, 'pain_quality'),
    symptoms: addType(availableFilters.symptoms, 'symptoms'),
    timings: addType(availableFilters.timings, 'timings'),
  };

  if (isMobile) {
    return (
      <MobileFilters
        filters={filterTypes}
        filtersOpen={filtersOpen}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        toggleOpen={toggleOpen}
        toggleFilter={toggleFilter}
      />
    );
  }

  return (
    <StyledFilters>
      <Mask $height={getHeight()} ref={maskRef} data-tour="filters-sidebar" />
      <Wrapper>
        <Title>Filter By:</Title>
        <FilterGroup
          availableFilters={filterTypes.conditions}
          open={filtersOpen.conditions}
          selectedFilters={selectedFilters}
          title="Conditions"
          toggleFilter={toggleFilter}
          toggleOpen={() => toggleOpen('conditions')}
        />
        <FilterGroup
          availableFilters={filterTypes.symptoms}
          open={filtersOpen.symptoms}
          selectedFilters={selectedFilters}
          title="Associated Symptoms"
          toggleFilter={toggleFilter}
          toggleOpen={() => toggleOpen('symptoms')}
        />
        <FilterGroup
          availableFilters={filterTypes.locations}
          description={'Where symptom was most prominent.'}
          open={filtersOpen.locations}
          selectedFilters={selectedFilters}
          title="Location"
          toggleFilter={toggleFilter}
          toggleOpen={() => toggleOpen('locations')}
        />
        <FilterGroup
          availableFilters={filterTypes.painQuality}
          description={'Type of pain experienced.'}
          open={filtersOpen.painQuality}
          selectedFilters={selectedFilters}
          title="Pain Quality"
          toggleFilter={toggleFilter}
          toggleOpen={() => toggleOpen('painQuality')}
        />
        <FilterGroup
          availableFilters={filterTypes.duration}
          description={
            'How long symptoms had been experienced at the time of the photo.'
          }
          open={filtersOpen.duration}
          selectedFilters={selectedFilters}
          title="Duration of Condition"
          toggleFilter={toggleFilter}
          toggleOpen={() => toggleOpen('duration')}
        />
        <FilterGroup
          availableFilters={filterTypes.timings}
          description={'When the pain/discomfort was most experienced.'}
          open={filtersOpen.timings}
          selectedFilters={selectedFilters}
          title="Timing"
          toggleFilter={toggleFilter}
          toggleOpen={() => toggleOpen('timings')}
        />
      </Wrapper>
    </StyledFilters>
  );
}

FiltersBar.propTypes = {
  availableFilters: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};

FiltersBar.defaultProps = {
  availableFilters: null,
};

export default FiltersBar;
