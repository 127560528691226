import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Expand from './Expand';
import Text from 'components/Text';
import Toggle from './Toggle';

const StyledHelpItem = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightTan};
  padding-bottom: 26px;
  margin-top: 18px;
`;

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-weight: 600;
  margin-bottom: 10px;
`;

function HelpItem({ children, title }) {
  const [open, setOpen] = React.useState(false);
  return (
    <StyledHelpItem>
      <Row onClick={() => setOpen(!open)}>
        <Title as="h2" color="darkBrown" size="f3">
          {title}
        </Title>
        <Toggle onClick={() => setOpen(!open)} open={open} />
      </Row>
      <Expand open={open}>{children}</Expand>
    </StyledHelpItem>
  );
}

HelpItem.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

HelpItem.defaultProps = {};

export default HelpItem;
