import * as React from 'react';

function useLockScroll(lock) {
  React.useEffect(() => {
    if (lock) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.left = '0px';
      document.body.style.right = '0px';
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.right = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [lock]);
}

export default useLockScroll;
