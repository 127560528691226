import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { navigate } from 'utils/router';

import Button from 'components/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
`;

function NavButtons({ submitDisabled, submitText, ...rest }) {
  const buttonStyle = { maxWidth: 160 };
  return (
    <Wrapper {...rest}>
      <Button secondary onClick={() => navigate(-1)} style={buttonStyle}>
        Previous
      </Button>
      <Button disabled={submitDisabled} type="submit" style={buttonStyle}>
        {submitText || 'Next'}
      </Button>
    </Wrapper>
  );
}

NavButtons.propTypes = {
  submitDisabled: PropTypes.bool,
  submitText: PropTypes.string,
};

NavButtons.defaultProps = {
  submitDisabled: false,
  submitText: null,
};

export default NavButtons;
