import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Button';
import Text from 'components/Text';

const Ol = styled.ol`
  margin-top: 0.25rem;
`;

function ImageUploadIndex() {
  return (
    <div className="ImageUploadIndex">
      <Text as="h1" center size="h2" style={{ margin: '0 0 30px' }}>
        Welcome to the Melanated Mammary Atlas&reg; Image Upload Tool!
      </Text>
      <Text font="serif">
        This is where you will upload an image to The Melanated Mammary
        Atlas&reg;.
      </Text>
      <Text font="serif">
        Before you begin, it will help to have:
        <Ol>
          <li>A close, clear image of the breast/chest ready.</li>
          <li>Details about what is pictured</li>
          <li>Permission to share the image for educational purposes.</li>
        </Ol>
      </Text>
      <Text font="serif">
        Your contribution today will impact the way people with brown skin are
        assessed and treated around the world.
      </Text>
      <Text font="serif">
        Welcome to the movement to normalize brown breasts. Together, we are
        inching toward health equity for all.
      </Text>
      <Button
        style={{ margin: '40px auto 0', maxWidth: 346 }}
        to="/image-upload/contact"
      >
        Get Started
      </Button>
    </div>
  );
}

ImageUploadIndex.propTypes = {};

ImageUploadIndex.defaultProps = {};

export default ImageUploadIndex;
