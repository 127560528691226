import { addToast } from 'reducers/toasts';
import { useDispatch } from 'react-redux';

function useToast() {
  const dispatch = useDispatch();

  function createToast(obj) {
    dispatch(addToast(obj));
  }

  return { createToast };
}

export default useToast;
