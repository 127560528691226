import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import RadioInput from 'components/RadioInput';

const StyledRecurring = styled.div`
  padding-left: 30px;
  transform: translateY(-4px);
  margin-bottom: -4px;
`;

function Recurring({ onChange, recurring, subscriptionRenewalInterval }) {
  const nestedRadioStyle = {
    height: 'initial',
    margin: '0',
    padding: '6px 14px',
  };


  return (
    <StyledRecurring>
      <RadioInput
        checked={recurring === "one-time"}
        name="recurring"
        onChange={onChange}
        value="one-time"
        style={nestedRadioStyle}
      >
        One time donation
      </RadioInput>
      <RadioInput
        checked={recurring === subscriptionRenewalInterval}
        name="recurring"
        onChange={onChange}
        value={subscriptionRenewalInterval}
        style={nestedRadioStyle}
      >
        {`${subscriptionRenewalInterval.charAt(0).toUpperCase()}${subscriptionRenewalInterval.slice(1)}ly`}
      </RadioInput>
    </StyledRecurring>
  );
}

Recurring.propTypes = {
  onChange: PropTypes.func.isRequired,
  recurring: PropTypes.string.isRequired,
  subscriptionRenewalInterval: PropTypes.string.isRequired,
};

Recurring.defaultProps = {};

export default Recurring;
