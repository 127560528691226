import React from 'react';

function Unchecked(props) {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.75"
        y="1.75"
        width="19.25"
        height="19.25"
        rx="1"
        fill="#fff"
        stroke="#D8CEC4"
        strokeWidth="2"
      />
    </svg>
  );
}

Unchecked.propTypes = {};

Unchecked.defaultProps = {};

export default Unchecked;
