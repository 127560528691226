import * as React from 'react';
// import PropTypes from 'prop-types';

function Arrow(props) {
  return (
    <svg
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2501 3.41648H3.02506L5.53358 0.908518L4.71655 0.0914917L0.808594 4.00001L4.71655 7.90853L5.53358 7.09151L3.02506 4.58355H11.2501V3.41648Z"
        fill="#84603F"
      />
    </svg>
  );
}

Arrow.propTypes = {};

Arrow.defaultProps = {};

export default Arrow;
