import React from 'react';
import PropTypes from 'prop-types';
// import { navigate } from 'utils/router';

import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Loading from 'components/Loading';
import Link from 'components/Link';
import Text from 'components/Text';

import Form from '../../components/Form';
import PageWrapper from '../../components/PageWrapper';
import NavButtons from '../../components/NavButtons';

import routes from 'utils/routes';
import { useForm } from 'react-hook-form';
import useScrollTop from 'hooks/useScrollTop';

function ConsentSubmit({
  error,
  loading,
  success,
  setSuccess,
  setUploadData,
  submitForm,
  uploadData,
}) {
  useScrollTop();
  const [consent1, setConsent1] = React.useState(false);
  const [consent2, setConsent2] = React.useState(false);
  const [consent3, setConsent3] = React.useState(false);
  const [consent4, setConsent4] = React.useState(false);

  const defaultValues = {
    esignature: uploadData.esignature,
  };

  React.useEffect(() => {
    return () => setSuccess(false);
    // eslint-disable-next-line
  }, []);

  const {
    handleSubmit,
    // formState: { errors },
    register,
  } = useForm({ defaultValues });

  function onSubmit(d) {
    const data = {
      ...uploadData,
      esignature: d.sign,
    };
    setUploadData(data);
    submitForm(data);
  }

  const labels = [
    'I grant all rights to use and display the uploaded images and associated data.',
    'I understand that the image can be used for up to 12 years unless I provide written notice.',
    'I release The Melanated Mammary Atlas™ from any and all possible legal repercussions from using the image I provided.',
    'I confirm that I own the rights or have obtained the necessary permissions for this image.',
  ];

  function checkDisabled() {
    if (!consent1 || !consent2 || !consent3 || !consent4) {
      return true;
    }
    return false;
  }

  if (loading) {
    return <PageWrapper title={<Loading />} />;
  }

  if (success) {
    return (
      <PageWrapper title="Success">
        <Text center>
          Thank you for contributing to The Melanated Mammary Atlas &trade;
        </Text>
        <Link
          onClick={() => setSuccess(false)}
          to={routes.imageUpload}
          style={{ marginTop: 20 }}
        >
          Upload Another Image
        </Link>
        <Text style={{ margin: 10 }}>or</Text>
        <Link onClick={() => setSuccess(false)} to={routes.login}>
          Login
        </Link>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title="Consent Part 2: Accept &amp; Submit">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Checkbox
          checked={consent1}
          label={labels[0]}
          required
          onChange={() => setConsent1(!consent1)}
        />
        <Checkbox
          checked={consent2}
          label={labels[1]}
          required
          onChange={() => setConsent2(!consent2)}
        />
        <Checkbox
          checked={consent3}
          label={labels[2]}
          required
          onChange={() => setConsent3(!consent3)}
        />
        <Checkbox
          checked={consent4}
          label={labels[3]}
          required
          onChange={() => setConsent4(!consent4)}
        />
        <Input
          description="Type your name below"
          label="Electronic Signature"
          required
          {...register('sign', { required: true })}
        />
        {error && <Text error>{error}</Text>}
        <NavButtons submitDisabled={checkDisabled()} submitText="Submit" />
      </Form>
    </PageWrapper>
  );
}

ConsentSubmit.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  setSuccess: PropTypes.func.isRequired,
  setUploadData: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  uploadData: PropTypes.any.isRequired,
};

ConsentSubmit.defaultProps = {};

export default ConsentSubmit;
