import * as React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';

function ProviderInputs({ user }) {
  const provider = user?.provider;
  return (
    <>
      <Input disabled label="Credentials" value={provider?.credentials || ''} />
      <Input
        disabled
        label="Country of Operation"
        value={provider?.country_of_operation || ''}
      />

      <Input disabled label="State/Region" value={provider.region || ''} />

      {provider.npi_number && (
        <Input disabled label="NPI Number" value={provider.npi_number || ''} />
      )}
      {provider?.identifier_type && (
        <Input
          disabled
          label="License/Certification Type"
          value={provider?.identifier_type || ''}
        />
      )}
      {provider?.id_number && (
        <Input
          disabled
          label="License/Certification Number"
          value={provider?.id_number || ''}
        />
      )}
    </>
  );
}

ProviderInputs.propTypes = {
  user: PropTypes.shape({
    provider: PropTypes.shape({
      country_of_operaction: PropTypes.string,
      credentials: PropTypes.string,
      identifier_type: PropTypes.string,
      id_number: PropTypes.string,
      npi_number: PropTypes.string,
    }),
  }),
};

ProviderInputs.defaultProps = {};

export default ProviderInputs;
