import * as React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';

function PageWrapper({ children, title }) {
  return (
    <>
      <Text as="h1" center size="h1" style={{ marginTop: 0, marginBottom: 10 }}>
        {title}
      </Text>
      {children}
    </>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any.isRequired,
};

PageWrapper.defaultProps = {
  children: null,
};

export default PageWrapper;
