import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Description from './Description';
import FilterActions from './FilterActions';
import FilterButton from 'pages/search-results/pages/searchResultsIndex/components/FilterButton';
import FilterCategoryList from './FilterCategoryList';

import useLockScroll from 'hooks/useLockScroll';

const HEIGHT = 46;

const FilterArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 12px 15px 20px;
`;

const StyledMobileFilters = styled.div`
  height: ${HEIGHT}px;
  position: relative;
`;

const CategoryFilters = styled.div`
  background-color: ${({ theme }) => theme.colors.nearWhite};
  display: ${({ $open }) => ($open ? 'block' : 'none')};
  left: 0px;
  position: absolute;
  z-index: 1;
`;

function MobileFilters({
  filters,
  filtersOpen,
  selectedFilters,
  setSelectedFilters,
  toggleFilter,
  toggleOpen,
}) {
  const categoryOpen = getOpen();
  const open = !!categoryOpen;
  const [dim, setDim] = React.useState({
    contentHeight: 0,
    height: 0,
    width: 0,
  });
  const filterRef = React.useRef();
  const displayFilters = openFilters();
  useLockScroll(open);

  React.useEffect(() => {
    getBox();
  }, []);

  function getOpen() {
    return Object.keys(filtersOpen).find(el => !!filtersOpen[el]);
  }

  function clearFilters() {
    setSelectedFilters([]);
  }

  function openFilters() {
    const catOpen = getOpen();
    if (catOpen) {
      return filters[catOpen];
    }
    return [];
  }

  function getBox() {
    const height =
      window.screen.height - filterRef.current.getBoundingClientRect().bottom;
    setDim({
      contentHeight: height - 180,
      height,
      width: window.screen.width,
    });
  }

  return (
    <StyledMobileFilters ref={filterRef} data-tour="filters-sidebar">
      <FilterCategoryList
        categoryOpen={categoryOpen}
        filters={filters}
        handleClick={toggleOpen}
        selectedFilters={selectedFilters}
      />
      <CategoryFilters
        $open={open}
        style={{ top: HEIGHT, width: dim.width, height: dim.height }}
      >
        <Description categoryOpen={categoryOpen} />
        <FilterArea style={{ maxHeight: dim.contentHeight }}>
          {displayFilters.map(el => (
            <FilterButton
              key={el.id}
              isSelected={selectedFilters.find(filter => filter.id === el.id)}
              onClick={() => toggleFilter(el)}
            >
              {el.name}
            </FilterButton>
          ))}
        </FilterArea>
        <FilterActions
          clearFilters={clearFilters}
          close={() => toggleOpen(categoryOpen)}
          open={open}
          selectedFilters={selectedFilters}
        />
      </CategoryFilters>
    </StyledMobileFilters>
  );
}

MobileFilters.propTypes = {
  filters: PropTypes.object,
  filtersOpen: PropTypes.object,
  selectedFilters: PropTypes.array,
  setSelectedFilters: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};

MobileFilters.defaultProps = {
  filters: {},
  filtersOpen: {},
  selectedFilters: [],
};

export default MobileFilters;
