import * as Sentry from '@sentry/react';

const notDev = process.env.NODE_ENV !== 'development';

class HandledError extends Error {
  constructor(errMessage, data) {
    // Passes errMessage to the Error super class,
    // similar to call new Error(errMessage).
    super(errMessage);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HandledError);
    }

    this.name = 'HandledError';
  }
}

function formatErrorString(errObj) {
  const response = errObj?.response?.data;
  if (response?.errors) {
    return response.errors.join(', ');
  } else {
    return JSON.stringify(response);
  }
}

function sendError(message, errObj, extraData) {
  if (notDev) {
    const backendResponse = formatErrorString(errObj);
    addBreadcrumb({
      category: 'sendError Breadcrumb',
      data: {
        backendResponse,
        ...errObj,
        ...extraData,
      },
      level: 'error',
      message,
    });

    let thisError;

    // MTS - We check to see if the errObj is actually a javascript error.
    // Sometimes it isn't.
    if (errObj instanceof Error) {
      // This overrides the error message to something that is
      // easily readable in Sentry.
      thisError = new Error(message, errObj);
    } else {
      thisError = new HandledError(message, {
        ...errObj,
        ...extraData,
      });
    }

    Sentry.captureException(thisError, {
      extra: {
        backendResponse,
        message,
        ...errObj,
        ...extraData,
      },
    });
  } else {
    console.error({ message, errObj, extraData });
  }
}

export const addBreadcrumb = ({
  category = 'default',
  data = {},
  message = '',
  level = 'log',
}) => {
  // valid level values: 'log', 'error', 'fatal', 'info', 'warning', 'critical'
  if (notDev) {
    Sentry.addBreadcrumb({
      category,
      data,
      level,
      message,
    });
  }
};

export default sendError;
