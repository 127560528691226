import * as React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import styled from 'styled-components/macro';

const StyledExpand = styled(animated.div)`
  ${({ open }) => !open && 'pointer-events: none;'};
  div > p {
    margin-top: 0;
  }
`;

function Expand({ children, open }) {
  const defaultHeight = '0px';
  const [contentHeight, setContentHeight] = React.useState(defaultHeight);
  const [ref, { height }] = useMeasure();

  // Animations
  const expand = useSpring({
    height: open ? `${contentHeight}px` : defaultHeight,
    opacity: open ? 1 : 0,
    transform: `translateY(${open ? 0 : -6}px)`,
  });

  React.useEffect(() => {
    //Sets initial height
    setContentHeight(height);

    //Adds resize event listener
    window.addEventListener('resize', setContentHeight(height));

    // Clean-up
    return window.removeEventListener('resize', setContentHeight(height));
  }, [height]);

  return (
    <StyledExpand open={open} style={expand}>
      <div ref={ref}>{children}</div>
    </StyledExpand>
  );
}

Expand.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Expand;
