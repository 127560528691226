import * as React from 'react';
import PropTypes from 'prop-types';

import http from 'utils/http';
import { sendError } from 'utils/helpers';
import endpoints from 'utils/endpoints';

import EditImage from './components/EditImage';
import PageLoading from 'components/PageLoading';
import PendingImage from './components/PendingImage';

function ImageDetail({ imageId }) {
  const [loading, setLoading] = React.useState(true);
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    http(`${endpoints.adminImageDetail}?id=${imageId}`)
      .then(({ data }) => setImage(data.data))
      .catch(err => sendError('Error fetching admin image detail ', err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  switch (image?.status) {
    case 'pending':
      return <PendingImage image={image} />;
    case 'approved':
      return <EditImage image={image} />;
    case 'denied':
      return <PendingImage image={image} isDenied />;
    default:
      return null;
  }
}

ImageDetail.propTypes = {
  imageId: PropTypes.string.isRequired,
};

ImageDetail.defaultProps = {};

export default ImageDetail;
