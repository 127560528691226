import * as React from 'react';

function useScrollTop() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default useScrollTop;
