import * as React from 'react';
import PropTypes from 'prop-types';

import CustomAmount from './CustomAmount';
import RadioInput from 'components/RadioInput';
import RadioWrapper from './RadioWrapper';

function Donations({ donationState, subscriptionRenewalInterval }) {
  const {
    customAmount,
    recurring,
    setRecurring,
    selectedDonation,
  } = donationState;

  function handleRecurring(e) {
    setRecurring(e.target.value);
  }

  return (
    <>
      <RadioInput
        onChange={donationState.handleNoneSelect}
        checked={selectedDonation === 'none'}
        name="donation"
        value="none"
      >
        None
      </RadioInput>
      <RadioWrapper
        active={selectedDonation === 'five'}
        recurring={recurring}
        subscriptionRenewalInterval={subscriptionRenewalInterval}
        handleRecurringChange={handleRecurring}
      >
        <RadioInput
          onChange={donationState.handleFiveSelect}
          checked={selectedDonation === 'five'}
          name="donation"
          value="five"
          style={{ marginBottom: 0 }}
        >
          $5
        </RadioInput>
      </RadioWrapper>

      <RadioWrapper
        active={selectedDonation === 'ten'}
        recurring={recurring}
        subscriptionRenewalInterval={subscriptionRenewalInterval}
        handleRecurringChange={handleRecurring}
      >
        <RadioInput
          onChange={donationState.handleTenSelect}
          checked={selectedDonation === 'ten'}
          name="donation"
          value="ten"
        >
          $10
        </RadioInput>
      </RadioWrapper>
      <RadioWrapper
        active={selectedDonation === 'custom'}
        recurring={recurring}
        subscriptionRenewalInterval={subscriptionRenewalInterval}
        handleRecurringChange={handleRecurring}
      >
        <RadioInput
          onChange={donationState.handleCustomSelect}
          checked={selectedDonation === 'custom'}
          name="donation"
          value="custom"
          style={{ marginBottom: 0 }}
        >
          Custom Amount
        </RadioInput>
        <CustomAmount
          type="number"
          onChange={donationState.handleCustomInput}
          value={customAmount}
          style={{ marginBottom: 4 }}
        />
      </RadioWrapper>
    </>
  );
}

Donations.propTypes = {
  donationState: PropTypes.shape({
    customAmount: PropTypes.number.isRequired,
    handleCustomInput: PropTypes.func.isRequired,
    handleCustomSelect: PropTypes.func.isRequired,
    handleNoneSelect: PropTypes.func.isRequired,
    handleTenSelect: PropTypes.func.isRequired,
    handleFiveSelect: PropTypes.func.isRequired,
    recurring: PropTypes.bool.isRequired,
    selectedDonation: PropTypes.string.isRequired,
    setRecurring: PropTypes.func.isRequired,
  }),
  subscriptionRenewalInterval: PropTypes.string.isRequired,
};

Donations.defaultProps = {};

export default Donations;
