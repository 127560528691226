import * as React from 'react';
// import PropTypes from 'prop-types';

import ToastItem from './ToastItem';

import { initToasts } from 'reducers/toasts';
import { useDispatch, useSelector } from 'react-redux';
import { useTransition } from 'react-spring';

const MOVEMENT = 20;

// helper function for creating toasts
export const createToast = ({
  type = 'default',
  message = '',
  duration = 2000,
}) => {
  return { type, message, duration };
};

function ToastList() {
  const dispatch = useDispatch();
  const toasts = useSelector(state => state.toasts);
  const transitions = useTransition(toasts, {
    from: { opacity: 0, transform: `translate(-50%, ${MOVEMENT}px)` },
    keys: item => item.id,
    enter: item => async (next, cancel) => {
      await next({ opacity: 1, transform: `translate(-50%, 0px)` });
    },
    leave: [{ opacity: 0, transform: `translate(-50%,-${MOVEMENT}px)` }],
  });

  // On unmount remove all toasts
  React.useEffect(() => {
    return () => {
      dispatch(initToasts());
    };
    // eslint-disable-next-line
  }, []);

  return transitions(({ ...style }, item) => (
    <ToastItem {...item} springProps={style} />
  ));
}

ToastList.propTypes = {};

ToastList.defaultProps = {};

export default ToastList;
