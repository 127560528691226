import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import RadioChecked from './RadioChecked';
import RadioUnchecked from './RadioUnchecked';
import Text from 'components/Text';

export const RadioLabel = styled.label`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 55px;
  margin: 4px 0;
  padding: 16px 14px;
`;

const Input = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
`;

const StyledText = styled(Text)`
  font-weight: 500;
  margin: 0;
  margin-left: 8px;
`;

function RadioInput({ checked, children, name, style, ...rest }) {
  return (
    <RadioLabel style={style}>
      <Input name={name} type="radio" {...rest} />
      {checked ? <RadioChecked /> : <RadioUnchecked />}
      <StyledText>{children}</StyledText>
    </RadioLabel>
  );
}

RadioInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

RadioInput.defaultProps = {
  children: null,
  style: {},
};

export default RadioInput;
