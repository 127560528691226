import * as React from 'react';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';

function getFilterKey(filter) {
  switch (filter.type) {
    case 'conditions':
      return 'condition_ids';
    case 'symptoms':
      return 'symptom_ids';
    case 'locations':
      return 'location_ids';
    case 'duration':
      return 'duration';
    case 'pain_quality':
      return 'pain_quality';
    case 'timings':
      return 'timing_ids';
    default:
      return null;
  }
}

const LIMIT = 20;

function useResults(termObj, selectedFilters, setSearchError) {
  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setPage(1);
    search();
    // eslint-disable-next-line
  }, [termObj, selectedFilters]);

  React.useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [page, selectedFilters]);

  function search() {
    if (!termObj) {
      setSearchError(true);
      return;
    } else {
      setSearchError(false);
    }
    setLoading(true);
    const filters = buildFilters();
    const payload = {
      limit: LIMIT,
      offset: page * LIMIT - LIMIT,
      search: termObj?.id || '',
      ...filters,
    };

    http
      .post(endpoints.search, payload)
      .then(({ data }) => {
        setResults(data.data);
        setTotal(data.meta.total);
      })
      .catch(err => {
        sendError('Error fetching search results', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function buildFilters() {
    const filters = {
      condition_ids: [],
      symptom_ids: [],
      duration: [],
      location_ids: [],
      pain_quality: [],
      timing_ids: [],
    };
    const filterPayload = selectedFilters.reduce((acc, el) => {
      const filterKey = getFilterKey(el);
      if (filterKey) {
        return { ...acc, [filterKey]: acc[filterKey].concat([el.id]) };
      }
      return acc;
    }, filters);
    return filterPayload;
  }

  return { loading, limit: LIMIT, page, results, setPage, total };
}

export default useResults;
