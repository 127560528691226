import * as React from 'react';
import styled from 'styled-components';

import Link from 'components/Link';

import routes from 'utils/routes';
import useIsMobile from 'hooks/useIsMobile';

const StyledLink = styled(Link)`
  font-weight: 700;
  text-decoration: underline;
`;

function useHelpItems({ photoCredit }) {
  const isMobile = useIsMobile();
  const helpItems = [
    {
      content: (
        <>
          <p>
            Use the results as a reference in your practice by expanding your
            knowledge, showing to your team, or helping a patient in real-time.
          </p>
          <p>
            You can also access the high-res version of the photo, and use in
            presentations after contacting the owner.
            <br />
            <StyledLink to={routes.help}>Read more in our FAQs</StyledLink>.
          </p>
        </>
      ),
      contentElement: 'div',
      hide: !photoCredit,
      maxWidth: 389,
      selector: '[data-tour="photo-credit"]',
      position: 'top',
      title: 'What do I do with the results?',
      text: 'How to use The MM Atlas',
    },
    {
      content: (
        <>
          <p>
            Access the watermark-free, high-res version of any photo by
            contacting the person who uploaded it.
            <br />
            <StyledLink to={routes.faq}>Read more in our FAQs.</StyledLink>
          </p>
        </>
      ),
      contentElement: 'div',
      hide: !photoCredit,
      maxWidth: 389,
      selector: '[data-tour="photo-credit"]',
      position: 'top',
      title: 'Can I use this photo in my research or a presentation?',
      text: 'Contact the owner of the photo',
    },
    {
      content: 'View related images here underneath each image’s description.',
      maxWidth: 309,
      selector: isMobile
        ? '[data-tour="related-images-mobile"]'
        : '[data-tour="related-images"]',
      position: isMobile ? 'bottom' : 'top',
      title: 'How can I see similar images of this condition/symptom?',
      text: 'Look at related images',
    },
  ];
  return helpItems;
}

export default useHelpItems;
