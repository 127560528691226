import * as React from 'react';
// import PropTypes from 'prop-types';

function Arrow(props) {
  return (
    <svg
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4102 5.20501L9.38881 6.22636L5.20516 2.04271L1.02151 6.22636L0.000156492 5.20501L5.20516 1.33732e-05L10.4102 5.20501Z"
        fill="#84603F"
      />
    </svg>
  );
}

Arrow.propTypes = {};

Arrow.defaultProps = {};

export default Arrow;
