import * as React from 'react';
// import PropTypes from 'prop-types';

import Button from 'components/Button';
import Card from '../Card';
import Text from 'components/Text';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';
import { useSelector } from 'react-redux';
import useToast from 'hooks/useToast';

function PasswordCard() {
  const { createToast } = useToast();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const email = useSelector(({ auth }) => auth.data.email);

  function handleClick() {
    setLoading(true);
    http
      .post(endpoints.resetPassword, { email })
      .then(res => {
        setSuccess(true);
      })
      .catch(err => {
        createToast({
          duration: 5000,
          message: `We're unable to reset your password at this time`,
          type: 'error',
        });
        sendError('Error resetting user password on settings page', err);
      })
      .finally(() => {
        console.log('done');
        setLoading(false);
      });
  }

  return (
    <Card title="Password">
      {!success && (
        <Button loading={loading} small onClick={handleClick}>
          Reset My Password
        </Button>
      )}
      {success && (
        <Text color="primary">
          <strong>Success!</strong>
          <br /> We've sent you an email with a link to reset your password
        </Text>
      )}
    </Card>
  );
}

PasswordCard.propTypes = {};

PasswordCard.defaultProps = {};

export default PasswordCard;
