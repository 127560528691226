import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Name from '../Name';

import { media } from 'styles/theme';

const StyledList = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.brown2};
  border-top: none;
  border-bottom: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.brown2};
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 54px;
  z-index: 4;
  ${media.ns} {
    top: ${({ $small }) => ($small ? 54 : 68)}px;
    border: 2px solid ${({ theme }) => theme.colors.lightTan};
    border-top: none;
    ${({ noMatches }) => noMatches && 'border: none;'}
  }
  ${({ noMatches }) => noMatches && 'border: none;'}
`;

const ListItem = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 15px 16px;
  width: 100%;
  span:last-of-type {
    ${({ theme }) => theme.colors.mediumBrown};
  }
  ${media.ns} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.mediumLightBrown};
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Type = styled.span`
  ${media.sm} {
    margin-top: 2px;
  }
`;

function List({ matches, onSelect, searchTerm, small }) {
  const listRef = React.useRef(null);

  function getMaxHeight() {
    if (listRef?.current) {
      const windowHeight = window.innerHeight;
      const dim = listRef.current.getBoundingClientRect();
      const maxHeight = windowHeight - dim.top - 80;
      return maxHeight;
    }
  }

  const listMaxHeight = getMaxHeight();
  return (
    <StyledList
      noMatches={matches.length === 0}
      ref={listRef}
      $small={small}
      style={{ maxHeight: listMaxHeight }}
    >
      {matches.map(el => (
        <ListItem key={el.id} onClick={() => onSelect(el)}>
          <Name name={el.name} searchTerm={searchTerm} />
          <Type>{el.type}</Type>
        </ListItem>
      ))}
    </StyledList>
  );
}

List.propTypes = {
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

List.defaultProps = {
  matches: [],
  small: false,
};

export default List;
