const PRIMARY_COLOR = '#84603F';
const SECONDARY_COLOR = '#D1A886';

export const screen = {
  md: '48em',
  lg: '64em',
};

export const media = {
  // MTS - I need to do px right here instead of a variable
  sm: `@media only screen and (max-width: 767px)`,
  // not small
  ns: `@media screen and (min-width: ${screen.md})`,
  // medium
  md: `@media screen and (min-width: ${screen.md}) and (max-width: ${screen.lg})`,
  // large
  lg: `@media screen and (min-width: ${screen.lg})`,
};

export const colors = {
  // primary colors
  black: '#111',
  gray: '#384754',
  darkGray: '#263442',
  red: '#EE3900',
  darkNavy: '#131D29',

  // most used brown colors
  primary: PRIMARY_COLOR,
  secondary: SECONDARY_COLOR,

  // light colors
  white: '#fff',
  nearWhite: '#F5F2EF',
  lightTan: '#D8CEC4',

  brown: PRIMARY_COLOR,
  darkBrown: '#301C0E',
  mediumBrown: '#614226',
  mediumLightBrown: '#AC8058',

  // TODO: Verify these once designs are complete
  // browns - order lightest to darkest
  brown1: SECONDARY_COLOR, // '#D1A886'
  brown2: '#C4956A',
  brown3: '#AC8058',
  brown4: '#946C48',
  brown5: PRIMARY_COLOR, // '#84603F'
  brown6: '#714D2D',
  brown7: '#614226',
  brown8: '#492F1A',
  brown9: '#301C0E',

  // greens
  green: '#99D78F',
  // darkGreen: '#1D4649',
};

const sizes = {
  f1: `
    font-size: 34px;
  `,
  f2: `
    font-size: 30px;
    line-height: 40px;
  `,
  f3: `
    font-size: 18px;
    line-height: 28px;
  `,
  p: `
    font-size: 16px;
    line-height: 24px;
  `,
  smallP: `
    font-size: 14px;
  `,
  smallestP: `
    font-size: 12px;
  `,
};

const theme = {
  colors,

  fonts: {
    lora: 'Lora',
    montserrat: 'Montserrat',
    serif: 'Lora',
    sansSerif: 'Montserrat',
  },

  sizes,

  shadow1: `box-shadow: 0px 4px 4px rgba(73, 47, 26, 0.05);`,
};

export default theme;
