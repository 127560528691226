import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from 'components/Button';
import Input from 'components/Input';
import Layout from 'components/Layout';
import Text from 'components/Text';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

const Content = styled.div`
  width: 346px;
`;

function ForgotPassword() {
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    http
      .post(endpoints.resetPassword, { email })
      .then(res => {
        // Don't do anything
      })
      .catch(err => sendError('Error resetting password', err))
      .finally(() => {
        setLoading(false);
        setSuccess(true);
      });
  }

  const title = success ? `It's on the way!` : 'Forgot Password';

  if (success) {
    return (
      <Layout title={title}>
        <Container>
          <Content>
            {success && (
              <Text font="serif" style={{ textAlign: 'center' }}>
                If you have a valid account, an email with instructions for
                resetting your password will be sent to the email address on
                file.
              </Text>
            )}
          </Content>
        </Container>
      </Layout>
    );
  }
  return (
    <Layout title={title}>
      <Container>
        <Content>
          <Text font="serif" style={{ textAlign: 'center' }}>
            Enter your email below
          </Text>
          <form onSubmit={handleSubmit} style={{ marginTop: 40 }}>
            <Input
              hideRequired
              name="Email"
              label="Email"
              type="email"
              required
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <Button
              disabled={loading}
              loading={loading}
              style={{ marginTop: 20 }}
            >
              Submit
            </Button>
          </form>
        </Content>
      </Container>
    </Layout>
  );
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
