import * as React from 'react';
// import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Button from 'components/Button';
import Card from '../Card';
import Input from 'components/Input';
import ProviderInputs from 'pages/join/pages/join-index/JoinForm/ProviderInputs';
import StudentInputs from 'pages/join/pages/join-index/JoinForm/StudentInputs';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';
import { updateData } from 'reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import useToast from 'hooks/useToast';

function YourSettings() {
  const dispatch = useDispatch();
  const { createToast } = useToast();
  const [loading, setLoading] = React.useState(false);
  const user = useSelector(({ auth }) => auth.data);
  const isStudent = user.occupation === 'student';
  const isProvider = user.occupation === 'provider';
  const [noNpi, setNoNpi] = React.useState(!user?.provider?.npi_number);
  const defaultValues = getDefaults();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    register,
    unregister,
  } = useForm({ defaultValues });

  function getDefaults() {
    const defaultValues = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      title: user.title,
    };

    if (isStudent) {
      defaultValues.school = user.student.school;
      defaultValues.gradDate = new Date(user.student.graduation_date);
      defaultValues.program = user.student.program;
    }

    if (isProvider) {
      defaultValues.credentials = user.provider.credentials;
      defaultValues.certificationDate = new Date(
        user.provider.certification_date
      );
      defaultValues.country = user.provider.country_of_operation;
      defaultValues.licenseType = user.provider.identifier_type;
      defaultValues.licenseNumber = user.provider.id_number;
      defaultValues.npiNumber = user.provider.npi_number;
      defaultValues.region = user.provider.region;
    }

    return defaultValues;
  }

  function onSubmit(data) {
    setLoading(true);
    const defaultPayload = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    };

    const provider = {
      credentials: data.credentials,
      certification_date: dayjs(data.certificationDate).format('YYYY-MM-DD'),
      country_of_operation: data.country,
      npi_number: data.npiNumber || '',
      identifier_type: data.licenseType || '',
      id_number: data.licenseNumber || '',
    };

    const student = {
      school: data?.school,
      graduation_date: data?.gradDate,
      program: data?.program,
    };

    const payload = isProvider
      ? { ...defaultPayload, ...provider }
      : { ...defaultPayload, ...student };

    http
      .patch(endpoints.account, payload)
      .then(({ data }) => {
        dispatch(updateData(data.data));
        createToast({ message: 'Account details saved successfully.' });
      })
      .catch(err => {
        sendError('Error saving user account details', err);
        createToast({
          type: 'error',
          message: 'Unable to save account details at this time.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const required = 'This field is required';

  return (
    <Card as="form" title="Your Settings" onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors?.first_name?.message}
        label="First Name"
        required
        wrapperStyle={{ paddingTop: 4 }}
        {...register('first_name', { required })}
      />
      <Input
        error={errors?.last_name?.message}
        required
        label="Last Name"
        {...register('last_name', { required })}
      />
      <Input
        error={errors?.email?.message}
        label="Email"
        required
        {...register('email', { required })}
      />
      <Input
        error={errors?.title?.message}
        label="Title"
        required
        {...register('title', { required })}
      />
      {isProvider && (
        <ProviderInputs
          control={control}
          errors={errors}
          register={register}
          required={required}
          isProvider={isProvider}
          noNpi={noNpi}
          setNoNpi={setNoNpi}
          unregister={unregister}
        />
      )}
      {isStudent && (
        <StudentInputs
          control={control}
          errors={errors}
          register={register}
          required={required}
          unregister={unregister}
        />
      )}
      {isDirty && (
        <Button disabled={loading} loading={loading} small type="submit">
          Save Changes
        </Button>
      )}
    </Card>
  );
}

YourSettings.propTypes = {};

YourSettings.defaultProps = {};

export default YourSettings;
