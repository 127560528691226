import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import EmptyListMessage from 'pages/admin/components/EmptyListMessage';
import Link from 'components/Link';
import SearchResultItem from 'components/SearchResultItem';
import Text from 'components/Text';

import { media } from 'styles/theme';
import routes from 'utils/routes';

const Wrapper = styled.div`
  grid-area: results;
  margin: 0 15px;
  ${media.ns} {
    margin: 0 20px;
  }
`;

const ResultsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledSearchResultItem = styled(SearchResultItem)`
  width: 100%;
  ${media.ns} {
    width: initial;
  }
`;

function Results({ children, loading, results, termObj }) {
  const text = (
    <Text as="span" styled={{ fontWeight: 500 }}>
      Sorry! It seems that we don't have any images that fit this criteria right
      now. Please check back soon as our database is constantly expanding. If
      you find an image that we could use, please share it with us{' '}
      <Link to={routes.imageUpload}>here</Link>.
    </Text>
  );
  return (
    <Wrapper>
      <ResultsList>
        {results?.length === 0 && (
          <EmptyListMessage text={text} style={{ maxWidth: 550 }} />
        )}
        {results?.map(result => (
          <StyledSearchResultItem
            data-tour="search-result-item"
            conditions={result.conditions}
            id={result.id}
            key={result.id}
            photo_url={result.photo_url}
            symptoms={result.symptoms}
          />
        ))}
      </ResultsList>
      {children}
    </Wrapper>
  );
}

Results.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  results: PropTypes.array,
  termObj: PropTypes.any,
};

Results.defaultProps = {
  results: [],
  termObj: null,
};

export default Results;
