import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Link as ReachLink } from 'utils/router';
import Logo from 'components/Logo';
import { StyledHeader } from 'components/Header';

const StyledJoinHeader = styled(StyledHeader)`
  border-bottom: none;
  justify-content: center;
`;

function JoinHeader() {
  return (
    <StyledJoinHeader>
      <ReachLink to="/" style={{ display: 'flex' }}>
        <Logo height={36} />
      </ReachLink>
    </StyledJoinHeader>
  );
}

JoinHeader.propTypes = {};

JoinHeader.defaultProps = {};

export default JoinHeader;
