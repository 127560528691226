import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Arrow from './Arrow';
import Link from 'components/Link';

const StyledBackLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 10px;
  font-weight: 700;
`;

function BackLink({ children, ...rest }) {
  return (
    <StyledBackLink {...rest}>
      <Arrow height={8} style={{ marginRight: 6 }} />
      {children}
    </StyledBackLink>
  );
}

BackLink.propTypes = {
  children: PropTypes.any.isRequired,
};

BackLink.defaultProps = {};

export default BackLink;
