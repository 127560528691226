import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledName = styled.span``;

function Name({ name, searchTerm }) {
  const lname = name.toLowerCase();
  const lsearchTerm = searchTerm.toLowerCase();
  const match = lname.match(lsearchTerm);
  const matchChars = match[0];
  const matchIndex = match.index;
  const first = name.slice(0, matchIndex);
  const bolded = name.slice(matchIndex, matchChars.length + matchIndex);
  const last = name.slice(matchIndex + matchChars.length);
  return (
    <StyledName>
      {first}
      <strong>{bolded}</strong>
      {last}
    </StyledName>
  );
}

Name.propTypes = {
  name: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

Name.defaultProps = {};

export default Name;
