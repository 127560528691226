import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import routes from 'utils/routes';

import BackLink from 'components/BackLink';
import Text from 'components/Text';

export const StyledBreadcrumbHeader = styled.div`
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightTan};
  box-sizing: border-box;
  display: flex;
  height: 100px;
  justify-content: space-between;
  margin: -56px -20px 0;
  padding: 25px 46px 25px;
`;

const Left = styled.div``;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-size: 22px;
  font-weight: 600;
  margin: 6px 0 0;
`;

const Right = styled.div`
  display: flex;
`;

function BreadcrumbHeader({ actionButtons, backTo, loading, title }) {
  return (
    <StyledBreadcrumbHeader>
      <Left>
        <BackLink to={backTo || routes.admin}>Back to Dashboard</BackLink>
        <Title>{title}</Title>
      </Left>
      <Right>{actionButtons}</Right>
    </StyledBreadcrumbHeader>
  );
}

BreadcrumbHeader.propTypes = {
  actionButtons: PropTypes.any,
  backTo: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,
  title: PropTypes.any,
};

BreadcrumbHeader.defaultProps = {
  actionButtons: null,
  backTo: null,
  children: null,
  loading: false,
  title: null,
};

export default BreadcrumbHeader;
