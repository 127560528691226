import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { animated, useTransition } from 'react-spring';

const Overlay = styled(animated.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(19, 29, 41, 0.8);
  top: 0;
  left: 0;
  z-index: 5;
`;

const StyledModal = styled(animated.div)`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

function Modal({ children, overlayColor, showModal, style, ...rest }) {
  const [isOpen, setIsOpen] = React.useState(showModal);

  function getStartColor(color) {
    const splitColor = color.split(',');
    const withTransparency = splitColor.slice(0, -1).concat([' 0)']).join(',');
    return withTransparency;
  }

  const startColor = getStartColor(overlayColor);

  const transitions = useTransition(isOpen, {
    from: {
      backgroundColor: startColor,
      opacity: 0,
    },
    enter: {
      backgroundColor: overlayColor,
      opacity: 1,
    },
    leave: {
      backgroundColor: startColor,
      opacity: 0,
    },
  });

  React.useEffect(() => {
    setIsOpen(showModal);
    if (showModal) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.left = '0px';
      document.body.style.right = '0px';
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.left = '';
      document.body.style.right = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [showModal]);

  return transitions(
    (styles, item) =>
      item && (
        <Overlay
          style={{
            backgroundColor: styles.backgroundColor,
          }}
        >
          <StyledModal
            style={{
              opacity: styles.opacity,
              ...style,
            }}
            {...rest}
          >
            {children}
          </StyledModal>
        </Overlay>
      )
  );
}

Modal.propTypes = {
  overlayColor: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

Modal.defaultProps = {
  overlayColor: 'rgba(19, 29, 41, .8)',
  style: {},
};

export default Modal;
