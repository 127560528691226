import React from 'react';
import { Redirect, Router } from 'utils/router';
import styled from 'styled-components/macro';
import axios from 'axios';

import AdminRoute from 'components/AdminRoute';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import CookieConsent from 'components/CookieConsent';
import { HelmetProvider } from 'react-helmet-async';
import NotFound from 'components/NotFound';
import ToastList from 'components/ToastList';
import { TourProvider } from '@reactour/tour';

// Pages
import Admin from './pages/admin';
import Faq from 'pages/faq';
import ForgotPassword from 'pages/forgot-password';
import Home from 'pages/home';
import ImageUpload from 'pages/image-upload';
import Invitations from 'pages/invitations';
import Join from 'pages/join';
import Login from 'pages/login';
import Logout from 'pages/logout';
import RefreshToken from 'pages/refresh-token';
import Resources from 'pages/resources';
import ResetPassword from 'pages/reset-password';
import SearchResults from 'pages/search-results';
import Settings from 'pages/settings';
import Subscription from 'pages/subscription';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useSelector } from 'react-redux';

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledRouter = styled(Router)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

function App() {
  const token = useSelector(state => state.auth?.data?.access_token);
  // MTS - Set token if a user reloads the page
  if (token) {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
  }

  React.useEffect(() => {
    // Netlify redirects are not working so this is what we have to do.
    if (window.location.hostname === 'join.mmatlas.com') {
      window.location = 'https://app.mmatlas.com/join';
    }
  }, []);

  function isSafari() {
    const ua = window?.navigator?.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    return iOS && webkit && !ua.match(/CriOS/i);
  }

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);
  const isMobileSafari = isSafari();

  return (
    <TourProvider
      afterOpen={isMobileSafari ? () => null : disableBody}
      beforeClose={isMobileSafari ? () => null : enableBody}
      steps={[]}
      showNavigation={false}
      showCloseButton={false}
      showBadge={false}
      scrollSmooth
      rounded={5}
      styles={{
        maskWrapper: base => ({
          ...base,
          color: '#301C0E',
          opacity: 0.5,
          zIndex: 3,
        }),
        popover: base => ({
          ...base,
          borderRadius: '5px',
          border: '1px solid #D8CEC4',
          padding: 0,
        }),
      }}
    >
      <HelmetProvider>
        <StyledApp>
          <ToastList />
          <CookieConsent />
          <StyledRouter>
            <Login path="/login" />
            <Logout path="/logout" />
            <Invitations path="/invitations/*" />
            <Join path="/join/*" />
            <ForgotPassword path="/forgot-password" />
            <ResetPassword path="/reset_password" />
            <RefreshToken path="/refresh-token" />
            <ImageUpload path="/image-upload/*" />
            <Faq path="/faq" />
            <Subscription path="/subscription" />

            {/* Authenticated User Routes */}
            <AuthenticatedRoute as={Home} path="/" needsSubscription />
            <AuthenticatedRoute as={Settings} path="/settings" />
            <AuthenticatedRoute as={Resources} path="/resources" />
            <AuthenticatedRoute as={SearchResults} path="/search-results/*" />

            {/* Authenticated Admin Routes */}
            <AdminRoute as={Admin} path="/admin/*" />
            <Redirect from="/help" to="/faq" />

            <NotFound default />
          </StyledRouter>
        </StyledApp>
      </HelmetProvider>
    </TourProvider>
  );
}

export default App;
