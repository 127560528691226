import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Close from './Close';

import { media } from 'styles/theme';

const HEIGHT = 22;

const StyledFilterButton = styled.button`
  align-items: center;
  border: none;
  background-color: ${({ isSelected, theme }) =>
    theme.colors[isSelected ? 'mediumBrown' : 'lightTan']};
  border-radius: ${HEIGHT / 2}px;
  color: ${({ isSelected, theme }) =>
    theme.colors[isSelected ? 'white' : 'darkBrown']};
  cursor: pointer;
  display: flex;
  height: ${HEIGHT}px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  margin: 5px 10px 5px 0;
  outline: 0;
  padding-left: 8px;
  padding-right: 8px;
  ${({ withCloseButton }) => withCloseButton && `padding-right: 4px;`}
  ${media.ns} {
    margin: 4px 8px 4px 0;
  }
`;

function FilterButton({ children, withCloseButton, ...rest }) {
  return (
    <StyledFilterButton withCloseButton={withCloseButton} {...rest}>
      {children}
      {withCloseButton && <Close height={12} style={{ marginLeft: 4 }} />}
    </StyledFilterButton>
  );
}

FilterButton.propTypes = {
  children: PropTypes.any,
  withCloseButton: PropTypes.bool,
};

FilterButton.defaultProps = {
  children: null,
  withCloseButton: false,
};

export default FilterButton;
