import * as React from 'react';
import PropTypes from 'prop-types';

import ActionButtons from './ActionButtons';
import BreadcrumbHeader from 'pages/admin/components/BreadcrumbHeader';
import ImageForm from '../ImageForm';
import PageLoading from 'components/PageLoading';

import { addToast } from 'reducers/toasts';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import useImageForm from '../ImageForm/useImageForm';
import useImagePreview from '../ImageForm/useImagePreview';

import buildPayload from '../ImageForm/buildPayload';
import http from 'utils/http';
import { navigate } from 'utils/router';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';

function EditImage({ image }) {
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const {
    conditions,
    defaultValues,
    imageOptionsLoading,
    imageOptions,
    locations,
    setConditions,
    setLocations,
    setSymptoms,
    symptoms,
  } = useImageForm(image);

  const {
    getValues,
    // handleSubmit,
    formState: { errors, isDirty },
    register,
    watch,
  } = useForm({ defaultValues });
  const watchPhoto = watch('photo');
  const previewImage = useImagePreview(watchPhoto);

  React.useEffect(() => {
    if (isDirty) {
      setHasChanges(true);
    }
  }, [isDirty]);

  function handleSave() {
    setSubmitting(true);
    const values = getValues();
    const obj = {
      ...values,
      id: image.id,
      condition_ids: conditions.map(
        el => imageOptions.conditions.find(item => item.name === el)?.id
      ),
      location_ids: locations.map(
        el => imageOptions.locations.find(item => item.name === el)?.id
      ),
      timing_id:
        imageOptions.timings.find(el => el.name === values.timing)?.id || null,
      symptom_ids: symptoms.map(
        el => imageOptions.symptoms.find(item => item.name === el)?.id
      ),
    };
    const payload = buildPayload(obj);
    http(payload)
      .then(res => {
        dispatch(
          addToast({
            message: `Image details successfully changed.`,
          })
        );
        navigate(routes.allImages);
      })
      .catch(err => {
        const errorMsg = err?.response?.data?.errors[0];
        dispatch(
          addToast({
            message: errorMsg ? `Error: ${errorMsg}` : 'Error saving image.',
            type: 'error',
          })
        );
        sendError(`Error updating image details`, err);
      })
      .finally(() => setSubmitting(false));
  }

  if (imageOptionsLoading || !imageOptions) {
    return <PageLoading />;
  }

  console.log({ errors });

  return (
    <>
      <BreadcrumbHeader
        actionButtons={
          <ActionButtons
            hasChanges={hasChanges}
            loading={submitting || imageOptionsLoading}
            onSaveClick={handleSave}
            onCancelClick={() => navigate(routes.allImages)}
          />
        }
        backTo={routes.allImages}
        loading={false}
        title="Edit Image"
      />
      <ImageForm
        conditions={conditions}
        consentFileName={`Consent-${image.uploader.first_name}${image.uploader.last_name}-${image.consent_given_at}.pdf`}
        consentUrl={image.consent_document_url}
        imageOptions={imageOptions}
        locations={locations}
        photoUrl={image.photo_url}
        previewImage={previewImage}
        register={register}
        setConditions={setConditions}
        setHasChanges={setHasChanges}
        setLocations={setLocations}
        setSymptoms={setSymptoms}
        symptoms={symptoms}
      />
    </>
  );
}

EditImage.propTypes = {
  image: PropTypes.shape({
    consent_document_url: PropTypes.string,
    consent_given_at: PropTypes.string,
    denied_reason: PropTypes.string,
    id: PropTypes.string.isRequired,
    photo_url: PropTypes.string.isRequired,
    uploader: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }).isRequired,
};

EditImage.defaultProps = {};

export default EditImage;
