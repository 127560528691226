import * as React from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import HeaderLink from './HeaderLink';
import routes from 'utils/routes';

function LogoutLink() {
  const loggedIn = useSelector(({ auth }) => !!auth.data.access_token);
  if (!loggedIn) {
    return null;
  }
  return (
    <HeaderLink active={false} to={routes.logout}>
      Log Out
    </HeaderLink>
  );
}

LogoutLink.propTypes = {};

LogoutLink.defaultProps = {};

export default LogoutLink;
