import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from 'components/Text';

import CloseIcon from './CloseIcon';

const Close = styled.button`
  background-color: transparent;
  border: none;
  padding: 0.25rem 0.5rem 0 0;
  color: ${({ theme }) => theme.colors.mediumLightBrown};
  cursor: pointer;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Title = styled(Text)`
  font-weight: 600;
  margin-bottom: 0.25rem;
  margin-top: 0.125rem;
  line-height: 20px;
`;

const StyledContent = styled.aside`
  padding: 1rem;
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}px;`}
`;

const TextContent = styled(Text)`
  line-height: 19px;
  margin-bottom: 0.5rem;
`;

function Content({
  contentElement,
  maxWidth,
  setIsOpen,
  text,
  title,
  ...rest
}) {
  return (
    <StyledContent $maxWidth={maxWidth || undefined}>
      <Title color="primary" size="smallP">
        {title}
      </Title>
      <TextContent as={contentElement} color="darkBrown" size="smallestP">
        {text}
      </TextContent>
      <Close aria-label="close" onClick={() => setIsOpen(false)}>
        <CloseIcon /> Close
      </Close>
    </StyledContent>
  );
}

Content.propTypes = {
  contentElement: PropTypes.any,
  maxWidth: PropTypes.number,
  setIsOpen: PropTypes.func.isRequired,
  text: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
};

Content.defaultProps = {
  contentElement: 'p',
};

export default Content;
