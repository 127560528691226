import React from 'react';
import endpoints from 'utils/endpoints';
import http from 'utils/http';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  address: '',
  photo: null,
  photo_credit: '',
  condition_ids: [],
  symptom_ids: [],
  location_ids: [],
  duration: '', //string
  timing_id: '', //string
  modifying_factors: '', //string
  pain_quality: '', //string
  severity: 0, //int
  case_summary: '', //string
  // credential_titles: '', // string,
  // npi_number: '', //string
  esignature: '', // string
  userState: '',
};

function useUpload() {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [uploadData, setUploadData] = React.useState(initialState);

  function submitForm(newData) {
    setLoading(true);
    setError(null);
    let formData = new FormData();
    const filteredData = { ...newData, state: newData.userState };
    delete filteredData.userState;
    // MTS - Multipart form code can be a little complicated when sending
    // arrays.
    for (const key in filteredData) {
      if (key === 'photo') {
        formData.set('photo', filteredData[key][0]);
      } else {
        if (filteredData[key] instanceof Array) {
          const arr = filteredData[key];
          for (const val of arr) {
            formData.append(`${key}[]`, val);
          }
        } else {
          formData.set(key, filteredData[key]);
        }
      }
    }

    const config = {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url: endpoints.image,
    };

    http(config)
      .then(res => {
        setSuccess(true);
        setUploadData(initialState);
      })
      .catch(err => {
        console.error(err);
        setError(
          'There was an error submitting your image.',
          JSON.stringify(err)
        );
      })
      .finally(() => setLoading(false));
  }

  return {
    error,
    loading,
    uploadData,
    setSuccess,
    setUploadData,
    submitForm,
    success,
  };
}

export default useUpload;
