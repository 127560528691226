import * as React from 'react';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';

function useSubscriptionPrice() {
  const [loading, setLoading] = React.useState(true);
  const [price, setPrice] = React.useState(0);
  const [interval, setInterval] = React.useState('monthly');
  const [noSubscription, setNoSubscription] = React.useState(false);
  const [canManageSubscription, setCanManageSubscription] = React.useState(
    false
  );

  React.useEffect(() => {
    setLoading(true);
    const config = {
      headers: {
        'mmatlas-API-Version': 1,
      },
      method: 'get',
      url: endpoints.subscription,
    };
    http(config)
      .then(({ data: { data, policies }}) => {
        setCanManageSubscription(
          Boolean(policies?.subscription?.manage_subscription)
        );
        const hasSubscription = Boolean(data?.status);
        if (!hasSubscription) {
          setNoSubscription(true);
        } else {
          const cost = data?.total_amount_in_cents;
          if (cost && cost > 0) {
            setPrice(cost / 100);
          }
          if (data?.plan_interval) {
            setInterval(data?.plan_interval);
          }
        }
      })
      .catch(err => {
        sendError('Error fetching user subscription', err);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return { canManageSubscription, loading, price, interval, noSubscription };
}

export default useSubscriptionPrice;
