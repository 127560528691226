import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ArrowButton from './ArrowButton';
import PageButton from './PageButton';
import Text from 'components/Text';

import paginationText from './paginationText';
import { media } from 'styles/theme';

const ButtonArea = styled.div`
  display: flex;
`;

const StyledPagination = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightTan};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 22px;
  ${media.ns} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

function Pagination({ limit, page, setPage, total }) {
  const numberOfPages = Math.ceil(total / limit);
  const buttons = Array(numberOfPages).fill('');
  const showArrows = numberOfPages > 4;

  if (numberOfPages <= 1) {
    return null;
  }

  function handleArrow(dir) {
    const nextPage = page + dir;
    if (nextPage === 0) {
      return;
    }
    if (nextPage > numberOfPages) {
      return;
    }
    setPage(nextPage);
  }

  const text = paginationText({ limit, page, total });
  return (
    <StyledPagination>
      <Text color="mediumBrown" style={{ fontStyle: 'italic', marginTop: 0 }}>
        {text}
      </Text>
      <ButtonArea>
        {showArrows && (
          <ArrowButton disabled={page === 1} onClick={() => handleArrow(-1)} />
        )}
        {buttons.map((el, i) => (
          <PageButton
            disabled={i + 1 === page}
            key={i}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </PageButton>
        ))}
        {showArrows && (
          <ArrowButton
            disabled={page === numberOfPages}
            onClick={() => handleArrow(1)}
            right
          />
        )}
      </ButtonArea>
    </StyledPagination>
  );
}

Pagination.propTypes = {
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

Pagination.defaultProps = {};

export default Pagination;
