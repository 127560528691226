import * as React from 'react';
import PropTypes from 'prop-types';

function Icon({ type, ...rest }) {
  function getIcon() {
    if (type === 'acc') {
      return (
        <path d="M12 0l-8 10h16l-8-10zm3.839 16l-3.839 4.798-3.839-4.798h7.678zm4.161-2h-16l8 10 8-10z" />
      );
    }
    return (
      <path d="M12 3.202l3.839 4.798h-7.678l3.839-4.798zm0-3.202l-8 10h16l-8-10zm8 14h-16l8 10 8-10z" />
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
      {getIcon()}
    </svg>
  );
}

Icon.propTypes = {
  type: PropTypes.oneOf(['acc', 'desc']),
};

Icon.defaultProps = {
  type: 'acc',
};

export default Icon;
