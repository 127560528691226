import * as React from 'react';
import PropTypes from 'prop-types';

function Arrow({ fill, ...rest }) {
  return (
    <svg
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41315 0.68927L8.82715 2.10348L3.03514 7.89638L8.82715 13.6893L7.41315 15.1035L0.207148 7.89638L7.41315 0.68927Z"
        fill={fill}
      />
    </svg>
  );
}

Arrow.propTypes = {
  fill: PropTypes.string.isRequired,
};

Arrow.defaultProps = {
  fill: '#fff',
};

export default Arrow;
