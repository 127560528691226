import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Checkbox from 'components/Checkbox';
import MultiCheckbox from 'components/MultiCheckbox';

const CheckboxArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

function CheckboxGroup({ handleChange, label, options, selectedValues }) {
  return (
    <MultiCheckbox label={label} required wrapperStyle={{ marginBottom: 20 }}>
      <CheckboxArea>
        {options.map(option => (
          <Checkbox
            key={option.id}
            checked={!!selectedValues.find(el => el === option.name)}
            label={option.name}
            onChange={() => handleChange(option.name)}
          />
        ))}
      </CheckboxArea>
    </MultiCheckbox>
  );
}

CheckboxGroup.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
};

CheckboxGroup.defaultProps = {
  selectedValues: [],
};

export default CheckboxGroup;
