import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Text from 'components/Text';

const StyledSubscriptionStatus = styled.div`
  display: flex;
  align-items: center;
`;

const StatusPill = styled.span`
  align-items: center;
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
  border-radius: 11px;
  color: ${({ $color, theme }) => theme.colors[$color]};
  display: inline-flex;
  height: 22px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  padding: 0 8px;
`;

function SubscriptionStatus({ status, ...rest }) {
  if (!status) {
    return null;
  }

  function getPill() {
    switch (status) {
      case 'active':
        return {
          color: 'darkBrown',
          backgroundColor: 'lightTan',
          text: 'Active',
        };
      case 'past_due':
        return { color: 'white', backgroundColor: 'red', text: 'Past Due' };
      case 'canceled':
        return { color: 'white', backgroundColor: 'red', text: 'Canceled' };
      case 'no_subscription':
        return {
          color: 'white',
          backgroundColor: 'red',
          text: 'No Subscription',
        };
      default:
        return null;
    }
  }

  const pill = getPill();

  // MTS - Don't show unhandled status
  if (!pill) {
    return null;
  }

  return (
    <StyledSubscriptionStatus {...rest}>
      <Text color="darkBrown" size="smallP" style={{ fontWeight: 600 }}>
        Status:
      </Text>
      <StatusPill $backgroundColor={pill.backgroundColor} $color={pill.color}>
        {pill.text}
      </StatusPill>
    </StyledSubscriptionStatus>
  );
}

SubscriptionStatus.propTypes = {
  status: PropTypes.oneOf([
    'active',
    'canceled',
    'no_subscription',
    'past_due',
  ]),
};

SubscriptionStatus.defaultProps = {};

export default SubscriptionStatus;
