import * as React from 'react';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';

function useSubscriptions() {
  const [loading, setLoading] = React.useState(true);
  const [subscriptions, setSubscriptions] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    http
      .get(endpoints.subscriptions)
      .then(({ data }) => {
        setSubscriptions(data.data);
      })
      .catch(err => sendError('Error retrieving subscriptions', { err }))
      .finally(() => setLoading(false));
  }, []);

  return { subscriptions, subscriptionsLoading: loading };
}

export default useSubscriptions;
