import * as React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Text from 'components/Text';

import nekishaProfile from './nekisha-profile.png';

const IMAGE_SIZE = 50;

const StyledImage = styled.img`
  border-radius: ${IMAGE_SIZE / 2}px;
  height: ${IMAGE_SIZE}px;
  object-fit: cover;
  margin-right: 12px;
  width: ${IMAGE_SIZE}px;
`;

const StyledTop = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.nearWhite};
  display: flex;
  padding: 13px 20px;
`;

const StyledText = styled(Text)`
  line-height: 15px;
  margin: 0;
`;

function Top() {
  return (
    <StyledTop>
      <StyledImage alt="Nekisha Profile" src={nekishaProfile} />
      <StyledText
        color="mediumBrown"
        size="smallestP"
        style={{ fontWeight: 600 }}
      >
        Hi, it's Nekisha 👋🏾
        <br /> Learn more with the guided tours below.
      </StyledText>
    </StyledTop>
  );
}

Top.propTypes = {};

Top.defaultProps = {};

export default Top;
