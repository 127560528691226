import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Arrow from '../../Arrow';
import FilterButton from '../../FilterButton';

const Description = styled.div`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 14px;
`;

const StyledFilterGroup = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightTan};
  padding-bottom: ${({ $open }) => ($open ? 20 : 12)}px;
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ $open }) => !$open && 'display: none;'}
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-size: 12px;
  font-weight: 700;
  flex: 1;
`;

const Count = styled.span`
  color: ${({ theme }) => theme.colors.mediumBrown};
  font-style: italic;
  font-weight: 400;
  ${({ $hide }) => $hide && `display: none;`}
`;

const TitleRow = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

function FilterGroup({
  availableFilters,
  description,
  open,
  selectedFilters,
  title,
  toggleOpen,
  toggleFilter,
}) {
  const count = selectedFilters.reduce((acc, el) => {
    if (availableFilters.find(filter => filter.id === el.id)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <StyledFilterGroup $open={open}>
      <TitleRow onClick={toggleOpen}>
        <Title>
          {title} <Count $hide={count === 0}>({count})</Count>
        </Title>
        <Arrow
          height={7}
          style={{ transform: `rotate(${open ? 0 : 180}deg)` }}
        />
      </TitleRow>
      <Filters $open={open}>
        {description && <Description>{description}</Description>}
        {availableFilters.map(el => (
          <FilterButton
            key={el.id}
            isSelected={selectedFilters.find(filter => filter.id === el.id)}
            onClick={() => toggleFilter(el)}
          >
            {el.name}
          </FilterButton>
        ))}
      </Filters>
    </StyledFilterGroup>
  );
}

FilterGroup.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  description: PropTypes.any,
  open: PropTypes.bool.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
};

FilterGroup.defaultProps = {
  description: null,
};

export default FilterGroup;
