import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: {
    access_token: '',
    expires_at: '',
    first_name: '',
    id: '',
    last_name: '',
    refresh_token: '',
    title: '',
  },
  meta: {},
  policies: {},
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSuccess: (state, authObj) => {
      const { access_token } = authObj.payload.data;
      // Add token to default header in all requests
      axios.defaults.headers['Authorization'] = `Bearer ${access_token}`;
      return { ...state, ...authObj.payload };
    },
    logout: () => {
      // Reset the header
      axios.defaults.headers['Authorization'] = '';
      return initialState;
    },
    refreshToken: (state, tokenObj) => {
      const { access_token, expires_at, refresh_token } = tokenObj?.payload;
      axios.defaults.headers['Authorization'] = `Bearer ${access_token}`;
      return {
        ...state,
        data: {
          ...state.data,
          access_token,
          expires_at,
          refresh_token,
        },
      };
    },
    updateData: (state, action) => {
      const newData = { ...state.data, ...action.payload };
      return { ...state, data: newData };
    },
    updatePolicies: (state, action) => {
      return { ...state, policies: action.payload };
    },
    updateSearchOptions: (state, action) => {
      const newMeta = { ...state.meta, search_options: action.payload };
      return { ...state, meta: newMeta };
    },
  },
});

export const {
  authSuccess,
  logout,
  refreshToken,
  updateData,
  updatePolicies,
  updateSearchOptions,
} = auth.actions;

export default auth.reducer;
