import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'utils/router';

import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import MultiCheckbox from 'components/MultiCheckbox';
import Select from 'components/Select';
import Text from 'components/Text';

import Form from '../../components/Form';
import NavButtons from '../../components/NavButtons';
import PageWrapper from '../../components/PageWrapper';

import useScrollTop from 'hooks/useScrollTop';

function ImageUploadImageDetails({
  conditions,
  duration,
  locations,
  setUploadData,
  timings,
  uploadData,
}) {
  useScrollTop();
  const [multiError, setMultiError] = React.useState(null);
  const [modifyingFactors, setModifyingFactors] = React.useState('');
  const [selectedConditions, setSelectedConditions] = React.useState([]);
  const [selectedLocations, setSelectedLocations] = React.useState([]);
  const [selectedDuration, setSelectedDuration] = React.useState('');
  const [selectedTiming, setSelectedTiming] = React.useState('');

  // Set previously selected answers
  React.useEffect(() => {
    const { condition_ids, location_ids } = uploadData;
    if (condition_ids.length) {
      setSelectedConditions(
        conditions.filter(el => condition_ids.find(con => con === el.id))
      );
    }
    if (location_ids.length) {
      setSelectedLocations(
        locations.filter(el =>
          location_ids.find(location => location === el.id)
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    setMultiError(null);

    // Check multicheckbox values
    if (!isValid()) {
      return;
    }

    const data = {
      condition_ids: selectedConditions.map(el => el.id),
      duration: selectedDuration || duration[0].id,
      location_ids: selectedLocations.map(el => el.id),
      modifying_factors: modifyingFactors,
      timing_id: selectedTiming || timings[0].id,
    };
    setUploadData({ ...uploadData, ...data });
    navigate('/image-upload/patient-summary');
  }

  function handleCondition(condition) {
    const alreadyExists = !!selectedConditions.find(
      el => el.id === condition.id
    );
    if (alreadyExists) {
      return setSelectedConditions(
        selectedConditions.filter(el => el.id !== condition.id)
      );
    }
    setSelectedConditions(selectedConditions.concat([condition]));
  }

  function handleLocation(location) {
    const alreadyExists = !!selectedLocations.find(el => el.id === location.id);
    if (alreadyExists) {
      return setSelectedLocations(
        selectedLocations.filter(el => el.id !== location.id)
      );
    }
    setSelectedLocations(selectedLocations.concat([location]));
  }

  const text = {
    conditionDesc: `
          To the best of your ability, please name the condition that is
          pictured. Check all that apply.
    `,
    locationDesc: `
      To the best of your ability, please indicate the location of the condition that is pictured. Check all that apply.
    `,
  };

  function isValid() {
    if (selectedConditions.length === 0) {
      setMultiError('Please select at least one condition.');
      return false;
    }
    if (selectedLocations.length === 0) {
      setMultiError('Please select at least one location.');
      return false;
    }
    return true;
  }

  return (
    <PageWrapper title="Image Details">
      <Form onSubmit={onSubmit}>
        <MultiCheckbox
          description={text.conditionDesc}
          label="Condition(s):"
          required
          wrapperStyle={{ marginTop: 0 }}
        >
          {conditions.map(condition => (
            <Checkbox
              checked={!!selectedConditions.find(el => el.id === condition.id)}
              key={condition.id}
              label={condition.name}
              onChange={() => handleCondition(condition)}
            />
          ))}
        </MultiCheckbox>

        <MultiCheckbox
          description={text.locationDesc}
          label="Location(s):"
          required
        >
          {locations.map(location => (
            <Checkbox
              checked={!!selectedLocations.find(el => el.id === location.id)}
              key={location.id}
              label={location.name}
              onChange={() => handleLocation(location)}
            />
          ))}
        </MultiCheckbox>

        <div style={{ padding: '20px 0' }}>
          <Select
            description="At the time of this image, how long had the patient been experiencing this condition?"
            label="Duration"
            onChange={e => setSelectedDuration(e.target.value)}
            value={selectedDuration}
          >
            {duration.map(el => (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            ))}
          </Select>
        </div>

        <div style={{ padding: '20px 0' }}>
          <Select
            description="When did the patient most experience pain/discomfort?"
            label="Timing"
            onChange={e => setSelectedTiming(e.target.value)}
            value={selectedTiming}
          >
            {timings.map(el => (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            ))}
          </Select>
        </div>

        <Input
          description="What did the patient attempt for relief, and did it help?"
          label="Modifying Factors"
          onChange={e => setModifyingFactors(e.target.value)}
          value={modifyingFactors}
        />

        <div style={{ paddingBottom: 20 }} />

        {multiError && <Text error>{multiError}</Text>}
        <NavButtons submitDisabled={false} />
      </Form>
    </PageWrapper>
  );
}

ImageUploadImageDetails.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  duration: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  locations: PropTypes.array,
  setUploadData: PropTypes.func.isRequired,
  timings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  uploadData: PropTypes.shape({
    condition_ids: PropTypes.array,
    location_ids: PropTypes.array,
  }),
};

ImageUploadImageDetails.defaultProps = {};

export default ImageUploadImageDetails;
