import * as React from 'react';
// import PropTypes from 'prop-types';

function Expand(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.333 1.37499L23.333 8.24537C23.333 8.45787 23.2626 8.6 23.1205 8.74076C22.9784 8.88286 22.8376 8.95325 22.6251 8.95325C22.2001 8.95325 21.9172 8.67036 21.9172 8.24537L21.9172 3.14537L15.4001 9.66249C15.1172 9.94538 14.6922 9.94538 14.408 9.66249C14.1251 9.3796 14.1251 8.95461 14.408 8.67037L20.9251 2.08287L15.8251 2.08287C15.4001 2.08287 15.1172 1.79998 15.1172 1.37499C15.1172 0.950004 15.4001 0.667114 15.8251 0.667114L22.6251 0.667114C22.6955 0.667114 22.8376 0.667115 22.908 0.737505C23.0501 0.807895 23.1909 0.950005 23.2626 1.09212C23.333 1.16251 23.333 1.30462 23.333 1.37501L23.333 1.37499ZM0.737607 22.5546L0.737607 15.6842C0.737607 15.4717 0.807998 15.3296 0.950109 15.1889C1.09222 15.0467 1.233 14.9764 1.44549 14.9764C1.87049 14.9764 2.15337 15.2592 2.15337 15.6842L2.15337 20.7842L8.67049 14.2671C8.95338 13.9842 9.37837 13.9842 9.66261 14.2671C9.9455 14.55 9.9455 14.975 9.66261 15.2592L3.14549 21.7764L8.24549 21.7764C8.67049 21.7764 8.95337 22.0592 8.95337 22.4842C8.95337 22.9092 8.67048 23.1921 8.24549 23.1921L1.37511 23.1921C1.30472 23.1921 1.16261 23.1921 1.09222 23.1217C0.950107 23.0513 0.809327 22.9092 0.737597 22.7671L0.737597 22.5546L0.737607 22.5546Z"
        fill="white"
      />
    </svg>
  );
}

Expand.propTypes = {};

Expand.defaultProps = {};

export default Expand;
