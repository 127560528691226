import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Disclaimer from 'components/Disclaimer';
import ImageLoader from 'components/ImageLoader';
import Expand from './Expand';

import useIsMobile from 'hooks/useIsMobile';

const Button = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mediumLightBrown};
  border: 0;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const ImageSection = styled.div`
  grid-area: img;
`;

function ExpandImage({ alt, src, toggleModal }) {
  const isMobile = useIsMobile();
  return (
    <ImageSection onContextMenu={e => e.preventDefault()}>
      <Wrapper>
        <ImageLoader
          alt={alt}
          src={src}
          style={{
            backgroundPosition: 'center',
            maxHeight: 600,
            minHeight: isMobile ? 'initial' : 420,
            objectFit: 'cover',
            objectPosition: 'center',
            width: '100%',
          }}
          watermark
        />
        <Button onClick={toggleModal}>
          <Expand height={24} />
        </Button>
      </Wrapper>
      <Disclaimer />
    </ImageSection>
  );
}

ExpandImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

ExpandImage.defaultProps = {};

export default ExpandImage;
