import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { navigate } from 'utils/router';
import routes from 'utils/routes';

import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';

function AuthenticatedRoute({ as: Component, needsSubscription, ...rest }) {
  const accessToken = useSelector(state => state.auth?.data?.access_token);
  const hasAccessToken = accessToken && accessToken !== '';
  const isAdmin = !!useSelector(
    state => state.auth?.policies?.user?.access_admin
  );

  const subscription = !!useSelector(
    state => state.auth?.policies?.user?.access_search
  );

  React.useEffect(() => {
    checkNavigation();
    // eslint-disable-next-line
  }, []);

  function checkNavigation() {
    if (isAdmin) {
      return navigate(routes.admin);
    }
    if (!hasAccessToken) {
      navigate(routes.login);
    }
    // route requires subscription but the user does not have the access_search
    // access.
    if (hasAccessToken && needsSubscription && !subscription) {
      return navigate(routes.subscriptionRedirect);
    }
  }

  if (!hasAccessToken || (needsSubscription && !subscription)) {
    return (
      <Layout>
        <PageLoading />
      </Layout>
    );
  }

  return <Component {...rest} />;
}

AuthenticatedRoute.propTypes = {
  as: PropTypes.any.isRequired,
  needsSubscription: PropTypes.bool,
};

AuthenticatedRoute.defaultProps = {
  needsSubscription: false,
};

export default AuthenticatedRoute;
