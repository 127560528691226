import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import AdminLinks from './AdminLinks';
import Close from './Close';
import Footer from 'components/Footer';
import Hamburger from './Hamburger';
import MobileLink from './MobileLink';
import UserLinks from './UserLinks';

import { media } from 'styles/theme';
import routes from 'utils/routes';
import { useSelector } from 'react-redux';

const IconButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 0;
  padding: 10px;
  ${media.ns} {
    display: none;
  }
`;

const StyledMobileNav = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: 2;
  ${media.ns} {
    display: none;
  }
`;

const LinkArea = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 115px;
  width: 100%;
`;

function MobileNav({ open, toggleOpen, type }) {
  const loggedIn = useSelector(({ auth }) => !!auth?.data?.access_token);

  if (!open) {
    return (
      <IconButton onClick={toggleOpen}>
        <Hamburger />
      </IconButton>
    );
  }

  const closeStyle = {
    position: 'absolute',
    top: 18,
    right: 8,
  };
  return (
    <StyledMobileNav>
      <LinkArea>
        <IconButton onClick={toggleOpen} style={closeStyle}>
          <Close />
        </IconButton>
        {type === 'admin' && <AdminLinks />}
        {type !== 'admin' && <UserLinks loggedIn={loggedIn} />}
      </LinkArea>
      {loggedIn && (
        <MobileLink to={routes.logout} style={{ marginBottom: 30 }}>
          Log Out
        </MobileLink>
      )}
      <Footer isMobile />
    </StyledMobileNav>
  );
}

MobileNav.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

MobileNav.defaultProps = {};

export default MobileNav;
