import React from 'react';
import PropTypes from 'prop-types';

import { Desc, InputWrapper, Label, Red } from 'components/Input';
import Text from 'components/Text';

function MultiCheckbox({
  children,
  description,
  error,
  label,
  required,
  wrapperStyle,
}) {
  const hasError = !!error;
  return (
    <InputWrapper wrapperStle={wrapperStyle}>
      {label && (
        <Label hasDescription={!!description}>
          {label}
          {required && <Red>*</Red>}
        </Label>
      )}
      {description && (
        <Desc font="sansSerif" size="smallP">
          {description}
        </Desc>
      )}
      {hasError && <Text error>{error}</Text>}
      {children}
    </InputWrapper>
  );
}

MultiCheckbox.propTypes = {
  children: PropTypes.any,
  description: PropTypes.any,
  error: PropTypes.string,
  label: PropTypes.any,
  required: PropTypes.bool,
  wrapperStyle: PropTypes.object,
};

MultiCheckbox.defaultProps = {
  children: null,
  description: null,
  error: '',
  label: null,
  required: false,
  wrapperStyle: {},
};

export default MultiCheckbox;
