import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';

import Button from 'components/Button';

const StyledButton = styled(Button)`
  display: inline-flex;
  margin-top: 30px;
  width: initial;
`;

const HiddenLink = styled.a`
  opactity: 0;
  visibility: hidden;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function ExportCSVButton({ exportArray, name }) {
  const linkRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  function convertToCSV(array) {
    const firstLine = 'First Name,Last Name,Email\n';
    const lines = array.reduce((acc, el) => {
      const thisLine = `${el.first_name},${el.last_name},${el.email}\n`;
      return `${acc}${thisLine}`;
    }, firstLine);
    return lines;
  }

  function downloadCSV() {
    setLoading(true);
    const date = dayjs().format('MM-DD-YYYY') || '';
    const exportedFilenmae = `${name || 'export'}-${date}.csv`;
    const csv = convertToCSV(exportArray);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (window?.navigator?.msSaveBlob) {
      // IE 10+
      window?.navigator.msSaveBlob(blob, exportedFilenmae);
      setLoading(false);
    } else {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      linkRef.current.setAttribute('href', url);
      linkRef.current.setAttribute('download', exportedFilenmae);
      linkRef.current.click();
      setLoading(false);
    }
  }

  return (
    <ButtonRow>
      <HiddenLink ref={linkRef} />
      <StyledButton loading={loading} onClick={downloadCSV}>
        Export To CSV
      </StyledButton>
    </ButtonRow>
  );
}

ExportCSVButton.propTypes = {
  exportArray: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    })
  ),
  name: PropTypes.string,
};

ExportCSVButton.defaultProps = {
  name: null,
};

export default ExportCSVButton;
