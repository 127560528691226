import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addToast } from 'reducers/toasts';

import BreadcrumbHeader from 'pages/admin/components/BreadcrumbHeader';
import PendingActionButtons from 'pages/admin/components/PendingActionButtons';
import UserForm from '../../components/UserForm';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { navigate } from 'utils/router';
import routes from 'utils/routes';
import { sendError } from 'utils/helpers';

function PendingUser({ userId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [subscriptionType, setSubscriptionType] = React.useState('standard');
  const isDenied = user?.status === 'denied';

  React.useEffect(() => {
    setLoading(true);
    http
      .get(`${endpoints.pendingUser}?id=${userId}`)
      .then(({ data }) => setUser(data.data))
      .catch(err => sendError('Error fetching user', err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  function handleApproveDeny(status, msg) {
    setLoading(true);
    let data = {
      id: userId,
      subscription_type: subscriptionType,
      status,
    };
    if (status === 'denied') {
      data.denied_reason = msg;
    }

    http
      .patch(endpoints.waitlist, data)
      .then(res => {
        dispatch(
          addToast({
            message: `User successfully ${status}.`,
          })
        );
        navigate(routes.pendingUsers);
      })
      .catch(err => {
        const errorMsg = err?.response?.data?.errors[0];
        dispatch(
          addToast({
            message: errorMsg ? `Error: ${errorMsg}` : 'Error saving image.',
            type: 'error',
          })
        );
        sendError(`Error setting status:${status} for image detail`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const subscriptionState = { subscriptionType, setSubscriptionType };

  function getTitle() {
    if (isDenied) {
      return `Denied User (${user.denied_reason})`;
    }
    return 'Pending User';
  }

  return (
    <>
      <BreadcrumbHeader
        actionButtons={
          <PendingActionButtons
            hasChanges={false}
            handleApproveDeny={handleApproveDeny}
            loading={loading}
          />
        }
        backTo={isDenied ? routes.deniedUsers : routes.pendingUsers}
        loading={loading}
        title={getTitle()}
      />
      {!loading && user && (
        <UserForm user={user} subscriptionState={subscriptionState} />
      )}
    </>
  );
}

PendingUser.propTypes = {
  userId: PropTypes.string.isRequired,
};

PendingUser.defaultProps = {};

export default PendingUser;
