export const passwordValidation = {
  pattern: {
    value: /^(?=.*\d)(?=.*[!@#$-%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/,
    message:
      'Password must have at least 10 characters, one digit, one uppercase character, one lowercase character, and one special character.',
  },
};

export const emailValidation = {
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Please enter a valid email address.',
  },
};
