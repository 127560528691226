import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CIRCLE_SIZE = 7;
const INNER_SIZE = 23;

const Circle = styled.div`
  border-radius: ${CIRCLE_SIZE / 2}px;
  background-color: ${({ theme }) => theme.colors.black};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  height: ${CIRCLE_SIZE}px;
  width: ${CIRCLE_SIZE}px;
`;

export const StyledInner = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.darkBrown};
  border-radius: ${INNER_SIZE / 2}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  height: ${INNER_SIZE}px;
  min-height: ${INNER_SIZE}px;
  opacity: 0.85;
  width: ${INNER_SIZE}px;
  min-width: ${INNER_SIZE}px;
`;

const Close = () => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.80034 0.206485C8.53413 -0.0597268 8.1041 -0.0597268 7.83788 0.206485L4.5 3.53754L1.16212 0.199659C0.895905 -0.0665529 0.46587 -0.0665529 0.199659 0.199659C-0.0665529 0.46587 -0.0665529 0.895905 0.199659 1.16212L3.53754 4.5L0.199659 7.83788C-0.0665529 8.1041 -0.0665529 8.53413 0.199659 8.80034C0.46587 9.06655 0.895905 9.06655 1.16212 8.80034L4.5 5.46246L7.83788 8.80034C8.1041 9.06655 8.53413 9.06655 8.80034 8.80034C9.06655 8.53413 9.06655 8.1041 8.80034 7.83788L5.46246 4.5L8.80034 1.16212C9.05973 0.902731 9.05973 0.46587 8.80034 0.206485Z"
      fill="white"
    />
  </svg>
);

function Inner({ open }) {
  return (
    <StyledInner>
      {!open && <Circle />}
      {open && <Close />}
    </StyledInner>
  );
}

Inner.propTypes = {
  open: PropTypes.bool.isRequired,
};

Inner.defaultProps = {};

export default Inner;
