import { createSlice } from '@reduxjs/toolkit';
import shortid from 'shortid';

const initialState = [];

const toasts = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast: (state, newToast) => {
      const { duration, message, type } = newToast.payload;
      return state.concat({
        id: shortid.generate(),
        duration: duration || 5000,
        message: message || '',
        type: type || 'default',
      });
    },
    initToasts: () => initialState,
    removeToast: (state, toastId) => {
      return state.filter(el => el.id !== toastId.payload);
    },
  },
});

export const { addToast, initToasts, removeToast } = toasts.actions;

export default toasts.reducer;
