import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import routes from 'utils/routes';

import Link from 'components/Link';

const Divider = styled.span`
  color: ${({ isMobile, theme }) => theme.colors[isMobile ? 'white' : 'brown']};
  margin: 10px;
`;

const StyledFooter = styled.footer`
  align-items: center;
  border-top: 1px solid
    ${({ isMobile, theme }) =>
      isMobile ? theme.colors.white : theme.colors.lightTan};
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
`;

function Footer({ isMobile }) {
  const linkProps = {
    color: isMobile ? 'white' : undefined,
    external: true,
    size: 'smallestP',
  };
  return (
    <StyledFooter isMobile={isMobile}>
      <Link href={routes.privacy} {...linkProps}>
        Privacy Policy
      </Link>
      <Divider isMobile={isMobile}>|</Divider>
      <Link href={routes.terms} {...linkProps}>
        Terms
      </Link>
    </StyledFooter>
  );
}

Footer.propTypes = {
  isMobile: PropTypes.bool,
};

Footer.defaultProps = {
  isMobile: false,
};

export default Footer;
