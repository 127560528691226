export const initialState = {
  conditions: false,
  symptoms: false,
  locations: false,
  painQuality: false,
  duration: false,
  timings: false,
};

function filtersReducer(state, action) {
  switch (action.type) {
    case 'toggle':
      return { ...state, [action.payload]: !state[action.payload] };
    case 'mobileToggle':
      const newState = Object.keys(state).reduce((acc, key) => {
        if (state[key]) {
          return {
            ...acc,
            [key]: false,
          };
        }
        return {
          ...acc,
          [key]: key === action.payload,
        };
      }, state);
      return newState;
    default:
      throw new Error();
  }
}

export default filtersReducer;
