import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';

import Footer from 'components/Footer';
import Header from 'components/Header';
import Text from 'components/Text';

const StyledLayout = styled.div`
  flex: 1;
`;

const Container = styled.div`
  flex: 1;
  padding: 56px 20px 30px;
`;

function Layout({ children, containerStyle, header, title, ...rest }) {
  const pageTitle = title
    ? `MMAtlas | ${title}`
    : 'The Melanated Mammary Atlas';
  return (
    <>
      <StyledLayout {...rest}>
        <Helmet title={pageTitle} />
        <Header type={header} />
        <Container style={containerStyle}>
          {title && (
            <Text
              as="h1"
              center
              color="darkBrown"
              size="h1"
              style={{ margin: 0 }}
            >
              {title}
            </Text>
          )}
          {children}
        </Container>
      </StyledLayout>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
  containerStyle: PropTypes.object,
  header: PropTypes.oneOf(['default', 'admin']),
  title: PropTypes.any,
};

Layout.defaultProps = {
  children: null,
  containerStyle: {},
  header: 'default',
  title: null,
};

export default Layout;
