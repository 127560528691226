import * as React from 'react';
// import PropTypes from 'prop-types';

function Close(props) {
  return (
    <svg
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.8475 5.84749L14 11.695L8.15251 5.84749L6.69064 7.30936L12.5381 13.1569L6.69064 19.0043L8.15251 20.4662L14 14.6187L19.8475 20.4662L21.3094 19.0043L15.4619 13.1569L21.3094 7.30936L19.8475 5.84749Z"
        fill="white"
      />
    </svg>
  );
}

Close.propTypes = {};

Close.defaultProps = {};

export default Close;
