import * as React from 'react';
// import PropTypes from 'prop-types';
import { navigate } from 'utils/router';

import Layout from 'components/Layout';
import PageLoading from 'components/PageLoading';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import routes from 'utils/routes';
import { useDispatch } from 'react-redux';
import { sendError } from 'utils/helpers';
import { updateData, updatePolicies } from 'reducers/auth';

function SubscriptionSuccess() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    // MTS - Adding a 3 second timeout here just to be sure the backend processes
    // the Stripe webhook before we refresh the permissions.
    setTimeout(() => {
      http(endpoints.account)
        .then(({ data }) => {
          dispatch(updateData(data.data));
          dispatch(updatePolicies(data.policies));
          navigate(routes.home);
        })
        .catch(err => {
          sendError('Error refreshing permissions after payment', err);
          navigate(routes.logout);
        });
    }, 3000);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <PageLoading />
    </Layout>
  );
}

SubscriptionSuccess.propTypes = {};

SubscriptionSuccess.defaultProps = {};

export default SubscriptionSuccess;
