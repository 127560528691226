import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'utils/router';

import { Helmet } from 'react-helmet-async';
import Input from 'components/Input';
import Text from 'components/Text';

import Address from './Address';
import Form from '../../components/Form';
import NavButtons from '../../components/NavButtons';
import PageWrapper from '../../components/PageWrapper';

import { useForm } from 'react-hook-form';
import useScrollTop from 'hooks/useScrollTop';

const API_KEY = process.env.REACT_APP_LOCATION_API_KEY;

function ImageUploadContact({ setUploadData, uploadData }) {
  useScrollTop();
  const [address, setAddress] = React.useState('');
  const [addressError, setAddressError] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [googleReady, setGoogleReady] = React.useState(false);

  React.useEffect(() => {
    // This checks to make sure Google maps is loaded
    const interval = setInterval(() => {
      if (!googleReady && window.google) {
        setGoogleReady(true);
        window.clearInterval(interval);
      }
    }, 300);
    // eslint-disable-next-line
  }, []);

  const defaultValues = {
    address: uploadData.address,
    email: uploadData.email,
    firstName: uploadData.first_name,
    lastName: uploadData.last_name,
  };

  const {
    handleSubmit,
    // formState: { errors },
    register,
  } = useForm({ defaultValues });

  function onSubmit(data) {
    setAddressError(null);
    if (address !== formatAddress(selectedAddress)) {
      return setAddressError(
        'Please select your address from the list of values.'
      );
    }
    const thisData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      address,
      userState: selectedAddress.administrative_area_level_1,
    };

    setUploadData({ ...uploadData, ...thisData });
    navigate('/image-upload/image');
  }

  function handleAddressSelect(address) {
    setAddressError(null);
    if (address) {
      const str = formatAddress(address);
      setAddress(str);
      setSelectedAddress(address);
    }
  }

  function formatAddress(address) {
    if (!address || !address.street_number) {
      return null;
    }
    const {
      country,
      street_number,
      route,
      locality,
      administrative_area_level_1,
      postal_code,
    } = address;
    const str = `${street_number} ${route} ${locality}, ${administrative_area_level_1} ${postal_code} ${country}`;
    return str;
  }

  function handleAddress(addr) {
    setAddress(addr);
    if (addr !== formatAddress(selectedAddress)) {
      setSelectedAddress(null);
    }
  }

  const wrapperStyle = { marginBottom: 24 };
  return (
    <PageWrapper title="Your Contact Information">
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`}
        />
      </Helmet>
      <Text font="serif">
        We are legally required to collect this information to document your
        consent. This information will not be shared or visible in the tool.
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="First Name"
          required
          wrapperStyle={wrapperStyle}
          {...register('firstName', { required: true })}
        />
        <Input
          label="Last Name"
          required
          wrapperStyle={wrapperStyle}
          {...register('lastName', { required: true })}
        />
        <Input
          label="Email"
          required
          type="email"
          wrapperStyle={wrapperStyle}
          {...register('email', { required: true })}
        />

        {googleReady && (
          <div style={{ marginBottom: 20 }}>
            <Address
              address={address}
              error={addressError}
              onChange={handleAddress}
              onSelect={handleAddressSelect}
            />
          </div>
        )}

        <NavButtons
          submitDisabled={false}
          submitText="Next"
          style={{ marginBottom: 160 }}
        />
      </Form>
    </PageWrapper>
  );
}

ImageUploadContact.propTypes = {
  setUploadData: PropTypes.func.isRequired,
  uploadData: PropTypes.any.isRequired,
};

ImageUploadContact.defaultProps = {};

export default ImageUploadContact;
