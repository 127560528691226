import React from 'react';
// import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { navigate } from 'utils/router';

import Button from 'components/Button';
import Input from 'components/Input';
import Layout from 'components/Layout';
import Link from 'components/Link';
import Text from 'components/Text';

import http from 'utils/http';
import endpoints from 'utils/endpoints';
import routes from 'utils/routes';

import { useDispatch, useSelector } from 'react-redux';
import { authSuccess } from 'reducers/auth';

const BottomLink = styled(Link)`
  font-weight: 600;
  text-decoration: underline;
`;

const Join = styled.div`
  margin-top: 32px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0px 30px 30px;
  max-width: 345px;
  width: 100%;
`;

const PasswordWrapper = styled.div`
  position: relative;
`;

const ForgotPasswordLink = styled(Link)`
  position: absolute;
  top: 7px;
  right: 0;
  line-height: 27px;
  ${({ theme }) => theme.sizes.smallestP}
`;

const StyledLogin = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  margin-top: 34px;
`;

const USERNAME = process.env.REACT_APP_EMAIL;
const PASSWORD = process.env.REACT_APP_PASSWORD;
// const USERNAME = process.env.REACT_APP_EMAIL_ADMIN;
// const PASSWORD = process.env.REACT_APP_PASSWORD_ADMIN;

function Login() {
  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [loginError, setLoginError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  React.useEffect(() => {
    if (auth?.data?.access_token) {
      nextPage(auth);
    }
    // eslint-disable-next-line
  }, []);

  function nextPage(authObj) {
    const nextPage = authObj?.policies?.user?.access_admin
      ? routes.admin
      : routes.home;
    navigate(nextPage);
  }

  function onSubmit(data) {
    setLoginError('');
    setLoading(true);
    http
      .post(endpoints.login, data)
      .then(({ data, ...rest }) => {
        dispatch(authSuccess(data));
        nextPage(data);
      })
      .catch(err => {
        setLoginError('Invalid email or password.');
      })
      .finally(() => setLoading(false));
  }

  return (
    <Layout title="Log In">
      <StyledLogin>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            autoComplete="username"
            defaultValue={USERNAME || ''}
            error={errors?.email?.message}
            hideRequired
            label="Email"
            name="email"
            required
            {...register('email', { required: true })}
          />
          <PasswordWrapper>
            <ForgotPasswordLink to="/forgot-password">
              Forgot Password?
            </ForgotPasswordLink>
            <Input
              autoComplete="current-password"
              error={errors?.password?.message}
              label="Password"
              name="password"
              defaultValue={PASSWORD || ''}
              hideRequired
              required
              type="password"
              {...register('password', { required: true })}
            />
          </PasswordWrapper>
          {loginError && <Text error>{loginError}</Text>}
          <Button loading={loading} type="submit" style={{ marginTop: 10 }}>
            Get Started
          </Button>
          <Join>
            <Text center style={{ margin: 0 }}>
              Don't have an account?
            </Text>
            <Text center style={{ margin: 0 }}>
              <BottomLink to="/join">Request to join the movement.</BottomLink>
            </Text>
          </Join>
        </Form>
      </StyledLogin>
    </Layout>
  );
}

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
