import styled from 'styled-components/macro';
import Link from 'components/Link';

const MobileLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 28px;
  font-weight: 400;
  line-height: 66px;
`;

export default MobileLink;
