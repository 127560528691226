import * as React from 'react';
import styled from 'styled-components/macro';

import HelpItem from './HelpItem';
import Layout from 'components/Layout';
import Link from 'components/Link';
import Text from 'components/Text';

import routes from 'utils/routes';

const Container = styled.div`
  margin: 28px auto 0;
  max-width: 500px;
  padding-bottom: 90px;
`;

const HelpText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.serif};
  ul {
    padding-left: 20px;
  }
  li {
    margin-bottom: 16px;
  }
`;

const Italic = styled.span`
  font-style: italic;
`;

function Faq() {
  return (
    <Layout title="FAQs">
      <Container>
        <HelpItem title="Is the MM Atlas an evidence-based resource?">
          <HelpText as="div">
            <p>
              The Melanated Mammary Atlas is an experience-based resource. While
              we absolutely value evidence-based practice, the evidence does not
              include every experience. In this tool, we will prioritize the
              experiences of those giving and receiving care in POC communities
              with the hopes of highlighting effective ways to assess conditions
              on skin of color. Because the case briefs and conditions are based
              on personal accounts, there may be some disagreement around an
              assessment or diagnosis reached. We encourage all users to refer
              to our{' '}
              <Link external href={routes.terms}>
                Terms of Use
              </Link>{' '}
              which, in part, states:
            </p>

            <p>
              “Lioness Lactation, LLC, has not independently investigated the
              photos and diagnosis supplied by such professionals, however, and
              cannot guarantee its accuracy. The information contained is not
              complete and users cannot fully rely on it for treatment or
              decision-making. Users must therefore rely on their experience and
              judgment when interpreting any of the information contained herein
              to an actual patient care situation. In addition, Lioness
              Lactation, LLC, urges users to consult additional reference
              sources and other healthcare professionals concerning treatments
              presented herein."
            </p>

            <p>
              Due to space limitations and the design of this app, it is not
              possible to present detailed explanations or to present
              alternative treatments or points of view. The reader is therefore
              cautioned to:
            </p>

            <ul>
              <li>
                Apply his or her medical judgment and experience to determine
                whether or not recommended treatments, diagnoses or actions are
                appropriate for any specific patient.
              </li>
              <li>
                Keep current with knowledge in their field of expertise so that
                he or she is aware of changes in standard treatment and actions.
              </li>
              <li>
                Use other, more detailed sources of information such as standard
                textbooks, poison control centers, consultants, etc., whenever
                he or she has any doubt whatsoever with regard to the
                recommended treatments or information or photos presented.”
              </li>
            </ul>
          </HelpText>
        </HelpItem>
        <HelpItem title="How do I search for images?">
          <HelpText>
            Once you are logged into the MM Atlas, a search bar will appear.
            Type the name of a condition or symptom into the search bar then
            click SEARCH to generate initial results. Once your results appear,
            you may filter them by any of the descriptors in the left column
            (conditions, symptoms, location, pain quality, duration, or timing)
            to further narrow your results. To perform a new search, simply type
            a new term into the search box. Terms are prefilled, and always
            expanding based on images that we receive. To get a sense of which
            terms are currently available, begin typing a condition or symptom
            in the search bar and a dropdown list of options will appear. Need
            more help? We post our most current list of search terms
            periodically on our social media. Find us on{' '}
            <Link external href="https://www.facebook.com/TheMMAtlas">
              Facebook
            </Link>{' '}
            or{' '}
            <Link external href="https://www.instagram.com/themmatlas/">
              Instagram
            </Link>
            .
          </HelpText>
        </HelpItem>
        <HelpItem title="Can I use the images for teaching?">
          <HelpText>
            Great question! In order to protect the individuals and institutions
            who own the images, we do not allow{' '}
            <Italic>
              screenshotting, downloading, or otherwise capturing images from
              the tool
            </Italic>
            . However, in most cases, image donors have provided their email
            address in the details of the image so they can be contacted
            directly for a high-resolution copy of the image without our
            watermarks. Additionally, a user with an active subscription may
            utilize the MM Atlas as a teaching aid by projecting entries from
            the tool in class during real-time instruction.
          </HelpText>
        </HelpItem>
        <HelpItem title="Why do you charge for access?">
          <HelpText>
            The MM Atlas is a 21st-century tool designed to address an age-old
            challenge. Its sophistication, complexity, and agility require a
            team of dedicated software planners, developers, and engineers to
            maintain and grow this dynamic resource. Constant expansion from
            global image uploads distinguishes the MM Atlas, making it the most
            current resource depicting diverse images of breasts and chests that
            exists. We pride ourselves on pricing this tool comparatively to
            other medical atlas textbooks, and considerably less expensive than
            other apps of this nature. As a foundation of our community-centered
            business model, not only do we give back monetarily to the
            communities that share images with us, we also offer financial
            hardship discounts and no-cost subscriptions to users around the
            world who may find it challenging to meet the base price of $9 USD
            per month.
          </HelpText>
        </HelpItem>
        <HelpItem title="How does consent work?">
          <HelpText>
            Our team of attorneys have worked diligently to develop a media
            release/consent agreement that each image donor agrees to when
            uploading their images to the Atlas. In short, the image donor
            agrees to loan the image to the Atlas for educational and diagnostic
            purposes. Owners maintain all rights to their images and must be
            contacted directly to negotiate use terms outside the Atlas.
          </HelpText>
        </HelpItem>
        <HelpItem title="Is the website secure to use?">
          <HelpText>
            Yes! To the best of our ability, we have created a secure
            environment in which you can safely peruse images. Your private
            information has been secured according to global standards as stated
            here in our{' '}
            <Link
              external
              href={routes.privacy}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </HelpText>
        </HelpItem>
        <HelpItem title="How can I contribute a photo to The MM Atlas?">
          <HelpText>
            Visit our upload total <Link to={routes.imageUpload}>here</Link> to
            begin uploading images right away. It takes about 10 minutes per
            image and it helps to have all pertinent information about what is
            pictured at your fingertips before you begin. We only accept
            close-up images of breasts/chests on the bodies of BIPOC humans.
          </HelpText>
        </HelpItem>
        <HelpItem title="I want to email The Melanated Mammary Atlas">
          <HelpText>
            We’d love to hear from you! Email us anytime at{' '}
            <Link href="mailto:contact@mmatlas.com">contact@mmatlas.com</Link>.
          </HelpText>
        </HelpItem>
        <HelpItem title="How can I cancel or change my subscription?">
          <HelpText>
            Contact us at{' '}
            <Link href="mailto:support@mmatlas.com">support@mmatlas.com</Link>{' '}
            to edit or cancel your subscription. Although we'll be sad to see
            you go, we are happy to manage the process for you to ensure that
            your billing is discontinued and your account is securely closed.
          </HelpText>
        </HelpItem>
      </Container>
    </Layout>
  );
}

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
